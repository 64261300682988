import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { FormControl, Input, MenuItem, Select } from '@mui/material';
import { FilterValueSelectProps } from './FilterValueSelect.types';

// TODO dedupe with eln entries stuff where possible
export class FilterPickListValue extends React.Component<
  FilterValueSelectProps<FieldDataType.PickList>
> {
  handleChange = (event) => {
    const filterValues = event.target.value;
    this.props.handleValueChange(filterValues);
  };

  render() {
    const { inventoryFieldDefinition, filter } = this.props;
    const filterValues = filter.field_values.map((value) => value + '');
    return (
      <FormControl>
        <Select
          multiple
          value={filterValues}
          onChange={this.handleChange}
          input={<Input />}
          className='filter__value__select'
        >
          {inventoryFieldDefinition.pick_list_values.map((pickListValue) => (
            <MenuItem
              key={pickListValue.id}
              value={pickListValue.id + ''}
              className='filter__value__select-item'
            >
              {pickListValue.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
