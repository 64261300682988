import { FieldDataType } from '@/FieldDefinitions/types';
import { TInventoryFilter } from '@/Inventory/components/SearchBar/Filter/FilterFields/FilterValueSelect/FilterValueSelect.types';
import constants from 'javascripts/constants.js';
const { SEARCH_FILTER_STYLES } = constants;

export function limitFilterStyles(
  data_type_name: TInventoryFilter['data_type_name'],
) {
  let applicableFilterStyles;

  if (data_type_name == FieldDataType.Date) {
    applicableFilterStyles = SEARCH_FILTER_STYLES.filter(
      (filterStyle) =>
        !(filterStyle.get('key') == 'has' || filterStyle.get('bool')),
    ).toJS();
  } else if (data_type_name == FieldDataType.Boolean) {
    applicableFilterStyles = SEARCH_FILTER_STYLES.filter(
      (filterStyle) =>
        filterStyle.get('bool') || filterStyle.get('key') == 'any',
    ).toJS();
  } else {
    applicableFilterStyles = SEARCH_FILTER_STYLES.filter(
      (filterStyle) => !(filterStyle.get('ranged') || filterStyle.get('bool')),
    ).toJS();
  }

  return applicableFilterStyles;
}
