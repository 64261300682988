import { fromJS, Map } from 'immutable'

import {
  ADD, UPDATE, REMOVE, SPLICE, UPDATE_REQUIRED_GROUP_NUMBER,
} from '@/FieldDefinitions/MoleculeFieldDefinitions/actions/moleculeFieldDefinitions.js'

export function moleculeFieldDefinitionsInitialState(moleculeFieldDefinitions: Object[]) {
  const mfds = moleculeFieldDefinitions.map((obj, index) => {
    const temp_group_number = index + moleculeFieldDefinitions.length
    const mfd = Object.assign({}, obj.molecule_field_definition, {
      temp_group_number,
    })

    return mfd
  })

  return fromJS({
    byId: mfds.reduce((memo, mfd) => {
      memo[mfd.id] = mfd
      return memo
    }, {}),
    all: mfds
      .sort((a, b) => a.display_order - b.display_order)
      .map(mfd => mfd.id),
  })
}

const resetGroupNumbers = reducer => (state, action) => {
  let reducedState = reducer(state, action)

  if (action.subtype !== UPDATE_REQUIRED_GROUP_NUMBER) {
    return reducedState
  } else {
    let index = 1

    const groupNumberMappings = reducedState.get('byId').reduce((memo, mfd) => {
      const requiredGroupNumber = mfd.get('required_group_number')

      if (requiredGroupNumber && !memo[requiredGroupNumber]) {
        memo[requiredGroupNumber] = index
        index++
      }

      return memo
    }, {})

    return reducedState.update('byId', byId => byId.map(mfd => {
      return mfd.set(
        'required_group_number',
        groupNumberMappings[mfd.get('required_group_number')]
      )
    }))
  }
}

function _moleculeFieldDefinitions(state: Object = Map(), action: Object) {
  switch (action.type) {
    case ADD: {
      const moleculeFieldDefinition = Map({
        id: 'FAKE-ID-' + Date.now(),
        data_type_name: 'Text',
        display_order: state.get('all').size,
        unique_value: false,
        required_group_number: null,
        molecule_fields_count: 0,
        temp_group_number: state.get('all').size * 2,
      })

      return state
        .setIn(['byId', moleculeFieldDefinition.get('id')], moleculeFieldDefinition)
        .update('all', all => all.push(moleculeFieldDefinition.get('id')))
    }

    case UPDATE:
      switch (action.subtype) {
        case UPDATE_REQUIRED_GROUP_NUMBER: {
          if (!action.requiredGroupNumber) {
            // is optional
            return state.setIn(['byId', action.id.toString(), 'required_group_number'], null)
          } else {
            const requiredGroupNumber = parseInt(action.requiredGroupNumber)
            const requiredGroup = state.get('byId').filter(mfd => {
              return mfd.get('required_group_number') === requiredGroupNumber
            })
            const otherMfd = state.get('byId').find(mfd => {
              return mfd.get('temp_group_number') === requiredGroupNumber
            })

            let interimState = state

            if ((!requiredGroup || requiredGroup.size === 0) && otherMfd.get('id') !== action.id) {
              // is starting a new group with another definition
              interimState = state.setIn(['byId', otherMfd.get('id').toString(), 'required_group_number'], requiredGroupNumber)
            }

            // is setting itself to required, either alone or with another
            return interimState.setIn(['byId', action.id.toString(), 'required_group_number'], requiredGroupNumber)
          }
        }

        default: {
          const updatedValues = Object.assign({}, action.updatedValues)
          const data_type_name = updatedValues.data_type_name

          if (data_type_name) {
            if (state.getIn(['byId', action.id.toString(), 'molecule_fields_count']) > 0) {
              delete updatedValues.data_type_name
            } else if (data_type_name !== 'Text') {
              updatedValues.unique_value = false
            }
          }

          return state.mergeDeep({
            byId: { [action.id]: updatedValues },
          })
        }
      }

    case REMOVE:
      return state.mergeDeep({ byId: { [action.id]: { _destroy: 1 } } })

    case SPLICE:
      return state.update('all', all => {
        return all
          .delete(all.findIndex(id => id === action.id))
          .insert(action.index, action.id)
      })

    default:
      return state
  }
}

const moleculeFieldDefinitions = resetGroupNumbers(_moleculeFieldDefinitions)

export default moleculeFieldDefinitions
