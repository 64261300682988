import React from 'react'
import {
  FormControl,
  TextField,
} from '@mui/material'

export default class FilterTextValue extends React.Component {
  inputProps = {
    type: 'text',
  }

  get value() {
    const { filter: { field_values } } = this.props
    return (field_values && field_values[0]) || ''
  }

  handleChange = (event) => {
    this.props.handleValueChange([event.target.value])
  }

  render() {
    return (
      <FormControl>
        <TextField
          value={this.value}
          onChange={this.handleChange}
          variant='outlined'
          className='filter__value__text'
          {...this.inputProps}
        />
      </FormControl>
    )
  }
}
