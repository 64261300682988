import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import elnFieldDefinitionsReducer, {
  elnFieldDefinitionsInitialState,
} from './reducers'
import editable from './modules/editable.js'
import persistable from './modules/persistable.js'
import pickListValues from './modules/pickListValues.js'
import reactions from './middleware/reactions.js'
import composeWithDevTools from '@/shared/utils/composeWithDevTools.js'

export default function configureStore({
  elnFieldDefinitions,
  vaultElnFieldDefinitionsUrl,
} = {}) {
  // Filter out any pre generated definitions
  const filteredElnFieldDefinitions = elnFieldDefinitions.filter(efd => efd.pick_list_values)
  const initialState = persistable.initialState().toMap()
    .merge(
      editable.initialState({
        current: {
          elnFieldDefinitions: elnFieldDefinitionsInitialState(elnFieldDefinitions),
          pickListValues: pickListValues.initialState(filteredElnFieldDefinitions),
        },
      })
    ).set('vaultElnFieldDefinitionsUrl', vaultElnFieldDefinitionsUrl)

  return createStore(
    elnFieldDefinitionsReducer,
    initialState,
    composeWithDevTools(applyMiddleware(
      reactions,
      thunk
    ))
  )
}
