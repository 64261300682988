import { StringOrNumber } from '@/types';
import React from 'react';
import { SimpleDataTable } from '../SimpleDataTable/SimpleDataTable';

import SubmitOrCancel from '@/shared/components/SubmitOrCancel.jsx';
import Errors from '@/shared/components/Errors.jsx';
import { DataTypeSelect, NameField, UniqueCheckbox, Warning } from '@/shared/components/FieldDefinitionsTable';
import Icon from '@/shared/components/Icon.jsx';
import { A } from '@/shared/components/sanitizedTags';
import { ReorderDataTable } from '../ReorderDataTable/ReorderDataTable';

import { FieldDataType, FieldDefinition, FieldPickValue } from '@/FieldDefinitions/types';
import MuiTheme from '@/shared/components/MuiTheme.jsx';// import { RequiredGroupSelect } from '@/shared/components';
import { term, titleize } from '@/shared/utils/stringUtils';
import _ from 'lodash';
import { PickListItemsEditor } from '../PickListItemsEditor/PickListItemsEditor';
import RequiredGroupSelector from '../RequiredGroupSelector/RequiredGroupSelector';
import Checkbox from '@/shared/components/Checkbox.jsx';
import DataType from '@/models/DataType.js';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { Tooltip } from '@mui/material';

type Props = React.ComponentProps<typeof SimpleDataTable> & {
  rows: Array<FieldDefinition>;

  dataType: 'inventory_sample' | 'inventory_event'; // for now, add batch etc later
  isSaving?: boolean;
  disableSubmitOrCancel?: boolean;
  readOnly?: boolean;
  errorMessages?: object;
  isEditing?: boolean;
  numFixedRows?: number;
  preventUncheckingSampleId?: boolean;
  preventUncheckingSingleUse?: boolean;
  customUniquenessNote?: string;
  dataTypeOptions?: Array<string>
  createNewRow?: () => FieldDefinition;

  hideDeleteForRow?: (row: FieldDefinition) => boolean;
  onSubmit: (rows: Array<FieldDefinition>) => void;
  onEdit: () => void;
  onCancelEdit: () => void;
};

type State = {
  rows: Array<FieldDefinition>;
  hadSampleId: boolean;
  hadSingleUse: boolean;
};

export default class EditFieldDefinitionList extends React.Component<Props, State> {
  static defaultProps = {
    dataTypeOptions: ['Text', 'Number', 'Date', 'Pick List', 'File'],
  };

  get rowsWithTempGroupNumbers() {
    const newRows = deepClone(this.props.rows);
    newRows.forEach((row, index) => {
      row.temp_group_number = index + newRows.length;
    });
    return newRows;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      rows: this.rowsWithTempGroupNumbers,
      hadSampleId: false,
      hadSingleUse: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.rows !== prevProps.rows) {
      this.setState({
        rows: this.rowsWithTempGroupNumbers,
        hadSampleId: this.props.rows.some(row => row.is_sample_id),
        hadSingleUse: this.props.rows.some(row => row.is_single_use),
      });
    }
  }

  get fixedRows() {
    const { numFixedRows = 0 } = this.props;
    return this.state.rows.slice(0, numFixedRows);
  }

  get fieldCountAttribute() {
    return `${this.props.dataType}_fields_count`;
  }

  get fieldName() {
    const { dataType } = this.props;
    switch (dataType) {
      case 'inventory_sample':
        return {
          singular: 'sample',
          plural: 'samples',
        };

      case 'inventory_event':
        return {
          singular: 'inventory',
          plural: 'inventories',
        };

      default:
        return {
          singular: term(dataType) ?? dataType,
          plural: term(dataType + '.other') ?? dataType + 's',
        };
    }
  }

  handleChangeOrder = (rows) => {
    this.setState({ rows });
  };

  handleAdd = () => {
    const newRow = this.props.createNewRow?.() ?? {
      id: 'FAKE-ID-' + Date.now(),
      data_type_name: 'Text',
      temp_group_number: Math.max(...this.state.rows.map(row => (row.temp_group_number ?? 0))) + 1,
    } as FieldDefinition;
    this.setState({ rows: [...this.state.rows, newRow] });
  };

  handleAddEdit = () => {
    this.props.onEdit?.();
  };

  handleSubmit = async () => {
    let { numFixedRows = 0 } = this.props;
    let { rows } = this.state;

    if (rows.some(row => row._destroy === 1 && row[this.fieldCountAttribute])) {
      const message = `Removing ${this.fieldName.singular} fields will delete existing data.`;
      if (!await ModalUtils.showModal(message, {})) {
        return;
      }
    }

    const groupMap: Record<number, number> = {};
    const getGroupNumber = (row: FieldDefinition) => {
      const groupNumber = row.required_group_number;
      if (!groupNumber) {
        return null;
      }
      if (row.amount_type) {
        return null;
      }
      if (groupMap[groupNumber]) {
        return groupMap[groupNumber];
      }
      return groupMap[groupNumber] = Object.keys(groupMap).length + 1;
    };

    // hack alert: we want to send rows that have an amount_type to the submit method even though they are included
    // in the fixed rows, but importantly are not truly "autogenerated" rows. Otherwise rows jump around on saving.
    // The truly hard coded rows will appear first.
    if (numFixedRows) {
      for (let i = 0; i < numFixedRows; i++) {
        if (rows[i].amount_type) {
          numFixedRows = i;
          break;
        }
      }
    }

    rows = rows.map(row => ({
      ...row,
      required_group_number: getGroupNumber(row),
    })).slice(numFixedRows);

    this.props.onSubmit(rows);
  };

  handleCancel = () => {
    this.props.onCancelEdit?.();
    this.setState({
      rows: this.props.rows,
    });
  };

  renderCustomRow = (row: FieldDefinition) => {
    if (row._destroy) {
      return <tr className='warning-row' key={`warning-${row.id}`}>
        <td colSpan={100}>
          <Warning definition={row} objectName={this.props.dataType} attribute={this.fieldCountAttribute} />
        </td>
      </tr>;
    }
  };

  get dataTypeOptions() {
    return ['Text', 'Number', 'Date', 'PickList', 'File'];
  }

  renderCell = (columnId: StringOrNumber, row: FieldDefinition) => {
    const { isEditing, numFixedRows = 0, preventUncheckingSampleId } = this.props;
    const { hadSampleId, hadSingleUse } = this.state;
    const rowIndex = this.props.rows.findIndex(r => r.id === row.id);
    const { fieldName } = this;
    const preventUncheckingSingleUse = row?.inventory_sample_fields_count > 0;

    const { rows } = this.state;
    if (isEditing && columnId === 'is_sample_id' && (rowIndex >= numFixedRows || rowIndex === -1)) {
      // display nothing for the Sample ID if any other field definitions have sample checked
      if (!row.is_sample_id && rows.some(row => row.is_sample_id)) {
        return '';
      }
      if (preventUncheckingSampleId && row.is_sample_id && hadSampleId) {
        return <Tooltip title='To modify Sample ID, delete all existing samples or contact support'>
          <input type="checkbox" readOnly checked={true} />
        </Tooltip>;
      }
    }

    if (isEditing && columnId === 'is_single_use' && (rowIndex >= numFixedRows || rowIndex === -1)) {
      // display nothing for the single use if Sample ID is not checked
      if (!row.is_sample_id) {
        return '';
      }
      if (preventUncheckingSingleUse && row.is_single_use && hadSingleUse) {
        return <Tooltip title='To modify Single Use, delete all existing samples or contact support'>
          <input type="checkbox" readOnly checked={true} />
        </Tooltip>;
      }
    }

    const rendered = this.props.renderCell?.(columnId, row);
    if (rendered) {
      return rendered;
    }

    const elementId = row.id;
    const value = row[columnId] ?? '';
    const columnDef = this.props.columns.find(column => column.id === columnId);

    let pickListSingleUseSummary;

    if (row.is_sample_id && row.data_type_name === FieldDataType.PickList) {
      pickListSingleUseSummary = <>
      {(row.pick_list_values ?? [])
        .filter(value => value.is_single_use && !value._destroy)
        .map(value => <div key={value.id} className='whitespace__nowrap'>{value.value}</div>)}
      </>;
    }

    if (isEditing && (rowIndex >= numFixedRows || rowIndex === -1)) {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.type === 'checkbox') {
          row[columnId] = e.target.checked;
          if (columnId === 'is_sample_id' && e.target.checked) {
            row.required_group_number = row.temp_group_number;
          }
        } else {
          row[columnId] = e.target.value;
        }
        this.setState({ rows: [...this.state.rows] });
      };

      const handleChangeRequired = (newRows: FieldDefinition[]) => {
        this.setState({
          rows: [
            ...this.fixedRows,
            ...newRows,
          ],
        });
      };

      const handleChangePickList = (newValue: FieldPickValue[]) => {
        row.pick_list_values = newValue;
        this.setState({ rows: [...this.state.rows] });
      };

      const { readOnly } = this.props;
      const { disabled, data_type_name } = row;
      const fields_count = row[this.fieldCountAttribute] ?? 0;
      const uniqueDisabledText = (disabled && data_type_name == 'Text')
        ? { disabledText: `${_.capitalize(fieldName.plural)} cannot be unique` }
        : {};
      const dataTypeDisabled = fields_count > 0;
      const dataTypeDisabledText = (disabled && !dataTypeDisabled)
        ? { disabledText: `Cannot change data type for ${fieldName.singular}` }
        : {};

      const commonProps = {
        onChange: handleChange,
        readOnly,
        disabled,
      };

      switch (columnId) {
        case 'name':
          return <div className="inner name">
            <NameField
              value={value}
              {...commonProps}
            />
          </div>;

        case 'data_type_name':
          return <div className="inner data-type" style={{ position: 'relative' }}>
            <DataTypeSelect
              options={this.dataTypeOptions}
              value={value}
              {...commonProps}
              disabled={disabled || dataTypeDisabled}
              {...dataTypeDisabledText}
            />
            <PickListItemsEditor
              dataType={value}
              values={row.pick_list_values}
              showSingleUseOption={row.is_sample_id}
              onChange={handleChangePickList} />
          </div>;

        case 'required_group_number':
          return <div className="inner requirement">
            <RequiredGroupSelector
              {...commonProps}
              id={elementId}
              value={value}
              options={this.state.rows.slice(numFixedRows).filter(row => (row._destroy !== 1))}
              onChange={handleChangeRequired}
              readOnly={!!row.is_sample_id}
            />
          </div>;

        case 'unique_value':
          return <div className="inner centered unique">
            <UniqueCheckbox
              {...commonProps}
              checked={value}
              disabled={disabled}
              dataTypeName={data_type_name}
              name={columnId}
              {...uniqueDisabledText}
            />
          </div>;
        case 'is_single_use': {
          if (pickListSingleUseSummary) {
            return pickListSingleUseSummary;
          }
          const showSingleUse = row.is_sample_id && row.data_type_name !== FieldDataType.PickList;
          if (!showSingleUse) {
            return '';
          }
        }
          break;
      }

      if (columnDef) {
        switch (columnDef.type) {
          case 'checkmark':
            return <input type="checkbox" className="cursor-checkbox" checked={row[columnId] ?? false} onChange={handleChange} />;
          default:
            return <input type="text" className="cursor-text" value={row[columnId] ?? false} onChange={handleChange} />;
        }
      }
    } else {
      if (columnId === 'is_single_use' && pickListSingleUseSummary) {
        return pickListSingleUseSummary;
      }

      if (columnDef?.type === 'checkmark') {
        return <span className="checkmark-cell">
          <Checkbox checked={value ?? false} />
        </span>;
      }

      switch (columnId) {
        case 'data_type_name':
          return DataType.displayNameForDataTypeName(value);

        case 'required_group_number':
          return <RequiredGroupSelector
            readOnly={true}
            id={elementId}
            value={value}
            options={this.props.rows}
          />;
      }
    }

    return undefined;
  };

  handleDeleteRow = (row: FieldDefinition) => {
    row._destroy = 1;
    this.setState({ rows: [...this.state.rows] });
  };

  render() {
    const { isSaving, isEditing, errorMessages, readOnly, dataType, customUniquenessNote, disableSubmitOrCancel } = this.props;
    const { fieldName } = this;

    const title = `${titleize(fieldName.singular)} Fields`;

    return <>
      <div className="EditFieldDefinitionList subcontainer">
        {isEditing && <>

          <h2>{title}</h2>

          <Errors errorMessages={errorMessages ?? []} />

          <ReorderDataTable
            {...this.props}
            rows={this.state.rows}
            onChangeOrder={this.handleChangeOrder}
            renderCell={this.renderCell}
            showDelete={true}
            onDeleteRow={this.handleDeleteRow}
            renderCustomRow={this.renderCustomRow}
            rowIdPrefix={`${dataType.replace('_', '-')}-field-definition-`}
          />

          <div className='fake_data_table__add_row'>
            <div className='row'>
              <div className='inner'>
                <A id="add-batch-field-definition" onClick={this.handleAdd} href="#">
                  <Icon icon="add" forceSize="16" />
                  <span>Add a {fieldName.singular} field</span>
                </A>
              </div>
            </div>
          </div>

          <SubmitOrCancel
            disabled={isSaving || disableSubmitOrCancel}
            green={true}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}>
            Update {fieldName.singular} fields
          </SubmitOrCancel>
        </>}

        {!isEditing &&
          <>
            {!readOnly && <A href="#" className="editableData-editLink" disabled={true || readOnly}
              onClick={this.handleAddEdit}>
              <Icon icon="pencil" forceSize="16" alt="pencil" />
              Add/Edit {title}
            </A>}

            <h2>{title}</h2>

            <SimpleDataTable
              {...this.props}
              isSortable={false}
              renderCell={this.renderCell}
              alternateRowColors={false}
              showHover={false}
            />
          </>}
        {customUniquenessNote !== null && <p className="muted uniqueness-note">
          {customUniquenessNote || `Note: Uniqueness is compared across all ${fieldName.singular} fields within the vault.`}
        </p>}

      </div>
    </>;
  }
}
