import React from 'react'
import { createRoot } from 'react-dom/client'

import AppContainer from '@/shared/components/AppContainer.jsx'
import ElnFieldDefinitionsContainer from './container.js'
import configureStore from './store.js'
import camelCaseKeys from '@/shared/utils/camelCaseKeys.js'

export * from './reducers'

export default function ElnFieldDefinitions(props) {
  const rootElement = document.getElementById('elnFieldDefinitions')
  const store = configureStore(camelCaseKeys(props))

  const root = createRoot(rootElement)
  root.render(
    <AppContainer store={store}>
      <ElnFieldDefinitionsContainer />
    </AppContainer>,
    rootElement
  )
}
