/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React, { ReactNode } from 'react';
import { List } from 'immutable';

import { Autocomplete, FormControlLabel, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { getLinkSuggestions } from '@/shared/actions/linkSuggestions.js';
import { getMoleculeSuggestions } from '@/shared/actions/moleculeSuggestions';
import Suggestion from '@/shared/components/UrlField/Suggestion';
import { MoleculeSuggestion, MoleculeSuggestions } from '@/shared/models/MoleculeSuggestions.js';
import { LinkSuggestions } from '@/shared/models/LinkSuggestion.js';
import { term } from '@/shared/utils/stringUtils';
import CDDIcon from '@/shared/components/Icon.jsx';
import { activeAjax } from '@/typedJS';

type SelectedMolecule = {
  molecule: MoleculeSuggestion;
};

type Props = {
  placeholder?: string;
  label?: string;
  noMoleculeFoundText?: string;
  initialValue?: string;
  onChange?: (selected: SelectedMolecule) => void;

  linkSuggestions?: LinkSuggestions;
  getLinkSuggestions?: (query: string) => LinkSuggestions;
  moleculeSuggestions?: MoleculeSuggestions;
  getMoleculeSuggestions?: (query: string) => MoleculeSuggestions;
};

type State = {
  inputValue: string;
  options: MoleculeSuggestion[];
  value?: MoleculeSuggestion;
  open: boolean;
  loadingCount: number;
};

export class StructureLookup extends React.Component<Props, State> {
  static defaultProps = {
    label: null,
    noMoleculeFoundText: `No ${term('molecule', true)} found`,
    initialValue: '',
    onChange: null,
    moleculeSuggestions: null,
    linkSuggestions: null,
  };

  insertWhenReceiveResults = false;
  handledChange = false;
  disposers: Array<() => void> = [];
  subscribed = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      inputValue: this.props.initialValue,
      options: [],
      value: null,
      open: false,
      loadingCount: 0,
    };
  }

  handleTextFocus = () => {
    if (!this.subscribed) {
      this.subscribed = true;
      activeAjax.subscribe(this.handleAjaxCountChange);
      this.disposers.push(() => {
        activeAjax.unsubscribe(this.handleAjaxCountChange);
      });
    }
  };

  handleTextBlur = () => {
    if (this.subscribed) {
      this.subscribed = false;
      activeAjax.unsubscribe(this.handleAjaxCountChange);
      this.disposers = [];
    }
  };

  componentWillUnmount() {
    this.disposers.forEach((disposer) => disposer());
  }

  handleAjaxCountChange = (loadingCount: number, start: boolean) => {
    setTimeout(() => {
      this.setState({
        loadingCount,
      });
    });
    return true;
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.linkSuggestions !== prevProps.linkSuggestions || this.props.moleculeSuggestions !== prevProps.moleculeSuggestions) {
      const { list } = this.getOptions();
      if (list.length && this.insertWhenReceiveResults) {
        setTimeout(() => {
          this.handleClose({}, 'select-option');
        });
      }
    }
  }

  requestSuggestions = (inputValue: string) => {
    const {
      linkSuggestions, getLinkSuggestions,
      moleculeSuggestions, getMoleculeSuggestions,
    } = this.props;

    if (inputValue && inputValue.trim()) {
      if (!linkSuggestions.get(inputValue)) {
        getLinkSuggestions(inputValue);
      }
      if (!moleculeSuggestions.get(inputValue)) {
        getMoleculeSuggestions(inputValue);
      }
    }
  };

  getOptions = () => {
    const { moleculeSuggestions, linkSuggestions } = this.props;
    const { inputValue } = this.state;

    const s1 = linkSuggestions.get(inputValue);
    const s2 = moleculeSuggestions.get(inputValue);
    let list = (s1 || List()).filter((s: MoleculeSuggestion) => {
      return s.type === 'molecules';
    });
    if (s2 && s2.mrv) list = list.unshift(s2);
    const busy = inputValue.length > 0 && !(s1 && s2);

    return {
      list: [...list], // Autocomplete needs a javascript array, but we need the store entries
      busy,
      couldHaveResults: busy || inputValue.length == 0 || (!s1 && !s2),
    };
  };

  getPlaceholder = () => {
    return this.props.placeholder ?? `${term('molecule', true)} name, common name, or identifier`;
  };

  getOptionLabel = (suggestion: MoleculeSuggestion) => suggestion.label;

  isOptionEqualToValue = (_option: MoleculeSuggestion, _value: MoleculeSuggestion) : boolean => {
    // due to the async search, this tends to write warnings into the console
    // if one changes the query and the current value (selected molecule) is not found
    // returning true solves the problem
    // return option.id == value.id;
    return true;
  };

  getOptionDisabled = (_option: any) : boolean => {
    return (!_option.molecule_details?.molfile && !_option.mrv);
  };

  getValueFromSuggestion = (suggestion: MoleculeSuggestion) => {
    let value = suggestion.display;
    if (Array.isArray(value)) {
      value = value[0];
    }
    return value.replace(/<(?:.|\n)*?>/gm, '');
  };

  handleOnChange = (_event: object, value: MoleculeSuggestion & { molecule_details?: { molfile: string }}, _reason: string) => {
    const { onChange } = this.props;
    if (this.handledChange) {
      return;
    }
    this.handledChange = true;

    const selection = {
      molecule: {
        ...JSON.parse(JSON.stringify(value)),
        displayName: this.getValueFromSuggestion(value),
      },
    };

    onChange && value && onChange(selection);
    if (value) {
      this.insertWhenReceiveResults = false;
    }
    this.setState({ value: null, inputValue: '' });
  };

  handleOnHighlightChange = (_event: object, option: MoleculeSuggestion, _reason: string) => {
    this.setState({ value: option });
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    // when the autocomplete is open, handle Enter/Escape keys and prevent propagation
    if (this.state.open) {
      // if you press Enter *before* the API returns results, it should still accept your input
      this.insertWhenReceiveResults = (event.key === 'Enter');
      // avoid that the dialog buttons react to the key events
      if (event.key === 'Enter' || event.key === 'Escape') {
        event.nativeEvent.stopImmediatePropagation();
      }
    }
  };

  handleOnInputChange = (_event: object, inputValue: string, reason: string) => {
    if (reason !== 'reset') {
      this.setState({ inputValue });
      this.requestSuggestions(inputValue);
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (_event: object, reason: string) => {
    if (reason === 'select-option') {
      this.handleOnChange({}, this.state.value, 'select-option');
    }
    this.setState({ open: false });
    this.handledChange = false;
  };

  renderOption = (props: object, option: MoleculeSuggestion) => {
    return <li key={option.id} {...props}>
      <Suggestion suggestion={option} />
    </li>;
  };

  render = () => {
    const { inputValue, open, loadingCount } = this.state;
    const { busy, couldHaveResults } = this.getOptions();
    const showLoadingIndicator = loadingCount > 0 || busy;
    const { label, noMoleculeFoundText } = this.props;
    const props: React.ComponentProps<typeof Autocomplete> = {
      inputValue,

      onChange: this.handleOnChange,
      onInputChange: this.handleOnInputChange,
      onHighlightChange: this.handleOnHighlightChange,

      autoComplete: true,
      autoHighlight: true,
      blurOnSelect: true,
      disableClearable: true,
      clearOnEscape: true,
      openOnFocus: true,

      noOptionsText: (inputValue && !showLoadingIndicator) ? noMoleculeFoundText : '',
      renderInput: (params): ReactNode => {
        const InputProps = {
          ...params.InputProps,
          classes: {
            ...(params.InputProps as any).classes,
            input: 'material-input',
          },
        };

        return (
          <TextField
            variant={'standard'}
            {...params} label={ label }
            InputProps={InputProps}
            onKeyDown={ this.handleKeyDown }
            placeholder={this.getPlaceholder()}
            onFocus={this.handleTextFocus}
            onBlur={this.handleTextBlur}
            />
        );
      },
      renderOption: this.renderOption,
      options: this.getOptions().list,
      filterOptions: (x) => x,
      getOptionLabel: this.getOptionLabel,
      isOptionEqualToValue: this.isOptionEqualToValue,
      getOptionDisabled: this.getOptionDisabled,
    };
    let className = '';
    if (!showLoadingIndicator) {
      className += 'not-loading';
    }
    return (
      <span className={className}>
        <FormControlLabel
          labelPlacement="start"
          label='Insert Structure:'
          control={
            <Autocomplete
              id={'idAutoCompleteStructureLookup'}
              open={open}
              onOpen={this.handleOpen}
              onClose={this.handleClose}
              {...props} />
          } />
        <CDDIcon icon='spinner'/>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  const { moleculeSuggestions, linkSuggestions } = state;
  return {
    moleculeSuggestions,
    linkSuggestions,
  };
};

const mapDispatchToProps = {
  getMoleculeSuggestions,
  getLinkSuggestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(StructureLookup);
