import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/lib/Async'

import { withStyles } from '@mui/styles'

import {
  MenuItem,
  NoSsr,
  Paper,
  TextField,
  Typography,
  emphasize,
} from '@mui/material'

import axios from 'axios'

// Style stuff to make the display work with material-ui
const styles = theme => {
  const theme_spacing_unit = 8 // theme.spacing.unit
  return ({
    root: {
      flexGrow: 1,
      // height: 250,
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${theme_spacing_unit / 2}px ${theme_spacing_unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme_spacing_unit}px ${theme_spacing_unit * 2}px`,
    },
    singleValue: {
      // fontSize: '1.000rem',
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      // fontSize: '1.000rem',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme_spacing_unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme_spacing_unit * 2,
    },
  })
}

// Functions used to display material-ui inside react-select
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

const inputComponent = React.forwardRef((props, _ref) => {
  return (<div {...props} />)
})
inputComponent.displayName = 'SaltPicker.inputComponent'

function Control(props) {
  return (
    <TextField
      disabled={props.isDisabled}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  const { children } = props
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </MenuItem>
  )
}

function Placeholder(props) {
  const textColor = props.isDisabled ? 'inherit' : 'textSecondary'
  return (
    <Typography
      color={textColor}
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  const { data } = props
  const textColor = props.isDisabled ? 'inherit' : 'textSecondary'
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      color={textColor}
      {...props.innerProps}
    >
      {data.value}
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

// The list of components that are customized for display (material-ui inside react-select)
const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

class Picker extends React.Component {
  state = {
    'single': null,
  }

  getSaltPromise = (input) => {
    return axios.get(
      '/support/salts/autocomplete_for_salt',
      { params: { term: input } }
    ).then(response => {
      return response.data
    })
  }

  setOption = (option) => {
    if (this.props.setValue != undefined) {
      this.props.setValue(option)
    } else {
      this.setState({
        'single': option,
      })
    }
  }

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue.length == 0) {
      return 'Type the name, symbols, or abbreviation of a salt.'
    } else {
      return 'No salts found.'
    }
  }

  render() {
    const { classes, theme, defaultValue } = this.props
    const single = this.props.value || this.state.single

    const selectStyles = {
      container: base => ({
        ...base,
        // hack to make the container the right height (so Material-UI upgrade doesn't break things)
        '> div > div > div': {
          height: 24,
        },
        '> div > div > div > div:nth-child(1)': {
          float: 'left'
        },
        '> div > div > div > div:nth-child(2)': {
          float: 'right'
        },
      }),
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    }
    return (
      <div className={classes.root}>
        <NoSsr>
          <AsyncSelect
            fullWidth
            className='salt-name'
            classes={classes}
            styles={selectStyles}
            components={components}
            defaultValue={defaultValue}
            isDisabled={this.props.disabled}
            name={this.props.name}
            loadOptions={this.getSaltPromise}
            noOptionsMessage={this.noOptionsMessage}
            onChange={this.setOption}
            placeholder={this.props.NoSaltFreeBaseOrAcid}
            isClearable
            single={single}
          />
        </NoSsr>
      </div>
    )
  }
}

Picker.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(Picker)
