import persistable from './modules/persistable.js'
import railsify from './models/elnFieldDefinitions.js'
import submit from '@/shared/utils/submit.js'

export const ADD = 'elnFieldDefinitions/ADD'
export const UPDATE = 'elnFieldDefinitions/UPDATE'
export const REMOVE = 'elnFieldDefinitions/REMOVE'
export const SPLICE = 'elnFieldDefinitions/SPLICE'

export function addElnFieldDefinition() { return { type: ADD } }

export function updateElnFieldDefinition(id: number, updatedValues: Object) {
  return { type: UPDATE, id, updatedValues }
}

export function removeElnFieldDefinition(id: number) {
  return { type: REMOVE, id }
}

export function spliceElnFieldDefinition(id: number, index: number) {
  return { type: SPLICE, id, index }
}

export function saveElnFieldDefinitions() {
  return function (dispatch: Function, getState: Function) {
    dispatch(persistable.actions.saving())
    const state = getState()
    const vaultElnFieldDefinitionsUrl = state.get('vaultElnFieldDefinitionsUrl')
    const data = $.param(railsify(state))

    return submit({
      url: vaultElnFieldDefinitionsUrl,
      data,
    }).then(
      (response) => dispatch(persistable.actions.savingSucceed(response)),
      ({ errorMessages, status }) => dispatch(persistable.actions.savingFail(errorMessages, status))
    )
  }
}
