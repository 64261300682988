import React from 'react';
import { CustomCalculationAutoComplete } from '../types';
import { FormHelperText, Tooltip, SelectChangeEvent } from '@mui/material';
import HelpIcon from 'ASSETS/images/cdd30/icons/help.png';
import { Img } from '@/shared/components/sanitizedTags.js';
import { getRootStore } from '@/stores/rootStore';
import { term } from '@/shared/utils/stringUtils';

type Props = {
  value: string;
  error?: string;
  calculationId?: number
  lingoizedAggregationLevel?: Array<Array<string>>;
  aggregateReadoutsBy?: string;
  onChange: (newValue: string) => void;
  onChangeAggregation: (newValue: string) => void;
  autoCompleteOptions: Partial<CustomCalculationAutoComplete>;
};

export class FormulaEditorLegacyWrapper extends React.Component<Props> {
  readonly refContainer = React.createRef<HTMLDivElement>();
  readonly refTextArea = React.createRef<HTMLTextAreaElement>();
  readonly refUpdate = React.createRef<HTMLDivElement>();
  readonly refAggregation = React.createRef<HTMLTableRowElement>();
  readonly refAggregationSelect = React.createRef<HTMLSelectElement>();

  autoFormula: any;

  componentDidMount() {
    const AutoFormula = (window as any).AutoFormula;
    this.autoFormula = new AutoFormula(this.refTextArea.current,
      this.refUpdate.current,
      this.refAggregation.current,
      {
        ...deepClone(this.props.autoCompleteOptions),
        afterUpdateElement: (element) => {
          // autoformula.js modifies the textarea and aggregation select values, and this is not broadcast as a change
          // event that React can pick up. So after the element is updated, send the new values to the handlers.
          this.props.onChange(element.val());
        },
        afterUpdateAggregation: (val) => {
          this.props.onChangeAggregation(val);
        },
      });
    this.updatePosition();
  }

  updatePosition() {
    this.autoFormula.positionSet = true;
  }

  handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { error, calculationId } = this.props;
    let textAreaId = 'formatted_formula_custom_calculation';
    if (calculationId) {
      textAreaId += `_${calculationId}`;
    }

    const lingoized_aggregation_level:Array<Array<string>> = this.props.lingoizedAggregationLevel ??
    [
      [
        `Aggregate by ${term('batch')} and ${term('run')}`,
        'batch and run',
      ],
      [
        `Aggregate by ${term('batch')} and ${term('protocol')}`,
        'batch and protocol',
      ],
      [
        `Aggregate by ${term('molecule')} and ${term('protocol')}`,
        'molecule and protocol',
      ],
    ];

    const tooltipProps: Partial<React.ComponentProps<typeof Tooltip>> = {
      componentsProps: {
        tooltip: {
          sx: {
            bgcolor: 'rgb(252, 252, 252)',
            color: 'rgb(89, 50, 22)',
            minWidth: '60rem',
            maxHeight: '20rem',
            overflowY: 'auto',
            padding: '1.5rem',
            border: '1px solid rgba(89, 50, 22, .5)',
            fontSize: '1rem',
          },
        },
      },
    };

    const { mapHelpToContent } = getRootStore().protocolReadoutDefinitionsStore.editStore;
    return <div id='protocol-readouts-edit' className='formula-editor-legacy-wrapper' ref={this.refContainer}>
      <div>
      <textarea ref={this.refTextArea}
        className={`resizable${error ? ' error' : ''}`}
        id={textAreaId}
        data-tr_options="data {:field=>:formula}"
        name="custom_calculation[formatted_formula]" autoComplete="off"
        rows={2}
        onChange={this.handleChangeText}
        defaultValue={this.props.value}
        />
        <Tooltip
          title={<div dangerouslySetInnerHTML={{ __html: mapHelpToContent.custom_calculations }} />}
          arrow
          enterDelay={500}
          leaveDelay={200}
          PopperProps={{ disablePortal: true }}
          {...tooltipProps}
          >
          <span className='help-icon'><Img src={HelpIcon} /></span>
        </Tooltip>
      </div>
      <table>
        <tbody>
          <tr ref={this.refAggregation}
          id="aggregation_custom_calculation" style={{ display: 'none' }}>
            <th>
              Aggregation:
            </th>
            <td>
              <select
                ref={this.refAggregationSelect}
                name="custom_calculation[aggregate_readouts_by]"
                id="custom_calculation_aggregate_readouts_by"
                value={this.props.aggregateReadoutsBy}
                onChange={e => {
                  this.props.onChangeAggregation(e.target.value);
                }}>
                {lingoized_aggregation_level.map(options => (
                  <option key={options[1]} value={options[1]}>{options[0]}</option>
                ))}
                </select>
            </td>
          </tr>
        </tbody>
      </table>
      <div ref={this.refUpdate}
      className="autocomplete" id="autoformula_custom_calculation" style={{ display: 'none' }}></div>

     {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </div>;
  }
}
