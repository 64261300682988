import React from 'react';
import { DDForm, DDFormProps } from '../DDForm';
import { DDModalForm, DDModalFormProps } from '../DDModalForm';
import { muiRenderElement } from './muiRenderers';

export class MuiDDForm extends React.Component<DDFormProps> {
  render() {
    return <DDForm {...this.props} renderElement={muiRenderElement}/>;
  }
}

export class MuiDDModalForm extends React.Component<DDModalFormProps> {
  render() {
    return <DDModalForm {...this.props} renderElement={muiRenderElement}/>;
  }
}
