import { combineReducers } from 'redux-immutable'
import { fromJS, Map } from 'immutable'

import editable from '../modules/editable.js'
import persistable from '../modules/persistable.js'
import pickListValues from '../modules/pickListValues.js'
import { ADD, UPDATE, REMOVE, SPLICE } from '../actions.js'
import reinitAfterCancel from './reinitAfterCancel.js'

function missingIndex(integers) {
  const indexTotal = ((integers.length * (integers.length + 1)) / 2)

  return integers.reduce((memo, i) => { return memo - i }, indexTotal)
}

export function elnFieldDefinitionsInitialState(elnFieldDefinitions: Object[]) {
  const display_order = missingIndex(elnFieldDefinitions.map(efd => efd.display_order))
  const projectFieldDefinition = {
    id: 'FAKE-ID-PROJECT-PLACEHOLDER',
    name: 'Project',
    data_type_name: 'Project',
    display_order: display_order,
    unique: false,
    required: true,
    uneditable: true,
  }
  elnFieldDefinitions.push(projectFieldDefinition)

  return fromJS({
    byId: elnFieldDefinitions.reduce((memo, efd) => {
      memo[efd.id] = efd
      return memo
    }, {}),
    all: elnFieldDefinitions
      .sort((a, b) => a.display_order - b.display_order)
      .map(efd => efd.id),
  })
}

export function elnFieldDefinitions(state: Object = Map(), action: Object) {
  let elnFieldDefinition
  let data_type_name

  switch (action.type) {
    case ADD:
      elnFieldDefinition = Map({
        id: 'FAKE-ID-' + Date.now(),
        data_type_name: 'Text',
        display_order: state.get('all').size,
      })

      return state
        .setIn(['byId', elnFieldDefinition.get('id')], elnFieldDefinition)
        .update('all', all => all.push(elnFieldDefinition.get('id')))

    case UPDATE:
      data_type_name = action.updatedValues.data_type_name

      if (data_type_name && data_type_name !== 'Text') {
        action.updatedValues.unique = false
      }

      return state.mergeDeep({
        byId: { [action.id]: action.updatedValues },
      })

    case REMOVE:
      return state
        .mergeDeep({ byId: { [action.id]: { _destroy: 1 } } })

    case SPLICE:
      return state.update('all', all => {
        return all
          .delete(all.findIndex(id => id === action.id))
          .insert(action.index, action.id)
      })

    case persistable.actionTypes.SUCCEED:
      return elnFieldDefinitionsInitialState(action.response)
    default:
      return state
  }
}

const reducer = reinitAfterCancel(
  persistable.reducer(
    editable.reducer(
      combineReducers({
        elnFieldDefinitions,
        pickListValues: pickListValues.reducer,
      })
    )
  )
)

export default reducer
