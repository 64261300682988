// A simple middleware for dispatching synchronous actions
const reactions = getReaction => ({ dispatch }) => {
  return next => action => {
    const value = next(action)
    const reaction = getReaction(value)

    if (reaction) {
      return dispatch(reaction)
    } else {
      return value
    }
  }
}

export default reactions
