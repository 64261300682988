import React from 'react'
import { Map } from 'immutable'

function requiredText(requiredGroupNames) {
  if (requiredGroupNames && requiredGroupNames.size > 0) {
    return `or ${requiredGroupNames.join(' or ')} is required`
  } else {
    return 'is optional'
  }
}

function filteredOptions(id, groupsByNumber) {
  return groupsByNumber
    .reduce((namesByNumber, bfds, groupNumber) => {
      const names = bfds.filter(bfd => { return bfd.get('id') !== id })
        .map(bfd => bfd.get('name'))
        .sort()

      if (names.size > 0) {
        return namesByNumber.set(groupNumber, names)
      } else {
        return namesByNumber
      }
    }, Map())
    .sortBy(names => names.min())
    .map((names, groupNumber) => {
      return (
        <option key={groupNumber} value={groupNumber}>
          {requiredText(names)}
        </option>
      )
    })
    .toArray()
}

export default function RequiredGroupSelect(props) {
  const {
    id,
    readOnly,
    tempGroupNumber,
    requiredGroupNumber,
    groupsByNumber,
    updateRequiredGroupNumber,
  } = props

  return (
    <select
      name="required_group_number"
      onChange={event => updateRequiredGroupNumber(id, event.target.value)}
      readOnly={readOnly}
      value={requiredGroupNumber || ''}
    >
      <option value={tempGroupNumber}>is required</option>
      <option value="">is optional</option>
      {filteredOptions(id, groupsByNumber)}
    </select>
  )
}
