/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';

import { SingleSelectTreeViewProps, TreeItem, TreeView } from '@mui/lab';
import { StringOrNumber } from '@/types';
import { SortableTreeViewNode, SortableTreeViewUtils } from './sortableTreeViewUtils';
import { clsx } from 'clsx';
import { observer } from 'mobx-react';

type Props = Omit<SingleSelectTreeViewProps, 'ref'> & {
  dragThreshold?: number;
  nodes: Array<SortableTreeViewNode>;
  onNodeDraggedInto?: (node: SortableTreeViewNode, into: SortableTreeViewNode) => void;
  onChangeOrder(nodes: Array<SortableTreeViewNode>,
    draggedNodeId?: StringOrNumber,
    expanded?: Array<string>): void;
  renderItemContents(node: SortableTreeViewNode): React.ReactNode;
  canDragInto?(from: SortableTreeViewNode, into: SortableTreeViewNode): SortableTreeViewNode | boolean;
}

type State = {
  isDragging: boolean;
  dragProhibited?: boolean;
  pressedNode?: SortableTreeViewNode;
  pointerDownElement?: HTMLElement;
  pointerDownX?: number;
  pointerDownY?: number;
  dragInsert?: {
    intoNodeId: StringOrNumber;
    expandedNodeId?: StringOrNumber;
    index: number;
  }
  dragBounds?: DOMRect;
  nodesWhileDragging?: Array<SortableTreeViewNode>;
  expandedWhileDragging?: Array<string>;
  innerDragHtml?: { __html: string };
}

export type SortableTreeViewProps = Props;

type SortableTreeViewItemProps = {
  className?: string;
  node: SortableTreeViewNode;
  parent: SortableTreeViewNode;
  draggingNode?: SortableTreeViewNode;
  dragInsert?: {
    intoNodeId: StringOrNumber;
    index: number;
  };
  showDragInHighlight?: boolean;
  renderItemContents(node: SortableTreeViewNode): React.ReactNode;
};

class SortableTreeViewItem extends React.Component<SortableTreeViewItemProps> {
  render() {
    const {
      node, parent, draggingNode, dragInsert, renderItemContents, className: propsClassName, showDragInHighlight,
      ...rest
    } = this.props;

    let insertAbove = false;
    let insertBelow = false;

    if (dragInsert) {
      if (dragInsert.intoNodeId == node.id && dragInsert.index == 0 && !node.children?.length) {
        insertBelow = true;
      } else if (parent) {
        // get the index of this node within its parent
        const indexWithinParent = (parent.children?.findIndex(child => child.id == node.id)) ?? 0;
        if (dragInsert.intoNodeId == parent.id) {
          if (dragInsert.index == 0 && indexWithinParent === 0) {
            insertAbove = true;
          } else {
            // we are rendering an item within the target node
            const itemIsLast = (indexWithinParent >= (parent?.children?.length - 1));
            if (itemIsLast && dragInsert.index >= parent.children.length && dragInsert.index > 0) {
              insertBelow = true;
            } else {
              if (indexWithinParent === dragInsert.index && indexWithinParent > 0) {
                insertAbove = true;
              }
            }
          }
        }
      }
    }

    const className = clsx({
      'sortable-tree-item': true,
      'sortable-tree-item-dragging': (node.id === draggingNode?.id),
      'sortable-tree-item-insert-above': insertAbove,
      'sortable-tree-item-insert-below': insertBelow,
      'sortable-tree-item-insert-within': showDragInHighlight && node.id == dragInsert?.intoNodeId,
    }) + ' ' + (propsClassName ?? '');

    return <TreeItem
      className={className}
      data-nodeid={'' + node.id}
      data-parentid={'' + parent?.id}
      {...rest}
      classes={{
        content: 'sortable-tree-item-content',
        label: 'sortable-tree-item-label',
        selected: 'sortable-tree-item-selected',
        focused: 'sortable-tree-item-focused',
      }}
      nodeId={'' + node.id}
      label={
        <div>
          {renderItemContents(node)}
        </div>
      }
      onPointerOver={e => { e.preventDefault(); }}
    >
      {(node.children ?? []).map(child =>
        <SortableTreeViewItem
          {...this.props}
          parent={node}
          key={child.id} node={child}
          draggingNode={draggingNode} />,
      )}

    </TreeItem>;
  }
}

@observer
export class SortableTreeView extends React.Component<Props, State> {
  static defaultProps = {
    dragThreshold: 10,
  };

  disposers: Array<() => void> = [];
  containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  draggingRef: React.RefObject<HTMLDivElement> = React.createRef();

  lastPointerX = 0;
  lastPointerY = 0;
  cancelInsert: {
    intoNodeId: StringOrNumber;
    index: number;
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isDragging: false,
      dragProhibited: false,
    };
  }

  componentDidMount(): void {
    const interval = setInterval(this.handleIntervalAutoscroll, 10);
    this.disposers.push(() => {
      clearInterval(interval);
    });

    window.addEventListener('pointerup', this.handleWindowPointerUp);
    this.disposers.push(() => {
      window.removeEventListener('pointerup', this.handleWindowPointerUp);
    });
    window.addEventListener('pointermove', this.handleWindowPointerMove);
    this.disposers.push(() => {
      window.removeEventListener('pointermove', this.handleWindowPointerMove);
    });
  }

  componentWillUnmount(): void {
    this.disposers.forEach(d => d());
  }

  handleWindowPointerUp = () => {
    setTimeout(() => {
      // end the drag, even if outside of the element
      this.setState({
        isDragging: false,
        nodesWhileDragging: null,
        pointerDownElement: null,
        expandedWhileDragging: null,
        innerDragHtml: null,
      });
    });
  };

  handleWindowPointerMove = (event) => {
    if (!this.state.pointerDownElement) {
      return;
    }

    const {
      isDragging, pointerDownElement, pressedNode,
      pointerDownX, pointerDownY,
      nodesWhileDragging,
      dragBounds,
    } = this.state;
    const { nodes, expanded, canDragInto } = this.props;

    this.lastPointerX = event.clientX;
    this.lastPointerY = event.clientY;

    // if the pointer is down but dragging is not yet in progress, see if the drag threshold is exceeded
    if (pointerDownElement && pressedNode && !isDragging) {
      const delta = Math.sqrt(
        Math.pow(event.screenX - pointerDownX, 2) +
        Math.pow(event.screenY - pointerDownY, 2),
      );
      if (delta > this.props.dragThreshold) {
        this.setState({
          isDragging: true,
          dragProhibited: false,
          nodesWhileDragging: SortableTreeViewUtils.spliceNode(
            nodes,
            pressedNode),
          expandedWhileDragging: deepClone(expanded),
        });
      }
    }

    if (this.state.isDragging) {
      // we're dragging, update the "ghost" image of the element being dragged
      this.draggingRef.current.style.transform = `translate(${dragBounds.x + event.screenX - pointerDownX}px, ${dragBounds.y + event.screenY - pointerDownY}px)`;

      const newState: State = {
        ...this.state,
        dragInsert: { ...this.cancelInsert },
        dragProhibited: false,
      };

      const expandedNodeId = this.state.dragInsert?.expandedNodeId;
      if (expandedNodeId) {
        newState.dragInsert = { ...this.state.dragInsert };
      }

      // se what we've been dragged over and how to process it
      let sortableTreeItemElement = (event.target as HTMLElement).closest('.sortable-tree-item') as HTMLElement;
      if (sortableTreeItemElement) {
        let { nodeid = -1, parentid = -1 } = sortableTreeItemElement.dataset ?? {};
        let overNode = SortableTreeViewUtils.findNodeById(nodesWhileDragging, nodeid);

        let testNode = overNode;
        const cantDragInto = canDragInto?.(pressedNode, overNode) === false;

        if (!cantDragInto) {
          while (testNode && testNode.children) {
            if (!this.props.expanded?.includes('' + testNode.id)) {
              // In case we want to debug dragging over collapsed nodes futher, uncomment the following line:
              // console.log(`Dragged over collapsed node "${(testNode as any).value}"`);
              newState.dragInsert = {
                ...newState.dragInsert,
                expandedNodeId: testNode.id,
              };
              break;
            }
            testNode = testNode.parent;
          }
        }

        // if we can't drag into this element, iterate up the parent tree to find a parent we can drag into
        if (sortableTreeItemElement && pressedNode && cantDragInto) {
          while (overNode && overNode.parent) {
            if (canDragInto?.(pressedNode, overNode.parent) === false) {
              overNode = overNode.parent;
            } else {
              nodeid = overNode.id;
              parentid = overNode.parent?.id ?? -1;
              sortableTreeItemElement = sortableTreeItemElement.closest(`[data-nodeid="${nodeid}"]`) as HTMLElement;
              break;
            }
          }
        }

        // check the position of the drag so we can know if the user is trying to drag INTO an element or perhaps above
        // or below it
        const { clientY } = event;
        const { top, height } = sortableTreeItemElement.getBoundingClientRect();

        const inTop = clientY < (top + height * 0.25);
        const inBottom = clientY > (top + height * 0.75);
        const belowMidpoint = clientY > (top + height / 2);

        const allowDragInfo = overNode ? (canDragInto?.(pressedNode, overNode) ?? true) : false;

        // determine the new state IF we are dragging into the parent node (above or below the target node)
        let draggedIntoParentState: State;

        // but maybe we intended it to be a sibling of the parent node
        if ((inTop || inBottom) && overNode.parent) {
          if (canDragInto?.(pressedNode, overNode.parent) === true) {
            draggedIntoParentState = {
              ...newState,
            };
            draggedIntoParentState.dragInsert.intoNodeId = overNode.parent.id;
            draggedIntoParentState.dragInsert.index = overNode.parent.children?.findIndex(child => child.id == nodeid) ?? 0;
            if (!inTop) {
              ++draggedIntoParentState.dragInsert.index;
            }
          }
        }

        if (allowDragInfo === false) {
          // we attempted to drag into an element that could not accept it
          if (draggedIntoParentState) {
            // use the parent node instead
            Object.assign(newState, draggedIntoParentState);
          } else {
            // drag is prohbited
            newState.dragProhibited = true;
          }
        } else if (allowDragInfo === true) {
          // we are dragging into an item that could receive the item
          if (draggedIntoParentState) {
            // drag as a sibling of the item
            Object.assign(newState, draggedIntoParentState);
          } else {
            // drag into the item's children as the first element
            newState.dragInsert.intoNodeId = nodeid;
            newState.dragInsert.index = 0;
          }
        } else {
          if (overNode.id == pressedNode.id && !inTop) {
            return;
          }
          newState.dragInsert.intoNodeId = parentid;
          newState.dragInsert.index = overNode.parent?.children?.findIndex(child => child.id == nodeid) ?? 0;

          if (belowMidpoint) {
            ++newState.dragInsert.index;
          }
        }
      }

      if (this.state.dragProhibited !== newState.dragProhibited ||
        this.state.dragInsert?.index !== newState.dragInsert?.index ||
        this.state.dragInsert?.intoNodeId !== newState.dragInsert?.intoNodeId ||
        this.state.dragInsert?.expandedNodeId !== newState.dragInsert?.expandedNodeId) {
        newState.expandedWhileDragging = Array.from(new Set<string>([
          ...this.props.expanded,
          ...SortableTreeViewUtils.getExpandedNodes(newState.dragInsert.expandedNodeId, this.props.nodes),
        ]));
        this.setState(newState);
      }
    }
  };

  handleIntervalAutoscroll = () => {
    const { lastPointerY } = this;
    const { pointerDownElement } = this.state;
    if (pointerDownElement) {
      const current = this.containerRef.current?.querySelector('ul');
      if (current) {
        const { top, bottom } = current.getBoundingClientRect();
        const scrollSpeed = 6;

        /* eslint-disable no-nested-ternary */
        const scrollY = lastPointerY < top ? -scrollSpeed : lastPointerY > bottom ? scrollSpeed : 0;
        if (scrollX || scrollY) {
          current.scrollBy(scrollX, scrollY);
        }
      }
    }
  };

  renderItemContents = (node: SortableTreeViewNode) => {
    return this.props.renderItemContents(node);
  };

  handlePointerDown = (event: React.PointerEvent<HTMLElement>) => {
    if (event.target instanceof HTMLElement) {
      const draggableElement = event.target.closest('[data-draggable]');
      const sortableTreeItem = event.target.closest('.sortable-tree-item') as HTMLElement;

      if (draggableElement && draggableElement instanceof HTMLElement) {
        const nodeId = sortableTreeItem.dataset.nodeid;
        const pressedNode = SortableTreeViewUtils.findNodeById(this.props.nodes, nodeId);

        this.cancelInsert = {
          intoNodeId: pressedNode.parent?.id,
          index: pressedNode.parent?.children?.findIndex(child => child.id == nodeId) ?? 0,
        };

        const treeClientRect = this.containerRef.current?.getBoundingClientRect();
        const itemRect = sortableTreeItem.getBoundingClientRect();
        itemRect.x -= treeClientRect.x;
        itemRect.y -= treeClientRect.y;

        this.setState({
          isDragging: false,
          pointerDownElement: draggableElement,
          pointerDownX: event.screenX,
          pointerDownY: event.screenY,
          pressedNode,
          innerDragHtml: { __html: sortableTreeItem.innerHTML },
          dragBounds: itemRect,
        });
        this.lastPointerX = event.screenX;
        this.lastPointerY = event.screenY;
      }
    }
  };

  handlePointerUp = (_event: React.PointerEvent<HTMLElement>) => {
    const { pointerDownElement, isDragging, pressedNode, expandedWhileDragging, dragInsert } = this.state;
    if (pointerDownElement && isDragging && pressedNode) {
      if (!dragInsert) {
        // seen only in automated tests before adding sleeps, perhaps drag was too quick
        return;
      }
      const nodesWhileDragging = SortableTreeViewUtils.spliceNode(
        this.props.nodes.slice(),
        pressedNode,
        dragInsert ?? this.cancelInsert);

      const before = JSON.stringify(SortableTreeViewUtils.computeSimpleHierarchy(this.props.nodes));
      const after = JSON.stringify(SortableTreeViewUtils.computeSimpleHierarchy(nodesWhileDragging));
      if (before != after) {
        this.props.onChangeOrder?.(nodesWhileDragging,
          pressedNode.id,
          expandedWhileDragging,
        );
      }
    }
    this.setState({
      isDragging: false,
      nodesWhileDragging: null,
      pointerDownElement: null,
      pressedNode: null,
    });
  };

  render() {
    const {
      nodes, expanded, dragThreshold, renderItemContents, canDragInto, onChangeOrder, onNodeDraggedInto,
      ...rest
    } = this.props;

    const {
      isDragging, dragInsert,
      innerDragHtml,
      dragProhibited,
    } = this.state;

    const nodesInUse = this.state.isDragging ? this.state.nodesWhileDragging : nodes;
    const expandedInUse = this.state.expandedWhileDragging ?? expanded;

    const className = (this.props.className ?? '') + (isDragging ? ' tree-view-dragging' : ' ');

    let showDragInHighlight = true;
    if (dragInsert?.intoNodeId) {
      const node = SortableTreeViewUtils.findNodeById(nodes, dragInsert.intoNodeId);
      if (node?.children?.length) {
        showDragInHighlight = false;
      }
    }

    let selected = this.props.selected;
    if (this.state.isDragging) {
      selected = '';
    }
    return (
      <div className='SortableTreeView' ref={this.containerRef}>
        <TreeView
          {...rest}
          className={className}
          onPointerDown={this.handlePointerDown}
          onPointerUp={this.handlePointerUp}
          expanded={expandedInUse}
          selected={selected}>
          {nodesInUse.map(child => <SortableTreeViewItem
            className={dragProhibited ? 'cursor-not-allowed' : ''}
            showDragInHighlight={showDragInHighlight}
            key={child.id}
            renderItemContents={this.renderItemContents}
            node={child}
            parent={null}
            dragInsert={isDragging ? dragInsert : null}
            draggingNode={this.state.isDragging ? this.state.pressedNode : undefined}
          />)}
        </TreeView>

        {isDragging &&
          <div className='dragging-placeholder' ref={this.draggingRef}
            dangerouslySetInnerHTML={innerDragHtml}>
          </div>}
      </div>
    );
  }
}
