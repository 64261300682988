import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { compose } from 'redux'

const itemSource = {
  beginDrag(props) {
    const { id, index, onSortEnd } = props
    return { id, index, onSortEnd }
  },
}

const itemTarget = {
  hover(props, monitor) {
    const { index, onSortEnd } = props
    const dragIndex = monitor.getItem().index
    const dragId = monitor.getItem().id

    if (dragIndex !== index) {
      onSortEnd(dragId, index)
      monitor.getItem().index = index
    }
  },
}

function sourceCollect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

function targetCollect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  }
}

export default function SortableItem(type: string, Component: Object) {
  class Item extends React.Component {
    componentDidMount() {
      this.props.connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      })
    }

    render() {
      const { isDragging, connectDragSource, connectDropTarget } = this.props
      const opacity = isDragging ? 0 : 1

      return connectDropTarget(
        <div style={{ opacity }}>
          <Component {...this.props} dragHandle={connectDragSource} />
        </div>
      )
    }
  }

  return compose(
    DragSource(type, itemSource, sourceCollect),
    DropTarget(type, itemTarget, targetCollect)
  )(Item)
}
