import React from 'react'
import { createRoot } from 'react-dom/client'

import AppContainer from '@/shared/components/AppContainer.jsx'
import MoleculeFieldDefinitionsContainer from './container.js'
import configureStore from './reducers'
import camelCaseKeys from '@/shared/utils/camelCaseKeys.js'

export default function MoleculeFieldDefinitions(props) {
  const rootElement = document.getElementById('moleculeFieldDefinitions')
  const store = configureStore(camelCaseKeys(props))
  const root = createRoot(rootElement)

  const renderApp = () => {
    root.render(
      <AppContainer store={store}>
        <MoleculeFieldDefinitionsContainer />
      </AppContainer>,
      rootElement
    )
  }

  renderApp()
}
