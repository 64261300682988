import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import editable from './modules/editable.js'
import ElnFieldDefinitions from './component.jsx'

function mapStateToProps(state) {
  return {
    editing: state.get('editing'),
    elnFieldDefinitions: state.getIn(['current', 'elnFieldDefinitions']),
    errorMessages: state.get('errorMessages'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    edit: editable.actions.edit,
  }, dispatch)
}

const ElnFieldDefinitionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ElnFieldDefinitions)

export default ElnFieldDefinitionsContainer
