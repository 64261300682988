import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import DataTable from './components/DataTable/component.jsx'
import EditDataTable from './components/EditDataTable'
import EditLink from '@/shared/components/EditLink.jsx'
import Errors from '@/shared/components/Errors.jsx'

export default class RunFieldDefinitions extends React.Component {
  render() {
    const {
      editing,
      errorMessages,
      runFieldDefinitions,
      edit,
      removedRunFieldDefinitionsWithData,
      usingRegistrationSystem,
      runFieldDefinitionsLocked,
    } = this.props
    const runStr = Terminology.dictionary.run.titleize()

    if (editing) {
      return (
        <div className="subcontainer">
          <h2>{runStr} Fields</h2>
          <Errors errorMessages={errorMessages} />
          <EditDataTable
            runFieldDefinitions={runFieldDefinitions}
            removedRunFieldDefinitionsWithData={removedRunFieldDefinitionsWithData}
          />
        </div>
      )
    } else {
      return (
        <div className="subcontainer">
          <EditLink onClick={edit} disabled={runFieldDefinitionsLocked}>
            Add/Edit {runStr} Fields
          </EditLink>
          <h2>{runStr} Fields</h2>
          <DataTable
            runFieldDefinitions={runFieldDefinitions}
            usingRegistrationSystem={usingRegistrationSystem}
          />
        </div>
      )
    }
  }
}
