import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { PickListEditor } from '@/shared/components/FieldDefinitionsTable'
import {
  getBatchFieldDefinition,
  getPickListValues,
  isEditingPickList,
  getInvalidIds,
  getNotification,
  getInput,
} from '@/FieldDefinitions/BatchFieldDefinitions/selectors.js'
import { actions } from '@/FieldDefinitions/BatchFieldDefinitions/modules/pickListValues.js'

function mapStateToProps(state, ownProps) {
  const batchFieldDefinition = getBatchFieldDefinition(state, ownProps.id)
  const pickListValues = getPickListValues(state, ownProps.id)
  const open = isEditingPickList(state, ownProps.id)
  const invalidIds = getInvalidIds(state)
  const notification = getNotification(state)
  const input = getInput(state)

  return {
    batchFieldDefinition,
    pickListValues,
    open,
    invalidIds,
    notification,
    input,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators({
    edit: () => actions.edit(ownProps.id),
    onSubmit: actions.submit,
    onCancel: actions.cancelEdit,
    updateInput: actions.updateInput,
    add: actions.add,
    addMany: actions.addMany,
    update: actions.update,
    remove: actions.remove,
  }, dispatch)
}

const PickListEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PickListEditor)

export default PickListEditorContainer
