import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { withStyles } from '@mui/styles'

import {
  MenuItem,
  NoSsr,
  Paper,
  TextField,
  Typography,
  emphasize,
} from '@mui/material'

const theme_spacing_unit = 8 // theme.spacing.unit

// Style stuff to make the display work with material-ui
const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme_spacing_unit / 2}px ${theme_spacing_unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme_spacing_unit}px ${theme_spacing_unit * 2}px`,
  },
  singleValue: {
    // fontSize: '1.000rem',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    // fontSize: '1.000rem',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme_spacing_unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme_spacing_unit * 2,
  },
})

// Functions used to display material-ui inside react-select
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

const inputComponent = React.forwardRef((props, _ref) => {
  return (<div {...props} />)
})
inputComponent.displayName = 'SolventPicker.inputComponent'

function Control(props) {
  return (
    <TextField
      disabled={props.isDisabled}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  const { children } = props
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </MenuItem>
  )
}

function Placeholder(props) {
  const textColor = props.isDisabled ? 'inherit' : 'textSecondary'
  return (
    <Typography
      color={textColor}
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  const { data } = props
  const textColor = props.isDisabled ? 'inherit' : 'textSecondary'
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      color={textColor}
      {...props.innerProps}
    >
      {data.label}
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

// The list of components that are customized for display (material-ui inside react-select)
const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

class Picker extends React.Component {
  state = {
    'single': null,
  }

  setOption = (option) => {
    if (this.props.setValue != undefined) {
      this.props.setValue(option)
    } else {
      this.setState({
        'single': option,
      })
    }
  }

  render() {
    const { classes, theme, defaultValue } = this.props
    const single = this.props.value || this.state.single

    const selectStyles = {
      container: base => ({
        ...base,
        // hack to make the container the right height (so Material-UI upgrade doesn't break things)
        '> div > div > div': {
          height: 24,
        },
        '> div > div > div > div:nth-child(1)': {
          float: 'left'
        },
        '> div > div > div > div:nth-child(2)': {
          float: 'right'
        },
      }),
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    }
    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            className='solvent-name'
            classes={classes}
            styles={selectStyles}
            components={components}
            defaultValue={defaultValue}
            isDisabled={this.props.disabled}
            name={this.props.name}
            onChange={this.setOption}
            options={this.props.options}
            placeholder="No Solvent"
            single={single}
          />
        </NoSsr>
      </div>
    )
  }
}

Picker.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(Picker)
