import { HasIdAndName } from '@/Accounts/types';
import { ColumnDef, SimpleDataTable } from '@/components';
import { AnyObject, StringOrNumber } from '@/types';
import { Collapse } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { ColumnsEditor } from '../ColumnsEditor/ColumnsEditor';
import { SimpleDataTableRenderType } from '../SimpleDataTable/SimpleDataTable';
import StickyDataTable from './StickyDataTable';
import StickyDataTableHeader from './StickyDataTableHeader';

type Props<TData extends HasIdAndName> = Omit<
  React.ComponentProps<typeof SimpleDataTable>,
  'rows' | 'renderCustomHeaderCell'
> & {
  columns: Array<ColumnDef>;
  availableColumns?: Array<ColumnDef>;
  tables: Array<{
    group: TData;
    rows: Array<AnyObject>;
  }>;
  visibleColumns?: Array<StringOrNumber>;
  noDataMessage?: string;
  setVisibleColumns?: (value: Array<StringOrNumber>) => void;
  collapsedHeight?: number;

  getOverridePropsForGroup?: (group: TData) => Partial<Props<TData>>;
  renderAdditionalHeaderElements?: (group: TData) => React.ReactNode;
  renderAdditionalHeaderRow?: (group: TData) => React.ReactNode; // below the top header row
  renderCustomHeaderCell?: (
    column: ColumnDef,
    group: TData,
  ) => SimpleDataTableRenderType;
};

type State = {
  expandedState: { [projectId: number]: boolean };
};

@observer
export default class MultipleTablesWithStickyHeaders<
  TData extends HasIdAndName,
> extends React.Component<Props<TData>, State> {
  static defaultProps: Partial<Props<HasIdAndName>> = {
    collapsedHeight: 40,
  };

  constructor(props: Props<TData>) {
    super(props);
    this.state = {
      expandedState: {},
    };
  }

  isExpanded(projectId: number) {
    return this.state.expandedState[projectId] ?? true;
  }

  handleToggleExpand = (tableData: TData) => {
    this.setState({
      expandedState: {
        ...this.state.expandedState,
        [tableData.id]: !this.isExpanded(tableData.id),
      },
    });
  };

  renderCustomHeaderCell = (column: ColumnDef, group: TData) => {
    if (column.id === 'btn_edit_columns') {
      return (
        <ColumnsEditor
          availableColumns={this.props.availableColumns}
          value={this.props.visibleColumns}
          onChangeValue={this.props.setVisibleColumns}
        />
      );
    }
    return this.props.renderCustomHeaderCell?.(column, group);
  };

  renderAdditionalElements = (group: TData) => {
    return this.props.renderAdditionalHeaderElements?.(group);
  };

  renderAdditionalHeaderRow = (group: TData) => {
    return this.props.renderAdditionalHeaderRow?.(group);
  };

  renderHeaderRow = (group: TData) => {
    return (
      <StickyDataTableHeader
        group={group}
        expanded={this.isExpanded(group.id)}
        showExpandButton={Object.keys(length).length > 1}
        onChangeExpanded={this.handleToggleExpand}
        renderAdditionalElements={() => this.renderAdditionalElements(group)}
        renderAdditionalHeaderRow={() => this.renderAdditionalHeaderRow(group)}
      />
    );
  };

  render() {
    const { tables, availableColumns, noDataMessage, collapsedHeight } =
      this.props;
    let { columns } = this.props;

    if (availableColumns) {
      columns = [...columns, { id: 'btn_edit_columns', label: '', width: 30 }];
    }

    return (
      <div className='MultipleTablesWithStickyHeaders'>
        {tables.map((table) => {
          const isExpanded = this.isExpanded(table.group.id);
          return (
            <div
              key={table.group.id}
              className={`table-container ${isExpanded ? 'table-container-expanded' : ''}`}
            >
              <Collapse
                in={isExpanded}
                style={{ visibility: 'visible', minHeight: collapsedHeight }}
              >
                <StickyDataTable
                  {...this.props}
                  infiniteScrollHeight={200}
                  columns={columns}
                  group={table.group}
                  rows={table.rows}
                  {...this.props.getOverridePropsForGroup?.(table.group)}
                  renderHeaderRow={() => this.renderHeaderRow(table.group)}
                />
                {table.rows.length === 0 && noDataMessage && (
                  <div className='noDataMessage'>{noDataMessage}</div>
                )}
              </Collapse>
            </div>
          );
        })}
      </div>
    );
  }
}
