import { LocationNode, LocationValue, Sample } from '@/Samples/types';
import { Card, CircularProgress, Fade } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { LocationBoxPosition } from './LocationBoxPosition';
import { observer } from 'mobx-react';

type Props = {
  box: LocationNode;
  width: number;
  height: number
  value?: LocationValue;
  loading?: boolean;
  samples?: Array<Sample>;
  onChange?: (value: LocationValue) => void;
};

@observer
export class LocationBoxPicker extends React.Component<Props> {
  handleClickElement = (position: number) => {
    this.props.onChange?.({
      id: this.props.box.id,
      position,
      value: '',
    });
  };

  render() {
    const { value, box, width, height, loading, samples } = this.props;
    if (!this.props.box || !this.props.box?.num_rows || !this.props.box?.num_columns) {
      return null;
    }

    const maxDimensions = Math.max(box.num_columns, box.num_rows);
    const cellPadding = Math.min(5, Math.max(2, 30 / maxDimensions));

    const boxMargin = 0;

    const rowColLabelSize = 25;
    let positionsWidth = width - rowColLabelSize - cellPadding * 2;
    let positionsHeight = height - rowColLabelSize - cellPadding * 2;

    const cellWidth = Math.max((positionsWidth - boxMargin * 2) / box.num_columns, 30);
    const cellHeight = Math.max((positionsHeight - boxMargin * 2) / box.num_rows, 30);
    const cellSizeWithPadding = Math.min(cellWidth, cellHeight);
    positionsWidth = cellSizeWithPadding * box.num_columns;
    positionsHeight = cellSizeWithPadding * box.num_rows;

    const cellSize = cellSizeWithPadding - cellPadding * 2 - 2;
    const cardWidth = rowColLabelSize + cellSizeWithPadding * box.num_columns + boxMargin * 2 + cellPadding * 2;
    const cardHeight = rowColLabelSize + cellSizeWithPadding * box.num_rows + boxMargin * 2 + cellPadding * 2;

    return <Box className='LocationBoxPicker'>
      <Card raised={true} style={{ position: 'relative', width: cardWidth, height: cardHeight }}>

        {/* Render column labels */}
        <div className='col-labels' style={{ marginLeft: cellPadding + 2 }}>
          {new Array(box.num_columns).fill(null).map((v, col) =>
            <label key={col} style={{ width: cellSizeWithPadding }}>{col + 1}</label>,
          )}
        </div>

        {/* Render row labels */}
        <div className='row-labels' style={{ marginTop: rowColLabelSize, width: rowColLabelSize }}>
          {new Array(box.num_rows).fill(null).map((v, col) =>

            <div key={col} style={{ height: cellSizeWithPadding }}>
              <label style={{ width: rowColLabelSize }}>{String.fromCharCode(65 + col)}</label>
            </div>,
          )}
        </div>

        <div className='positions'
          style={{ width: positionsWidth, height: positionsHeight, marginTop: rowColLabelSize, marginLeft: rowColLabelSize }}>
          {new Array(box.num_rows).fill(null).map((v, row) =>
            <div key={row} className='row' style={{ height: cellSizeWithPadding }}>
              {new Array(box.num_columns).fill(null).map((v, col) => {
                const position = row * box.num_columns + col + 1;
                const selected = (value?.id === box.id && value.position === position);
                const sample = (samples ?? []).find(s => s.location.position === position);
                return <LocationBoxPosition
                  key={col}
                  position={position}
                  selected={selected}
                  sample={sample}
                  cellSize={cellSize} cellPadding={cellPadding}
                  onClick={this.handleClickElement} />;
              })}

            </div>,
          )}
        </div>
        <Fade in={loading} appear={false} timeout={500}>
          <div className='loading-mask'>
            <CircularProgress className='loading-spinner' />
          </div>
        </Fade>
      </Card>
    </Box>;
  }
}
