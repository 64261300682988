import { LocationValue } from '@/Samples/types';
import { getRootStore } from '@/stores/rootStore';
import { LocationPickerDialog } from './LocationPickerDialog';
import { LocationUtils } from './locationUtils';
import { observer } from 'mobx-react';
import React from 'react';

type Props = {
  value: LocationValue;
  name: string;
  context: string;
  locations: Array<LocationValue>;
};

type State = {
  open?: boolean;
  value?: LocationValue;
};

@observer
export class LocationPickerFormField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  get store() {
    return getRootStore().locationStore;
  }

  render() {
    const { locations, fetchBoxContents } = this.store;
    const value = this.state.value?.id ? this.state.value : null;
    const { name } = this.props;
    if (value?.id && !value?.value) {
      value.value = LocationUtils.getLabelForLocation(value, locations);
    }
    return <>
      <input name={name} hidden readOnly
        value={value?.id ? `${value.id},${value.position}` : ''} />

      <input className='input-text' id="location-picker-input" readOnly
        value={value?.value ?? ''} placeholder='Enter location'
        onClick={() => this.setState({ open: true, value })} />

      <LocationPickerDialog
        {...this.props}
        open={this.state?.open}
        value={this.state.open ? this.state.value : null}
        onCancel={() => this.setState({ open: false, value: null })}
        onChange={value => this.setState({ value })}
        onSubmit={value => {
          if (!value.value) {
            value.value = LocationUtils.getLabelForLocation(value, locations);
          }
          this.setState({ open: false, value });
        }}
        locations={locations}
        fetchBoxContents={fetchBoxContents}
      />
    </>;
  }
}
