import React from 'react'

import { SearchField } from './SearchField'

// In Typescript, this is how you would do it:
// import { SearchFieldProps } from './SearchField'

type Props = /* SearchFieldProps */ & {
  enableSearch: boolean
};

export class SearchBar extends React.Component<Props> {
  state = {
    filterPanelVisible: false,
  }

  handleFilterPanelClose = () => {
    this.setState({ filterPanelVisible: false })
  }

  handleFilterPanelToggle = () => {
    this.setState({ filterPanelVisible: !this.state.filterPanelVisible })
  }

  render() {
    const { filterPanelVisible } = this.props

    return (
      <div className="search-bar">
        <SearchField
          {...this.props}
          oldButtonStyle={true}
        />
        {filterPanelVisible && <div className="search-bar__summary">
        </div>}
      </div>
    )
  }
}
