import React from 'react'

export default function UniqueCheckbox(props) {
  const {
    onChange,
    checked,
    disabled,
    dataTypeName,
    disabledText,
    readOnly,
    uneditable,
  } = props

  const disabledFromDataType = dataTypeName !== 'Text'
  const isDisabled = disabled || disabledFromDataType

  var title = null
  if (disabledText && isDisabled) {
    title = disabledText
  } else if (disabledFromDataType) {
    title = 'Only text fields can be unique'
  }

  const name = props.name || 'unique'

  if (uneditable) {
    return null
  } else {
    return (
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={isDisabled}
        readOnly={readOnly}
        title={title}
      />
    )
  }
}

UniqueCheckbox.defaultProps = {
  dataTypeName: 'Text',
}
