import { combineReducers } from 'redux-immutable'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import moleculeFieldDefinitions, { moleculeFieldDefinitionsInitialState }
  from './moleculeFieldDefinitions.js'
import pickListValues from '@/FieldDefinitions/MoleculeFieldDefinitions/modules/pickListValues.js'
import editable from '@/FieldDefinitions/MoleculeFieldDefinitions/modules/editable.js'
import persistable from '@/FieldDefinitions/MoleculeFieldDefinitions/modules/persistable.js'
import reactions from '../middleware/reactions.js'
import reinitAfterSaveOrCancel from './reinitAfterSaveOrCancel.js'
import composeWithDevTools from '@/shared/utils/composeWithDevTools.js'

const reducer = reinitAfterSaveOrCancel(
  persistable.reducer(
    editable.reducer(
      combineReducers({
        moleculeFieldDefinitions,
        pickListValues: pickListValues.reducer,
      })
    )
  )
)

export function initialState(moleculeFieldDefinitions) {
  const parsedBatchFieldDefinitions = moleculeFieldDefinitions.map(obj => obj.molecule_field_definition)

  return persistable.initialState().toMap()
    .merge(
      editable.initialState({
        current: {
          moleculeFieldDefinitions: moleculeFieldDefinitionsInitialState(moleculeFieldDefinitions),
          pickListValues: pickListValues.initialState(parsedBatchFieldDefinitions),
        },
      })
    )
}

export default function configureStore({
  moleculeFieldDefinitions,
  vaultMoleculeFieldDefinitionsUrl,
  usingRegistrationSystem,
  moleculeFieldDefinitionsLocked,
  allowsMultipleMoleculesPerStructure,
} = {}) {
  const _initialState = initialState(moleculeFieldDefinitions)
    .merge({
      vaultMoleculeFieldDefinitionsUrl,
      usingRegistrationSystem,
      moleculeFieldDefinitionsLocked,
      allowsMultipleMoleculesPerStructure,
    })

  return createStore(
    reducer,
    _initialState,
    composeWithDevTools(
      applyMiddleware(
        reactions,
        thunk
      )
    )
  )
}
