import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

export default function UniquenessNote() {
  return (
    <p className="muted">
      {`Note: Uniqueness is compared across all ${Terminology.dictionary['batch']} fields within the vault.`}
    </p>
  )
}
