import { combineReducers } from 'redux-immutable'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import runFieldDefinitions, { runFieldDefinitionsInitialState }
  from './runFieldDefinitions.js'
import pickListValues from '@/FieldDefinitions/RunFieldDefinitions/modules/pickListValues.js'
import editable from '@/FieldDefinitions/RunFieldDefinitions/modules/editable.js'
import persistable from '@/FieldDefinitions/RunFieldDefinitions/modules/persistable.js'
import reactions from '../middleware/reactions.js'
import reinitAfterSaveOrCancel from './reinitAfterSaveOrCancel.js'
import composeWithDevTools from '@/shared/utils/composeWithDevTools.js'

const reducer = reinitAfterSaveOrCancel(
  persistable.reducer(
    editable.reducer(
      combineReducers({
        runFieldDefinitions,
        pickListValues: pickListValues.reducer,
      })
    )
  )
)

export function initialState(runFieldDefinitions) {
  const parsedRunFieldDefinitions = runFieldDefinitions.map(obj => obj.run_field_definition)

  return persistable.initialState().toMap()
    .merge(
      editable.initialState({
        current: {
          runFieldDefinitions: runFieldDefinitionsInitialState(runFieldDefinitions),
          pickListValues: pickListValues.initialState(parsedRunFieldDefinitions),
        },
      })
    )
}

export default function configureStore({
  runFieldDefinitions,
  vaultRunFieldDefinitionsUrl,
  usingRegistrationSystem,
  runFieldDefinitionsLocked,
} = {}) {
  const _initialState = initialState(runFieldDefinitions)
    .merge({
      vaultRunFieldDefinitionsUrl,
      usingRegistrationSystem,
      runFieldDefinitionsLocked,
    })

  return createStore(
    reducer,
    _initialState,
    composeWithDevTools(
      applyMiddleware(
        reactions,
        thunk
      )
    )
  )
}
