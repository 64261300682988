/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText, FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Switch,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { TreeItemDef } from '@/shared/components/DDForm/types/treeDef';
import { TabContext, TabList, TabPanel, TreeItem, TreeView } from '@mui/lab';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { DDFormProps } from '../DDForm';
import { DDFormUtils } from '../DDFormUtils';
import {
  FormElementRendererMap,
  RenderElementFunction,
} from '../renderers/formRenderMap';
import { FieldValueType } from '../types';
import { ElementDef } from '../types/base/elementDef';
import { FieldDef } from '../types/base/fieldDef';
import { GroupDef } from '../types/base/groupDef';
import { ButtonDef } from '../types/buttonDef';
import { CheckboxDef } from '../types/checkboxDef';
import { CustomFieldDef } from '../types/customFieldDef';
import { DateInputDef } from '../types/dateInputDef';
import { NumberInputDef } from '../types/numberInputDef';
import { PageElementDef } from '../types/pageElementDef';
import { RowColumnDef, RowDef } from '../types/rowColumnDef';
import { SelectMultipleDef } from '../types/selectMultipleDef';
import { SelectCommonFields, SelectSingleDef } from '../types/selectSingleDef';
import { StepperDef } from '../types/stepperDef';
import { TextInputDef } from '../types/textInputDef';
import { ToggleDef } from '../types/toggleDef';
import { TreeDef } from '../types/treeDef';
import { TypographyDef } from '../types/typographyDef';
import { TextFieldCursorFix } from '@/shared/components/DDForm/mui/TextFieldCursorFix';
import { RadioGroupDef } from '@/shared/components/DDForm/types/radioGroupDef';
import { AnyObject } from '@/types';
import { ChevronRight, ExpandMore, Error } from '@mui/icons-material';
import { TabsDef } from '../types/tabsDef';

import './muiRenderers.sass';
import { TestableAutocomplete, TestableSelect, TestableTextFieldSelect } from '@/shared/components/TestableMuiComponents/TestableSelect';

const fullWidthStyle: React.CSSProperties = {
  width: '100%',
};

const getNameAttribute = (field: ElementDef, props: DDFormProps) => {
  return (props.renderNameAttribute ?? true) ? { name: field.key } : { 'data-name': field.key };
};

const getControlRenderProperties = (inField: ElementDef) => {
  const result = {
    ...inField.controlAttributes,
    id: inField.id,
  };
  return result;
};

const getInputProps = (field: ElementDef, overrides: AnyObject = {}) => {
  const result = {
    className: 'material-input',
    ...(_.pick(field, ['maxLength', 'min', 'max', 'step'])),
    ...overrides,
  };
  return _(result).omitBy(_.isUndefined).value();// as Partial<typeof result>;
};

const renderElement: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  if (element.visible === false) {
    return <></>;
  }
  // render the item
  let item =
    muiRendererMap[element.type] &&
    muiRendererMap[element.type](
      props,
      element,
      getValue,
      setValue,
      formErrors,
    );

  const { tooltip } = element as FieldDef;
  const { legend } = element as GroupDef;

  let result = item;
  if ('disabled' in element && element.disabled) {
    item = <span>{item}</span>;
  }
  if (tooltip) {
    result = (
      <Tooltip title={tooltip} {...element.tooltipProps}>
        {item}
      </Tooltip>
    );
  }
  if (legend) {
    result = (
      <fieldset>
        <legend>{legend}</legend>
        {item}
      </fieldset>
    );
  }
  return result;
};

/**
 * Render a simple group (row or column) of other elements
 * @param props
 * @param field
 * @param getValue
 * @param setValue
 * @returns
 */
const renderSimpleGroup: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as RowColumnDef;

  const getRenderProperties = (inField: ElementDef) => {
    Object.keys(inField).forEach((key) => {
      if ((inField as any)[key] === undefined) {
        delete (inField as any)[key];
      }
    });
    const result = {
      attributes: {} as AnyObject,
      style: {} as React.CSSProperties,
      className: '',
    };

    if (field.noPadding) {
      result.style = { padding: 0 };
    }

    if (inField.width === 'expand') {
      result.attributes.xs = true;
    } else if (inField.width !== undefined) {
      result.style.width = inField.width;
    }

    if (inField.key) {
      result.attributes['data-key'] = inField.key;
    }

    const testId = inField.id ?? inField.key;
    if (!['select', 'text', 'number'].includes(inField.type)) {
      // Scott, it was probably a bad decision to put the id on the container, but our tests expect it now.
      // The TestableSelect class will render the id on the select itself, so we don't need it here.
      //
      // TODO: when the dust settles, remove the id from the container, apply it only to the control and update the tests.
      result.attributes.id = testId;
    }

    if ((inField as any).label) {
      result.attributes['data-label'] = (inField as any).label;
    }

    if (testId) {
      result.attributes['data-testid'] = testId;
    }

    if (inField.name) {
      result.attributes.name = inField.name;
    }
    let elementType = inField.type;
    if (inField.type === 'stepper' && !(inField as StepperDef).useStepper) {
      elementType = 'tabs';
    }

    const classes: Array<string> = ['mui-element', `element-${elementType}`];

    if (inField.type === 'select' && (inField as SelectCommonFields).typeahead) {
      classes.push('element-select-typeahead');
    }
    if (inField.variant) {
      classes.push(`element-${inField.type}-${inField.variant}`);
    }

    if (inField.className) {
      classes.push(inField.className);
    }
    result.className = classes.join(' ');

    return result;
  };

  const { attributes, style, className } = getRenderProperties(field);

  const contents = field.children
    .filter((child) => child && child.visible !== false)
    .map((child, index) => {
      const { attributes, style, className } = getRenderProperties(child);

      return (
        <Grid
          item
          key={index}
          id={field.id ?? field.key}
          {...attributes}
          style={style}
          className={className}
          xs={attributes.xs ? true : undefined}
        >
          {renderElement(props, child, getValue, setValue, formErrors)}
        </Grid>
      );
    });

  if (element.type === 'row' && (element as RowDef).useSpan) {
    return <>{contents}</>;
  }

  const container = (
    <Grid
      container
      direction={field.type}
      style={style}
      xs={undefined}
      spacing={field.spacing}
      id={field.id ?? field.key}
      {...field.controlAttributes}
    >
      {contents}
    </Grid>
  );
  return container;
};

/**
 * Render a field as a text input
 * @param field
 * @returns
 */
const renderText: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as TextInputDef;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (props.blacklistedCharacters?.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(field, e.currentTarget.value);
  };

  const value = getValue(field);
  const error = props.showErrors && formErrors && formErrors[field.key];

  const inputProps = getInputProps(field, {
    autoComplete: field.autocomplete ? 'on' : 'off',
    'data-1p-ignore': (field.autocomplete === false), // https://1password.community/discussion/comment/677269/#Comment_677269
  });
  let style = fullWidthStyle;
  if (field.controlAttributes?.style) {
    style = { ...style, ...(field.controlAttributes.style as AnyObject) };
  }
  return (
    <TextFieldCursorFix
      {...getControlRenderProperties(field)}
      {...getNameAttribute(field, props)}
      style={style}
      id={field.id ?? field.key}
      className={field.className}
      data-key={field.key}
      label={field.label}
      inputProps={inputProps}
      error={!!error}
      helperText={error}
      onKeyDown={handleKeyPress}
      value={value || ''}
      onChange={handleChange}
      autoFocus={field.autoFocus}
      required={!!field.required}
      disabled={field.disabled === true}
    />
  );
};

/**
 * Render a field as a text input
 * @param field
 * @returns
 */
const renderDate: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as DateInputDef;
  const error = props.showErrors && formErrors && formErrors[field.key];
  const format = field.format ?? 'YYYY-MM-DD';

  const handleChange = (date: { format?: (string) => string } | null) => {
    setValue(field, date?.format(format) ?? '');
  };

  const value = getValue(field); // DDFormUtils.convertValueToDate(getValue(field));

  return (
    <FormControl {...field?.formOptions} style={fullWidthStyle}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          {...getControlRenderProperties(field)}
          {...getNameAttribute(field, props)}
          label={field.label}
          inputFormat={format}
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField
            {...params}
            error={!!error}
            helperText={error}
          />}
        />
      </LocalizationProvider>

    </FormControl>
  );
};

/**
 * Render a field as a numeric input
 * @param field
 * @returns
 */
const renderNumericInput: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as NumberInputDef;
  const error = props.showErrors && formErrors && formErrors[field.key];
  const value = getValue(field) ?? '';
  const passValue = isNaN(parseFloat('' + value)) ? '' : value.toString();
  const inputProps = getInputProps(field);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(field, e.currentTarget.value);
  };

  return (
    <TextField
      {...getControlRenderProperties(field)}
      {...getNameAttribute(field, props)}
      type='number'
      placeholder={field.placeholder}
      style={fullWidthStyle}
      key={field.key}
      label={field.label}
      className={field.className}
      inputProps={inputProps}
      error={!!error}
      helperText={error}
      // for some reason, this doesn't well work as a controlled input (https://www.pivotaltracker.com/story/show/185912367)
      // probably it's fine to have it uncontrolled, but this should be investigated
      defaultValue={passValue}
      onChange={handleChange}
      autoFocus={field.autoFocus}
      required={!!field.required}
      disabled={field.disabled ?? false}
      onFocus={event => {
        // when this gets focus, select the contents. Otherwise since the default is "0", typing "1" may result in "10"
        event.target.select();

        // there's a scenario in which you have two numeric inputs on a page and one or both has validation errors, and
        // without stopping the event propagation the focus rapidly cycles between the two
        event.stopPropagation();
        event.preventDefault();

        // I don't know why this ugly hack is necessary, but sometimes the selection is reset when clicking
        setTimeout(() => {
          event.target.select();
        });
      }}
    />
  );
};

/**
 * Render a field as a select control (single or multiple)
 * @param field
 * @returns
 */
const renderSelectCore: RenderElementFunction = (
  formProps: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as SelectSingleDef | SelectMultipleDef;
  const error = formProps.showErrors && formErrors && formErrors[field.key];
  const isMultiple = field.type === 'multiselect';
  const optionFieldId = field.optionFieldId ?? 'id'; // also value
  const optionFieldLabel = field.optionFieldLabel ?? 'label';
  const optionFieldGroup = field.optionFieldGroup ?? 'group';

  const { typeahead = false } = field;

  const title = field.tooltip && typeof field.tooltip === 'string' ? field.tooltip : null;

  const toSafeValue = (value) => {
    // MUI Select doesn't allow for blank values, so use ' ' to mean ''
    if (value === '' || value === undefined) {
      return ' '; // TODO: fix warning "MUI: You have provided an out-of-range value ` ` for the select...""
    }
    // if (typeof value === 'number') {
    //   return value.toString();
    // }
    if (Array.isArray(value)) {
      return value.map(item => toSafeValue(item));
    }
    return value;
  };

  const fromSafeValue = (value) => {
    // MUI Select doesn't allow for blank values, so use ' ' to mean ''
    if (value === ' ') {
      return '';
    }
    if (Array.isArray(value)) {
      return value.map(item => fromSafeValue(item));
    }
    return value;
  };

  const handleChangeSelect = (e: SelectChangeEvent<{ name?: string; value: unknown; }>) => {
    // MUI Select doesn't allow for blank values, so use ' ' to mean ''
    const value = e.target.value as string;
    setValue(field, fromSafeValue(value));
  };

  const handleChangeMultipleSelect = (e: SelectChangeEvent<{ name?: string; value: unknown; }>) => {
    setValue(field, fromSafeValue(e.target.value as unknown as string[]));
  };

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    setValue(field, fromSafeValue(e.target.value as string));
  };

  const handleChangeMultiple = (e: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    setValue(field, fromSafeValue(e.target.value as unknown as string[]));
  };

  let value = getValue(field);
  if (isMultiple) {
    value = DDFormUtils.convertValueToArrayString(value);
  }

  value = toSafeValue(value);

  const selectionAttributes = {
    'data-qa-selection': value,
    'data-qa-options': JSON.stringify(field.selectOptions),
    'className': 'hasDataQaOptions',
  };
  // typeahead case
  if (typeahead) {
    const commonTypeAhead: Partial<React.ComponentProps<typeof Autocomplete>> = {
      disabled: field.disabled ?? false,
      options: field.selectOptions ?? [],
      getOptionLabel: (option) => ('' + (option as AnyObject)[optionFieldLabel]),
      PaperComponent: (props) => (
        <Paper {...props} className={`${props.className ?? ''} MuiDDForm-select-paper-${formProps.size}`} />),
      style: fullWidthStyle,
      ...getNameAttribute(field, formProps),
      title,
    };

    // create a new array of options with the id made safe ('' => ' '), but otherwise entries
    // are left unchanged, which is important because the options/values may be objects
    commonTypeAhead.options = commonTypeAhead.options.map((option: object) => {
      const newId = toSafeValue(option[optionFieldId]);
      if (newId !== option[optionFieldId]) {
        return { ...option, [optionFieldId]: newId };
      }
      return option;
    });

    // if any items have the group property, then display the group UI
    for (let i = 0; i < commonTypeAhead.options.length; i++) {
      if (commonTypeAhead.options[i][optionFieldGroup]) {
        commonTypeAhead.groupBy = option => (option as any)[optionFieldGroup];
        break;
      }
    }

    if (isMultiple) {
      const selectValue = field.selectOptions?.filter((option) =>
        (value as Array<string>).includes('' + option[optionFieldId]),
      );
      const handleChange = (
        e: React.ChangeEvent<AnyObject>,
        value: Array<AnyObject> | null,
      ) => {
        setValue(field, value ? value.map((item) => item[optionFieldId]) : []);
      };

      return (
        <TestableAutocomplete
          options={commonTypeAhead.options}
          multiple
          value={selectValue}
          onChange={handleChange}
          {...commonTypeAhead}
          {...selectionAttributes}
          renderInput={(params) => (
            <TextField
              {...getControlRenderProperties(field)}
              {...params}
              id={null}
              error={!!error}
              autoFocus={field.autoFocus}
              style={fullWidthStyle}
              inputProps={getInputProps(field)}
              InputProps={{ ...params.InputProps }}
              label={(field.required && field.label) ? `${field.label} *` : field.label}
              placeholder={field.placeholder}
            />
          )}
          placeholder={field.placeholder}
        />
      );
    } else {
      const id =
        '' +
        (typeof value === 'object'
          ? (value as AnyObject)[optionFieldId]
          : value);
      const selectValue =
        field.selectOptions?.find(
          (option) => id === '' + option[optionFieldId],
        ) ?? '';

      const handleChange = (e: any, value: unknown) => {
        // Pass a value, or pass the ID?
        // The default is to pass an object, but you can specify to use the id.
        if (field.passIdOnSelect === true) {
          setValue(field, value?.[optionFieldId] ?? '');
        } else {
          setValue(field, value as AnyObject);
        }
      };

      // when the text changes, automatically select any matching option
      const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const option = (field.selectOptions ?? [])
          .find(option => (option[optionFieldLabel].toString().toLowerCase() === e.target.value.toLowerCase()));
        if (field.passIdOnSelect === true) {
          setValue(field, option?.[optionFieldId] ?? '');
        } else {
          setValue(field, option as AnyObject);
        }
      };

      return (
        <>
          <TestableAutocomplete
            {...commonTypeAhead}
            {...selectionAttributes}
            {...getControlRenderProperties(field)}
            options={commonTypeAhead.options}
            // this is a bit of a hack to force the control to clear when the options change
            key={JSON.stringify(field.selectOptions)}
            value={selectValue || null}
            onChange={handleChange}
            clearOnBlur={true}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                // not sure why the label isn't showing, but this will force it to be visible
                InputLabelProps={{ shrink: !!field.label && !field.placeholder }}
                InputProps={{ ...params.InputProps }}
                autoFocus={field.autoFocus}
                label={(field.required && field.label) ? `${field.label} *` : field.label}
                placeholder={field.placeholder}
                onChange={handleChangeText}
              />
            )}
            placeholder={field.placeholder}
          />
        </>
      );
    }
  }

  // non-typeahead case
  const menuItems: Array<JSX.Element> = [];
  if (!value && field.placeholder && !field.selectOptions.some(option => option[optionFieldId] === '')) {
    menuItems.push((<MenuItem value={'|-|'} disabled className='select-placeholder' data-placeholder={field.placeholder}>
      <i style={{ color: 'gray' }}>{field.placeholder}</i>
    </MenuItem>));
  }

  const addMenuOptions = (srcArray: SelectSingleDef['selectOptions'], indentationLevel = 0) => {
    srcArray.forEach((item, index) => {
      const key = `${item[optionFieldId] ?? index}.${indentationLevel}`;
      const value = toSafeValue(`${item[optionFieldId] ?? index}`);
      const label = `${item[optionFieldLabel] ?? index}`;
      if (item.children) {
        // simulation of OptGroup
        menuItems.push(<MenuItem key={key} value={value} disabled>{label}</MenuItem>);
        addMenuOptions(item.children, 1);
      } else {
        menuItems.push(<MenuItem style={{ marginLeft: 20 * indentationLevel }} key={key}
          value={value}>{label}</MenuItem>);
      }
    });
  };

  addMenuOptions(field.selectOptions);

  const labelId: string | undefined = field.label ? `${field.key}-select-label` : undefined;

  if (labelId && !isMultiple) {
    return <TestableTextFieldSelect
      {...getControlRenderProperties(field)}
      {...selectionAttributes}
      {...getNameAttribute(field, formProps)}
      title={title}
      style={fullWidthStyle}
      label={field.label}
      inputProps={getInputProps(field)}
      error={!!error}
      value={(!value && field.placeholder) ? '|-|' : value}
      onChange={!isMultiple ? handleChange : handleChangeMultiple}
      autoFocus={field.autoFocus}
      required={!!field.required}
      disabled={field.disabled ?? false}
      select // tell TextField to render select
      placeholder={field.placeholder}
    >{menuItems}</TestableTextFieldSelect>;
  }

  if (Array.isArray(value)) {
    value = value.map(item => toSafeValue(item));
  }
  const select = (
    <TestableSelect
      {...getControlRenderProperties(field)}
      {...selectionAttributes}
      {...getNameAttribute(field, formProps)}
      title={title}
      style={fullWidthStyle}
      labelId={labelId}
      label={field.label}
      inputProps={{ maxLength: 100 }}
      error={!!error}
      value={value || '|-|'}
      onChange={!isMultiple ? handleChangeSelect : handleChangeMultipleSelect}
      autoFocus={field.autoFocus}
      multiple={isMultiple}
      required={!!field.required}
      disabled={field.disabled ?? false}
      placeholder={field.placeholder}
    >{menuItems}</TestableSelect>
  );

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{field.label}</InputLabel>
      {select}
    </FormControl>
  );
};

const renderSelect: RenderElementFunction = (props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as SelectSingleDef | SelectMultipleDef;
  const error = props.showErrors && formErrors && formErrors[field.key];

  return <FormControl style={fullWidthStyle}>
    {renderSelectCore(props, element, getValue, setValue, formErrors)}
    {error && <FormHelperText id={field.key + '-helper'} error={true}>{error}</FormHelperText>}
  </FormControl>;
};

/**
 * Render a field as a toggle control
 * @param field
 * @returns
 */
const renderToggle: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: boolean) => void,
) => {
  const field = element as ToggleDef;
  const value = DDFormUtils.convertValueToBoolean(getValue(field));

  const handleChange = (): void => {
    setValue(field, !value);
  };

  return (
    <Typography component='div'>
      <Grid component='label' container alignItems='center' spacing={1}>
        {(!field.labelDirection || field.labelDirection === 'left') && (
          <Grid item>{field.label}</Grid>
        )}
        <Grid item>
          <Switch
            {...getControlRenderProperties(field)}
            {...getNameAttribute(field, props)}
            checked={value}
            onChange={handleChange}
            color={'primary'}
          />
        </Grid>
        {field.labelDirection === 'right' && <Grid item>{field.label}</Grid>}
      </Grid>
    </Typography>
  );
};

/**
 * Render a field as a toggle control
 * @param field
 * @returns
 */
const renderCheckbox: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: boolean) => void,
) => {
  const field = element as CheckboxDef;
  const untypedValue = getValue(field);
  const value = DDFormUtils.convertValueToBoolean(getValue(field));

  if (field.valueType === undefined || field.valueType === 'auto' || field.valueType === 'boolean') {
    // force this value to be a boolean if it's not one already.
    if (typeof untypedValue !== 'boolean') {
      setValue(field, value);
    }
  }

  const handleChange = (): void => {
    setValue(field, !value);
  };

  return (
    <FormControlLabel
      disabled={field.disabled}
      control={
        <Checkbox
          {...getControlRenderProperties(field)}
          {...getNameAttribute(field, props)}
          checked={value}
          onChange={handleChange}
          color='default'
        />
      }
      label={<span className='checkbox-label'>{field.label}</span>}
    />
  );
};

const renderButton: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
) => {
  const field = element as ButtonDef;

  const buttonContents = (
    <span className='button-contents'>
      {field.icon && <field.icon />} {field.label}
    </span>
  );
  const buttonAttributes = {
    ...getControlRenderProperties(field),
    disabled: field.disabled ?? false,
    style: fullWidthStyle,
    'aria-label': field.ariaLabel ?? (typeof field.tooltip === 'string' ? field.tooltip : null),
  };

  const handleClickByForm = (evt: React.MouseEvent) => {
    if (props.onClickElement) {
      props.onClickElement(evt, element);
    }
  };
  const handleClick = field.onClickButton ?? handleClickByForm;

  const color: (React.ComponentProps<typeof Button>)['color'] = (field.color ??
    (field.buttonType === 'primary'
      ? 'primary'
      : 'inherit'));

  switch (field.buttonType) {
    case 'round':
      return (
        <Fab onClick={field.onClickButton} {...buttonAttributes}>
          {buttonContents}
        </Fab>
      );

    case 'link':
      return (
        <Link href='#' onClick={field.onClickButton} {...buttonAttributes}>
          {buttonContents}
        </Link>
      );

    case 'icon':
      return (
        <IconButton onClick={field.onClickButton} {...buttonAttributes}>
          {buttonContents}
        </IconButton>
      );
    case 'default':
    default: {
      return (
        <Button
          onClick={handleClick}
          {...buttonAttributes}
          variant={field.variant}
          color={color}
        >
          {buttonContents}
        </Button>
      );
    }
  }
};

/**
 * Render a page (row or column) containing other elements, to be used in tabs or stepper.
 * @param props
 * @param field
 * @param getValue
 * @param setValue
 * @returns
 */
const renderPage: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as PageElementDef;

  return (
    <>
      {muiRendererMap[field.contents.type] &&
        muiRendererMap[field.contents.type](
          props,
          field.contents,
          getValue,
          setValue,
          formErrors,
        )}
    </>
  );
};

/**
 * Render a stepper and pages
 * @param props
 * @param field
 * @param getValue
 * @param setValue
 * @returns
 */
const renderStepper: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as StepperDef;

  const activeStep = (getValue(field) as number) ?? 0;

  const setActiveStep = (step: number) => {
    runInAction(() => {
      setValue(field, step);
    });
  };

  const activePage = field.children[activeStep];
  if (!field.useStepper) {
    const tabs = {
      ...field,
      children: field.children.map((page, index) => ({
        ...page,
        error: props.showErrors && !!formErrors?.[`${field.key}[${index}]`],
      })),
    };
    return renderTabs(props, tabs, getValue, setValue, formErrors);
  }
  return (
      <>
      <Stepper
        activeStep={activeStep}
        nonLinear={field.allowDirectSwitching !== false}
        {...getControlRenderProperties(field)}
        {...getNameAttribute(field, props)}
      >
        {field.children.map((child, index) => (
          <Step key={'' + child.label}>
            <StepButton
              className='element-step-button'
              onClick={() => {
                setActiveStep(index);
              }}
            >
              <StepLabel
                error={
                  props.showErrors && !!formErrors?.[`${field.key}[${index}]`]
                }
              >
                {'' + child.label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div className='stepper-page-container'>
        {muiRendererMap[activePage.type](
          props,
          activePage,
          getValue,
          setValue,
          formErrors,
        )}
      </div>
    </>
  );
};

const renderTabs: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string } | null,
) => {
  const field = {
    ...(element as FieldDef),
    valueType: 'number',
  } as TabsDef | StepperDef;

  const tabsValue = getValue(field) ?? 0;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(field, parseInt(newValue, 10));
  };

  return (
    <TabContext value={'' + tabsValue}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleChange}>
          {field.children.map((child, index) => {
            const showError = props.showErrors && !!formErrors?.[`${field.key}[${index}]`];

            return <Tab
              key={index}
              label={child.label}
              value={'' + index}
              sx={theme => showError
                ? {
                    color: theme.palette.error.main,
                    '&.Mui-selected': {
                      color: theme.palette.error.main,
                    },
                  }
                : {}}
              icon={child.error ? <Error /> : null}
              iconPosition='start'
              />;
          })}
        </TabList>
      </Box>
      {field.children.map((child, index) => (
        <TabPanel key={index} value={'' + index} className={field.type === 'stepper' ? 'stepper-page-container' : ''}>
          {renderPage(
            props,
            child,
            getValue,
            setValue,
            formErrors,
          )}
        </TabPanel>
      ))}
    </TabContext>);
};

const renderTypography = (props: DDFormProps, element: ElementDef) => {
  const field = element as TypographyDef;
  return (
    <Typography
      {...getControlRenderProperties(field)}
      className={field.className}
      variant={field.variant as React.ComponentProps<typeof Typography>['variant']}
    >
      {field.label}
    </Typography>
  );
};

const renderTree: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const treeDef = element as TreeDef;

  const TreeItemWithChildren = (itemProps: { item: TreeItemDef; }) => {
    const { item } = itemProps;

    const contents = item.itemField
      ? renderElement(props, item.itemField, getValue, setValue, formErrors)
      : item.label;

    return (
      <TreeItem key={item.key} nodeId={item.key} label={contents}>
        {item.children?.map((child) => {
          const childContents = child.itemField
            ? renderElement(
              props,
              child.itemField,
              getValue,
              setValue,
              formErrors,
            )
            : child.label;

          return (
            <TreeItem
              className={`element-tree-item ${child.className}`}
              key={child.key}
              nodeId={child.key}
              label={childContents}
            />
          );
        })}
      </TreeItem>
    );
  };

  return (
    <>
      <TreeView
        key={element.key}
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
      >
        {treeDef.children?.map((item) => (
          <TreeItemWithChildren key={item.key} item={item} />
        ))}
      </TreeView>
    </>
  );
};

const renderCustom: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as CustomFieldDef;
  return (
    (field.render &&
      field.render(field, props, field, getValue, setValue, formErrors)) || (
      <></>
    )
  );
};

const renderRadioGroup: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as RadioGroupDef;
  const optionFieldId = field.optionFieldId ?? 'id'; // also value
  const optionFieldLabel = field.optionFieldLabel ?? 'label';
  const currentValue = getValue(field);
  const error = props.showErrors && formErrors && formErrors[field.key];

  const handleChange = (event: React.SyntheticEvent, newValue: FieldValueType) => {
    const option = field.selectOptions.find(option => option[optionFieldId] === newValue);
    if (!option?.disabled) {
      setValue(field, newValue);
    }
  };

  return <FormControl className={field.className}
    required={!!field.required}
    disabled={field.disabled === true}
    error={!!error}

  >
    {field.label && !field.horizontal && <FormLabel id={`${field.key}-label`}>{field.label}</FormLabel>}
    <RadioGroup
      aria-labelledby={`${field.key}-label`}
      value={currentValue}
      onChange={handleChange}
      {...getNameAttribute(field, props)}
      row={field.horizontal === true}
    >
      {field.label && field.horizontal &&
        <FormLabel sx={{ marginRight: '1rem', marginTop: '.6rem' }} id={`${field.key}-label`}>{field.label}</FormLabel>}
      {field.selectOptions.map(option =>
        <FormControlLabel key={'' + option[optionFieldId]}
          value={option[optionFieldId]}
          onClick={() => {
            if (!option.disabled && !field.disabled) {
              setValue(field, option[optionFieldId] as any);
            }
          }}
          control={
            <Radio disabled={option.disabled ?? field.disabled ?? false} checked={('' + currentValue) === ('' + option[optionFieldId])} />
          }
          label={option[optionFieldLabel]} />,
      )
      }
    </RadioGroup>
    {error && <FormHelperText error={true}>{error}</FormHelperText>}
  </FormControl>;
};

export const muiRendererMap: FormElementRendererMap = {
  custom: renderCustom,
  text: renderText,
  row: renderSimpleGroup,
  column: renderSimpleGroup,
  number: renderNumericInput,
  date: renderDate,
  select: renderSelect,
  multiselect: renderSelect,
  toggle: renderToggle,
  button: renderButton,
  page: renderPage,
  stepper: renderStepper,
  tabs: renderTabs,
  typography: renderTypography,
  checkbox: renderCheckbox,
  tree: renderTree,
  radioGroup: renderRadioGroup,
};

export const muiRenderElement = renderElement;
