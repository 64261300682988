import React from 'react'

import Icon from '@/shared/components/Icon.jsx'
import { NameField, DataTypeSelect, DropdownDisplayCheckbox, Actions }
  from '@/shared/components/FieldDefinitionsTable'

import PickListEditor from './PickListEditor'
import RequiredGroupSelect from './RequiredGroupSelect'

export default function BaseRow(props) {
  const {
    dragHandle,
    elementId,
    runFieldDefinition,
    handleChange,
    readOnly,
    remove,
  } = props
  const {
    id,
    name,
    data_type_name,
    is_display_identifier,
    required_group_number,
    temp_group_number,
    run_fields_count,
    disabled,
    dropdown_display_disabled,
  } = runFieldDefinition.toJS()
  const dataTypeDisabled = run_fields_count > 0
  const dataTypeDisabledText = (disabled && !dataTypeDisabled)
    ? { disabledText: 'Cannot change data type for inventory fields' }
    : {}

  const commonProps = {
    onChange: handleChange,
    readOnly: readOnly,
    disabled: disabled,
  }

  const availableDataTypeNames = ['Text', 'LongText', 'PickList', 'File', 'Number', 'BatchLink']

  return (
    <div className="row" id={elementId}>
      <div className="inner name">
        <NameField
          value={name}
          {...commonProps}
        />
      </div>
      <div className="inner data-type">
        <DataTypeSelect
          options={availableDataTypeNames}
          value={data_type_name}
          {...commonProps}
          disabled={disabled || dataTypeDisabled}
          {...dataTypeDisabledText}
        />
        <PickListEditor
          id={id}
          dataTypeName={data_type_name}
          {...commonProps}
        />
      </div>
      <div className="inner centered dropdown-display">
        <DropdownDisplayCheckbox
          {...commonProps}
          checked={is_display_identifier}
          disabled={dropdown_display_disabled}
          disabledText="This field must be displayed"
          name="dropdown-display"
        />
      </div>
      <div className="inner requirement">
        <RequiredGroupSelect
          id={id}
          requiredGroupNumber={required_group_number}
          tempGroupNumber={temp_group_number}
          {...commonProps}
        />
      </div>
      <div className="inner actions">
        <Actions
          onClick={() => remove(id)}
          disabledTitle="Cannot remove inventory fields. Contact support."
          {...commonProps}
        />
        {dragHandle(
          <span>
            <Icon icon="drag_handle" />
          </span>
        )}
      </div>
    </div>
  )
}
