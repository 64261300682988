import _ from 'lodash'
import React from 'react'
import { fromJS } from 'immutable'
import Select from 'react-select'
import RailsHiddenFields from 'shared/components/RailsHiddenFields.jsx'

export default class VaultSelect extends React.Component {
  constructor(props) {
    super(props)
    const vaultId = this.props.vault_id
    this.vaultList = this.initializeVaultList(this.props.vaults)
    this.accountList = this.initializeAccountList(this.props.vaults)
    const vault = this.selectedVaultForId(vaultId)
    let account = null
    if (vault) {
      account = this.initializeAccountList([vault])[0]
    }
    this.state = { vaultId: vaultId, vault: vault, account: account }
  }

  selectedVaultForId(id) {
    if (!id) {
      return null
    }
    const idInteger = 1 * id
    const selectedVault = this.vaultList.find(entry => entry.id == idInteger)
    return selectedVault
  }

  initializeVaultList = (rawList) => {
    return rawList.map(entry => ({ ...entry, label: entry['name'], value: entry['id'] }))
  }

  initializeAccountList = (rawList) => {
    const list = rawList.map(entry => ({ label: entry.settings.account.name, value: entry.settings.account.name }))
    return _.uniqBy(list, 'label')
  }

  handleSelect = (vault) => {
    let change = { vaultId: vault.id, vault: vault }
    if (vault && !this.state.account) {
      change['account'] = this.initializeAccountList([vault])[0]
    }
    this.setState(change)
  }

  changeAccount = (account) => {
    let change = { vaultId: null, vault: null, account: account }
    this.setState(change)
  }

  render() {
    let value = null
    if (this.state.vault) {
      value = this.state.vault
    }
    var filteredVaultList = this.vaultList
    if (this.state.account) {
      filteredVaultList = filteredVaultList.filter(vault => vault.settings.account.name == this.state.account.value)
    }
    return (
      <React.Fragment>
        <Select
          classNamePrefix='account-select'
          value={this.state.account}
          isClearable={true}
          options={this.accountList}
          onChange={this.changeAccount}
          placeholder={'Select Account'}
          style='width: 75%;'
        />
        <Select
          classNamePrefix='vault-select'
          value={value}
          isClearable={false}
          options={filteredVaultList}
          onChange={this.handleSelect}
          placeholder={'Select Vault'}
          style='width: 75%;'
        />
        <RailsHiddenFields
          name={this.props.fieldName}
          value={this.state.vaultId}
        />
      </React.Fragment>
    )
  }
}

VaultSelect.defaultProps = {
  fieldName: 'user[vault_id]'
}
