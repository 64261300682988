import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import EditDataTable from './component.jsx'
import editable from '@/FieldDefinitions/ProtocolFieldDefinitions/modules/editable.js'
import {
  add,
  update,
  remove,
  splice,
  save,
} from '@/FieldDefinitions/ProtocolFieldDefinitions/actions/protocolFieldDefinitions.js'

function mapStateToProps(state) {
  return {
    protocolFieldDefinitions: state.getIn(['current', 'protocolFieldDefinitions']),
    usingRegistrationSystem: state.get('usingRegistrationSystem'),
    saving: state.get('saving'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cancelEdit: editable.actions.cancelEdit,
    add,
    update,
    remove,
    splice,
    save,
  }, dispatch)
}

const EditDataTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDataTable)

export default EditDataTableContainer
