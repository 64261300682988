import React from 'react'

export default function DropdownDisplayCheckbox(props) {
  const {
    onChange,
    checked,
    disabled,
    disabledText,
    readOnly,
    uneditable,
  } = props

  var title = null
  if (disabledText && disabled) {
    title = disabledText
  }

  if (uneditable) {
    return null
  } else {
    return (
      <input
        type="checkbox"
        name="is_display_identifier"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
        title={title}
      />
    )
  }
}
