import React, { Component } from 'react'
import { FormControl, FormLabel, Switch } from '@mui/material'
import { observer, inject } from 'mobx-react'
import { pick } from 'lodash'

import MuiTheme from 'shared/components/MuiTheme.jsx'
import PeriodSelect from './PeriodSelect.jsx'
import NotificationAdminList from './NotificationAdminList.jsx'
import SubmitOrCancel from 'shared/components/SubmitOrCancel.jsx'
import WitnesserList from './WitnesserList.jsx'
import { Terminology } from 'javascripts/cross_app_utilities.js'

@inject(stores => (
  pick(stores.store, [
    'elnWitnessingEnabled',
    'dirty',
    'loading',
    'submit',
    'onChange',
  ])
))
@observer
class ElnWitnessing extends Component {
  // Wait for Terminology to be populated
  static get periodInfo() {
    return `Once a witness has been assigned, if the ${Terminology.t('entry')} has not been finalized after the specified period, a notification email will be sent to the following members.`
  }

  onEnabledChange = event => {
    this.props.onChange({ elnWitnessingEnabled: event.target.checked })
  }

  renderSubmit = () => {
    const { dirty, loading, submit } = this.props

    return (
      <SubmitOrCancel
        green
        noCancelOption
        disabled={!dirty}
        loading={loading}
        onSubmit={submit}
      >
        Save changes
      </SubmitOrCancel>
    )
  }

  render() {
    const { elnWitnessingEnabled: enabled } = this.props

    return (
      <form>
        <div className="settings-page__header">
          <h2 className='settings-page__heading'>ELN Witnessing</h2>
          <Switch
            className='eln-witnessing__enabled'
            checked={enabled}
            onChange={this.onEnabledChange}
            color="primary"
          />
        </div>

        <div className="subsubcontainer subcontainer--text-only">
          {ElnWitnessing.periodInfo}
        </div>

        <div className="settings-page__fields">
          <FormControl disabled={!enabled}>
            <FormLabel>Period:</FormLabel>
            <PeriodSelect />
          </FormControl>

          <FormControl disabled={!enabled}>
            <FormLabel>Members to notify:</FormLabel>
            <NotificationAdminList />
          </FormControl>

          <FormControl disabled={!enabled}>
            <FormLabel>Members who can witness:</FormLabel>
            <WitnesserList />
          </FormControl>
        </div>

        {this.renderSubmit()}
      </form>
    )
  }
}

export default ElnWitnessing
