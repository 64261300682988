import {
  getFieldDefinitionById,
  getRequiredGroupNames as genericGetRequiredGroupNames,
  getGroupsByNumber as genericGetGroupsByNumber,
  getRemovedFieldDefinitionsWithData,
} from '@/FieldDefinitions/shared/selectors.js'

export {
  getPickListValues,
  isEditingPickList,
  getInvalidIds,
  getNotification,
  getInput,
} from '@/FieldDefinitions/shared/selectors.js'

const FIELD_DEFINITION_KEY = 'moleculeFieldDefinitions'
const FIELD_COUNT_KEY = 'molecule_fields_count'

export function getMoleculeFieldDefinition(state, id) {
  return getFieldDefinitionById(FIELD_DEFINITION_KEY, state, id)
}

export function getRequiredGroupNames(state, id, requiredGroupNumber) {
  return genericGetRequiredGroupNames(FIELD_DEFINITION_KEY, state, id, requiredGroupNumber)
}

export function getGroupsByNumber(state) {
  return genericGetGroupsByNumber(FIELD_DEFINITION_KEY, state)
}

export function getRemovedMoleculeFieldDefinitionsWithData(state) {
  return getRemovedFieldDefinitionsWithData(FIELD_DEFINITION_KEY, FIELD_COUNT_KEY, state)
}
