import persistable from '../modules/persistable.js'
import railsify from '../models/runFieldDefinitions.js'
import submit from '@/shared/utils/submit.js'

export const ADD = 'runFieldDefinitions/ADD'
export const UPDATE = 'runFieldDefinitions/UPDATE'
export const REMOVE = 'runFieldDefinitions/REMOVE'
export const SPLICE = 'runFieldDefinitions/SPLICE'

export const UPDATE_REQUIRED_GROUP_NUMBER = 'runFieldDefinitions/UPDATE/requiredGroupNumber'

export function add() { return { type: ADD } }

export function update(id: number, updatedValues: Object) {
  return { type: UPDATE, id, updatedValues }
}

export function updateRequiredGroupNumber(id: number, requiredGroupNumber: number) {
  return {
    type: UPDATE,
    subtype: UPDATE_REQUIRED_GROUP_NUMBER,
    id,
    requiredGroupNumber,
  }
}

export function remove(id: number) {
  return { type: REMOVE, id }
}

export function splice(id: number, index: number) {
  return { type: SPLICE, id, index }
}

export function save() {
  return function (dispatch: Function, getState: Function) {
    dispatch(persistable.actions.saving())

    const state = getState()
    const data = $.param(railsify(state))
    const vaultRunFieldDefinitionsUrl = state.get('vaultRunFieldDefinitionsUrl')

    return submit({
      url: vaultRunFieldDefinitionsUrl,
      data,
    }).then(
      (response) => dispatch(persistable.actions.savingSucceed(response)),
      ({ errorMessages, status }) => dispatch(persistable.actions.savingFail(errorMessages, status)))
  }
}
