export function getUniqueId(state) {
  let id

  do {
    id = `FAKE-ID-${Math.random().toString(16).slice(2).toUpperCase()}`
  } while (state.getIn(['byId', id]))

  return id
}

export function normalizedValue(str) {
  return str.trim().toLowerCase()
}
