const actionsGenerator = namespace => {
  const actionTypes = {
    UPDATE_INPUT: `${namespace}/pickListValues/UPDATE_INPUT`,
    ADD: `${namespace}/pickListValues/ADD`,
    ADD_MANY: `${namespace}/pickListValues/ADD_MANY`,
    UPDATE: `${namespace}/pickListValues/UPDATE`,
    REMOVE: `${namespace}/pickListValues/REMOVE`,
  }

  const actions = {
    updateInput: (input: String) => {
      return {
        type: actionTypes.UPDATE_INPUT,
        input,
      }
    },
    add: (value: String) => {
      return {
        type: actionTypes.ADD,
        value,
      }
    },
    addMany: (values: Array) => {
      return {
        type: actionTypes.ADD_MANY,
        values,
      }
    },
    update: (id: number, updatedValues: Object) => {
      return {
        type: actionTypes.UPDATE,
        id,
        updatedValues,
      }
    },
    remove: (id: number) => {
      return { type: actionTypes.REMOVE, id }
    },
  }

  return { actionTypes, actions }
}

export default actionsGenerator
