import React from 'react'

import Icon from '@/shared/components/Icon.jsx'
import { NameField, DataTypeSelect, UniqueCheckbox, RequiredCheckbox, Actions }
  from '@/shared/components/FieldDefinitionsTable'

import PickListEditor from './PickListEditor'

export default function BaseRow(props) {
  const {
    dragHandle,
    elementId,
    elnFieldDefinition,
    handleChange,
    readOnly,
    removeElnFieldDefinition,
  } = props
  const {
    id,
    name,
    data_type_name,
    unique,
    required,
    uneditable,
    eln_fields_count,
  } = elnFieldDefinition.toJS()
  const dataTypeDisabled = eln_fields_count > 0

  const commonProps = {
    onChange: handleChange,
    readOnly: readOnly,
    uneditable: uneditable,
  }

  const className = uneditable ? 'row uneditable' : 'row'

  return (
    <div className={className} id={elementId}>
      <div className="inner name">
        <NameField
          value={name}
          {...commonProps}
        />
      </div>
      <div className="inner type">
        <DataTypeSelect
          options={['Text', 'Number', 'Date', 'PickList', 'File']}
          value={data_type_name}
          {...commonProps}
          disabled={dataTypeDisabled}
        />
        <PickListEditor
          id={id}
          dataTypeName={data_type_name}
          {...commonProps}
        />
      </div>
      <div className="inner centered unique">
        <UniqueCheckbox
          checked={unique}
          dataTypeName={data_type_name}
          {...commonProps}
        />
      </div>
      <div className="inner centered required">
        <RequiredCheckbox
          checked={required}
          {...commonProps}
        />
      </div>
      <div className="inner actions actions">
        <Actions
          onClick={() => removeElnFieldDefinition(id)}
          uneditable={uneditable}
        />
        {dragHandle(
          <span>
            <Icon icon="drag_handle" />
          </span>
        )}
      </div>
    </div>
  )
}
