import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import DataTable from './components/DataTable/component.jsx'
import EditDataTable from './components/EditDataTable'
import EditLink from '@/shared/components/EditLink.jsx'
import Errors from '@/shared/components/Errors.jsx'
import UniquenessNote from './components/UniquenessNote.jsx'

export default class BatchFieldDefinitions extends React.Component {
  render() {
    const {
      editing,
      errorMessages,
      batchFieldDefinitions,
      edit,
      removedBatchFieldDefinitionsWithData,
      usingRegistrationSystem,
      batchFieldDefinitionsLocked,
    } = this.props
    const batchStr = Terminology.dictionary.batch.titleize()

    if (editing) {
      return (
        <div className="subcontainer" {...this.props.attributes}>
          <h2>{batchStr} Fields</h2>
          <Errors errorMessages={errorMessages} />
          <EditDataTable
            batchFieldDefinitions={batchFieldDefinitions}
            removedBatchFieldDefinitionsWithData={removedBatchFieldDefinitionsWithData}
          />
          <UniquenessNote />
        </div>
      )
    } else {
      return (
        <div className="subcontainer" {...this.props.attributes}>
          <EditLink onClick={edit} disabled={batchFieldDefinitionsLocked}>
            Add/Edit {batchStr} Fields
          </EditLink>
          <h2>{batchStr} Fields</h2>
          <DataTable
            batchFieldDefinitions={batchFieldDefinitions}
            usingRegistrationSystem={usingRegistrationSystem}
          />
          <UniquenessNote />
        </div>
      )
    }
  }
}
