import { List } from 'immutable'
import editable from '../modules/editable.js'

const reinitAfterCancel = reducer => (state, action) => {
  const reducedState = reducer(state, action)

  switch (action.type) {
    case editable.actionTypes.CANCEL:
      return reducedState.set('errorMessages', List())
    default:
      return reducedState
  }
}

export default reinitAfterCancel
