import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { FormControl, TextField } from '@mui/material';
import { FilterValueSelectProps } from './FilterValueSelect.types';

// TODO dedupe with eln entries stuff where possible
export class FilterTextValue extends React.Component<
  FilterValueSelectProps<FieldDataType.Text>
> {
  inputProps = {
    type: 'text',
  };

  get value() {
    const {
      filter: { field_values },
    } = this.props;
    return (field_values && field_values[0]) || '';
  }

  handleChange = (event) => {
    this.props.handleValueChange([event.target.value]);
  };

  render() {
    return (
      <FormControl>
        <TextField
          value={this.value}
          onChange={this.handleChange}
          variant='outlined'
          className='filter__value__text'
          {...this.inputProps}
        />
      </FormControl>
    );
  }
}
