import { List } from 'immutable'

import { getElnFieldDefinition } from '../selectors.js'

export default function railsify(state) {
  const eln_field_definitions_attributes = state.getIn(['current', 'elnFieldDefinitions', 'all'])
    .reduce(function (memo, id, index) {
      const definition = getElnFieldDefinition(state, id).toJS()

      if (definition.id !== 'FAKE-ID-PROJECT-PLACEHOLDER') {
        const pick_list_values = state.getIn(['current', 'pickListValues', 'current', 'byDefinitionId', definition.id.toString()], List())
          .reduce((memo, _id) => {
            const pickListValue = state.getIn(['current', 'pickListValues', 'current', 'byId', _id.toString()]).toJS()

            if (pickListValue.id.toString().startsWith('FAKE-ID')) {
              delete pickListValue.id
            }

            memo.push(pickListValue)
            return memo
          }, [])

        memo[index] = Object.assign(definition, {
          pick_list_values,
          display_order: index,
        })
      }

      return memo
    }, {})

  return { vault: { eln_field_definitions_attributes } }
}
