import { combineReducers } from 'redux-immutable'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import protocolFieldDefinitions, { protocolFieldDefinitionsInitialState }
  from './protocolFieldDefinitions.js'
import pickListValues from '@/FieldDefinitions/ProtocolFieldDefinitions/modules/pickListValues.js'
import editable from '@/FieldDefinitions/ProtocolFieldDefinitions/modules/editable.js'
import persistable from '@/FieldDefinitions/ProtocolFieldDefinitions/modules/persistable.js'
import reactions from '../middleware/reactions.js'
import reinitAfterSaveOrCancel from './reinitAfterSaveOrCancel.js'
import composeWithDevTools from '@/shared/utils/composeWithDevTools.js'

const reducer = reinitAfterSaveOrCancel(
  persistable.reducer(
    editable.reducer(
      combineReducers({
        protocolFieldDefinitions,
        pickListValues: pickListValues.reducer,
      })
    )
  )
)

export function initialState(protocolFieldDefinitions) {
  const parsedProtocolFieldDefinitions = protocolFieldDefinitions.map(obj => obj.protocol_field_definition)

  return persistable.initialState().toMap()
    .merge(
      editable.initialState({
        current: {
          protocolFieldDefinitions: protocolFieldDefinitionsInitialState(protocolFieldDefinitions),
          pickListValues: pickListValues.initialState(parsedProtocolFieldDefinitions),
        },
      })
    )
}

export default function configureStore({
  protocolFieldDefinitions,
  vaultProtocolFieldDefinitionsUrl,
  usingRegistrationSystem,
  protocolFieldDefinitionsLocked,
} = {}) {
  const _initialState = initialState(protocolFieldDefinitions)
    .merge({
      vaultProtocolFieldDefinitionsUrl,
      usingRegistrationSystem,
      protocolFieldDefinitionsLocked,
    })

  return createStore(
    reducer,
    _initialState,
    composeWithDevTools(
      applyMiddleware(
        reactions,
        thunk
      )
    )
  )
}
