import React from 'react'

import { SortableItem, Warning } from '@/shared/components/FieldDefinitionsTable'
import BaseRow from './BaseRow.jsx'
export RowPreview from './RowPreview.jsx'

class Row extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this._handleChange.bind(this)
  }

  _handleChange(event) {
    let value = event.target.value

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }

    this.props.update(
      this.props.moleculeFieldDefinition.get('id'),
      { [event.target.name]: value }
    )
  }

  render() {
    const { moleculeFieldDefinition } = this.props
    const { id, _destroy } = moleculeFieldDefinition.toJS()
    const elementId = `molecule-field-definition-${id}`

    if (_destroy === 1) {
      return (
        <div className="row" id={elementId}>
          <Warning definition={moleculeFieldDefinition} objectName='molecule' attribute='molecule_fields_count' />
        </div>
      )
    }

    return (
      <BaseRow
        handleChange={this.handleChange}
        elementId={elementId}
        {...this.props}
      />
    )
  }
}

export default SortableItem('moleculeFieldDefinition', Row)
