import { UPDATE } from '../actions.js'
import pickListValues from '../modules/pickListValues.js'
import persistable from '../modules/persistable.js'
import editable from '../modules/editable.js'
import reactions from '@/shared/utils/reactions.js'

const getReaction = (action) => {
  switch (action.type) {
    case UPDATE: {
      const { updatedValues } = action

      if (updatedValues && updatedValues.data_type_name === 'PickList') {
        return pickListValues.actions.edit(action.id)
      } else {
        return null
      }
    }
    case persistable.actionTypes.SUCCEED:
      return editable.actions.submit()
    default:
      return null
  }
}

export default reactions(getReaction)
