import React from 'react';

import { GlobalMessage } from '@/components/GlobalMessage/GlobalMessage';
import { term } from '@/shared/utils/stringUtils';
import { getRootStore } from '@/stores/rootStore';
import { CDD } from '@/typedJS';
import { Link } from '@mui/material';
import { observer } from 'mobx-react';
import { ErrorBoundary } from 'react-error-boundary';
import './InventorySearchView.sass';
import { SearchBar } from './components/SearchBar/SearchBar';
import { InventorySearchTable } from './components/Table/InventorySearchTable';
import { FieldTypeValues } from './components/consts';
import EditInventoryEventDialog from './components/inventory_dialogs/EditInventoryEventDialog';
import EditInventorySampleDialog from './components/inventory_dialogs/EditInventorySampleDialog';
import { InventorySearchViewAPIProps } from './components/types';

@observer
class InventorySearchViewInternal extends React.Component<InventorySearchViewAPIProps> {
  constructor(props: InventorySearchViewAPIProps) {
    super(props);
    const inventory_field_definitions_entries = FieldTypeValues.map(
      (fieldType) => [
        fieldType,
        [
          ...props.custom_field_definitions[fieldType],
          ...props.default_field_definitions[fieldType],
        ],
      ],
    );
    this.store.setupInventorySearchPage({
      initial_entries: props.initial_entries,
      inventory_field_definitions: Object.fromEntries(
        inventory_field_definitions_entries,
      ),
      links: props.links,
      saved_filters: props.saved_filters,
      result_columns_available: props.result_columns_available,
      total_count: props.total_count,
    });

    // To update shown entries whenever available vaults get changed
    CDD.DataSourceSelection.updateCallback = this.store.submitNewQuery;
  }

  get store() {
    return getRootStore().inventoryStore;
  }

  get links() {
    const { links } = this.props;
    return {
      entriesExportProgressUrl: links.inventory_entries_export_progress_url,
      entriesExportUrl: links.inventory_entries_export_url,
    };
  }

  get searchBarHandlers() {
    const {
      removeQueryFilter,
      updateQueryFilter,
      updateQueryMRV,
      addQueryFilter,
      updateQueryText,
      resetQueryFilters,
      submitNewQuery,
    } = this.store;
    return {
      updateQueryMRV,
      removeQueryFilter,
      updateQueryFilter,
      addQueryFilter,
      updateQueryText,
      submitQuery: submitNewQuery,
      resetQueryFilters,
    };
  }

  get sortProps() {
    const { sortedColumn, setSortBy } = this.store;
    return { sortBy: sortedColumn, setSortBy };
  }

  render(): React.ReactNode {
    const {
      loading,
      inventoryEntries,
      searchQuery,
      inventoryFieldDefinitions,
      visibleColumns,
      availableColumns,
      currentlyEditingInventoryAsSample,
      currentlyEditingInventoryAsEvent,
      currentlyEditingInventoryBatches,
      totalCount,
      hasErrors,
    } = this.store;

    const {
      vault_name_map: vaultNameMap,
      user_structure_masked,
      all_units: allUnits,
      custom_field_definitions: { Event: eventFDs, Sample: sampleFDs },
    } = this.props;
    return (
      <>
        <GlobalMessage messages={this.store.errors} />
        <div id='inventorySearchView'>
          <SearchBar
            sharedProps={{
              searchQuery,
              terminology: {
                entryTerminologySingle: term('sample'),
                entryTerminologyPlural: term('sample.other'),
              },
            }}
            handlers={this.searchBarHandlers}
            exportLinksProps={{ onClickExport: this.store.submitExport }}
            searchFilters={{
              inventoryFieldDefinitions,
              vaultNameMap,
              user_structure_masked,
            }}
            entriesCount={{
              showingCount: inventoryEntries.length,
              totalCount,
            }}
          />
          <InventorySearchTable
            {...this.sortProps}
            hasMore={inventoryEntries.length < totalCount && !hasErrors}
            getNextPage={this.store.getNextPage}
            onChangeVisibleColumns={this.store.setVisibleColumnDetails}
            onClickEntry={this.store.handleEditInventory}
            onToggleSampleDepleted={this.store.handleToggleDeplete}
            availableColumns={availableColumns}
            visibleColumns={visibleColumns}
            entryRows={inventoryEntries}
            loading={loading}
            highlights={this.store.searchHighlights}
          />
          <EditInventorySampleDialog
            isOpen={
              currentlyEditingInventoryAsSample !== null &&
              currentlyEditingInventoryAsEvent === null
            }
            value={currentlyEditingInventoryAsSample}
            batches={currentlyEditingInventoryBatches}
            fieldDefinitions={sampleFDs}
            eventFieldDefinitions={eventFDs}
            units={allUnits}
            searchDialog
            onSubmit={this.store.handleSubmitEditSample}
            onClose={this.store.handleCancelEditInventory}
            onDelete={this.store.handleDeleteSample}
          />
          <EditInventoryEventDialog
            isOpen={currentlyEditingInventoryAsEvent !== null}
            value={currentlyEditingInventoryAsEvent}
            fieldDefinitions={eventFDs}
            unit={currentlyEditingInventoryAsSample?.units ?? null}
            sample={currentlyEditingInventoryAsSample}
            onSubmit={this.store.handleSubmitEditEvent}
            onClose={this.store.handleCancelEditInventory}
            onDelete={this.store.handleDeleteEvent}
          />
        </div>
      </>
    );
  }
}
export class InventorySearchView extends React.Component<InventorySearchViewAPIProps> {
  get store() {
    return getRootStore().inventoryStore;
  }

  // since some errors can also go wrong by the time we get the store values, we have this fallback in a separate `render()` call
  renderErrorFallback() {
    const resetInventoryPreferences = async () => {
      await this.store.resetInventoryPreferences();
      window.location.reload();
    };
    return (
      <div>
        Something Went Wrong.{' '}
        <Link onClick={resetInventoryPreferences}>
          Resetting your search preferences might help.
        </Link>
      </div>
    );
  }

  render() {
    return (
      <ErrorBoundary fallback={this.renderErrorFallback()}>
        <InventorySearchViewInternal {...this.props} />
      </ErrorBoundary>
    );
  }
}
