import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import Row from './Row'
import RowComponent from './Row/component.jsx'

export default function DataTable(props) {
  const { runFieldDefinitions } = props
  const all = runFieldDefinitions.get('all')
  const runStr = Terminology.dictionary.run.titleize()
  let autoGeneratedRows

  autoGeneratedRows = [
    <RowComponent
      id="run-date"
      key="run-date"
      name={`${runStr} Date`}
      requiredText={`is required`}
      is_display_identifier={true}
      dowpdown_display_disabled={true}
    />,
  ]

  return (
    <table id="runFieldDefinitions-show" className="dataTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Data Type</th>
          <th className="centered">Dropdown Display</th>
          <th colSpan="2">This Field</th>
        </tr>
      </thead>
      <tbody>
        {autoGeneratedRows}
        {all.map(id => <Row key={id} id={id} />)}
      </tbody>
      <tfoot>
      </tfoot>
    </table>
  )
}
