import React from 'react';
import { CardActions, Dialog, IconButton } from '@mui/material';
import StructureLookup from './StructureLookup';
import { SelectedMolecule } from '@/shared/models/MoleculeSuggestions.js';
import SubmitOrCancel from '@/shared/components/SubmitOrCancel.jsx';
import { StructureEditor, StructureFormat } from './StructureEditorTypes';
import { openModalComponent } from '@/components';
import { getMoleculeMrv } from '@/shared/utils/moleculeSearch';

import { term } from '@/shared/utils/stringUtils';
import askForConfirmation from '@/shared/components/ConfirmationDialog';
import { CDD } from '@/typedJS';
import { KetcherEditor } from '@/components/StructureEditor/KetcherEditor';
import { getRootStore } from '@/stores/rootStore';
import { A } from '@/shared/components/sanitizedTags';
import { ModalUtils } from '@/shared/utils/modalUtils';

type Props = {
  id?: string;
  title: string;
  structure?: string;
  moleculesOnly?: boolean; // a hint that only single molecules apply, so can skip reaction features, text boxes, etc.
  desiredFormat: StructureFormat;
  isSearch?: boolean;
  onCancel: () => void;
  onSubmit: (structure: string) => void;
  onConfirm: (structure: string) => void;
};

type State = {
  open: boolean;
  expanded: boolean;
  dialogPaperStyle: { style: React.CSSProperties }
};

export class StructureEditorDialog extends React.Component<Props, State> {
  static defaultProps = {
    title: '',
    desiredFormat: StructureFormat.MRV,
  };

  disposers:Array<() => void> = [];

  editor: React.RefObject<React.Component & StructureEditor>;

  constructor(props: Props) {
    super(props);

    const expanded = getRootStore().localPersistenceStore.data.structureExpanded;
    this.state = {
      open: true,
      expanded,
      dialogPaperStyle: this.dialogPaperStyle,
    };
    this.editor = React.createRef();
  }

  get usingKetcher() {
    return CDD.features.structureEditor === 'ketcher';
  }

  componentDidMount() {
    if (this.state.expanded && this.usingKetcher) {
      this.updateEditorSize();
    }
    window.addEventListener('resize', this.updateEditorSize);
    this.disposers.push(() => {
      window.removeEventListener('resize', this.updateEditorSize);
    });
  }

  componentWillUnmount() {
    this.disposers.forEach(disposer => disposer());
  }

  updateEditorSize = () => {
    if (this.usingKetcher) {
      const updateKetcherSize = () => {
        // the Ketcher iframe has a 'root' element that gets a height assigned as an inline style, so resizing the iframe
        // without adjusting that element's size causes the bottom toolbar to be misaligned. Fix that.
        const ketcherEditorIframe = document.getElementById('ketcher-editor') as HTMLIFrameElement;
        if (ketcherEditorIframe && ketcherEditorIframe.contentWindow.innerHeight > 500) {
          const root = ketcherEditorIframe.contentWindow.document.getElementById('root');
          if (root) {
            root.style.height = (ketcherEditorIframe.contentWindow.innerHeight - 20) + 'px';
            // style set, so return
            return;
          }
        }
        // things aren't ready, so try again
        requestAnimationFrame(updateKetcherSize);
      };
      requestAnimationFrame(updateKetcherSize);
    }
    this.setState({ dialogPaperStyle: this.dialogPaperStyle });
  };

  handleClickToggleExpand = () => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
    getRootStore().localPersistenceStore.setData({ structureExpanded: expanded });
    this.updateEditorSize();
  };

  handleClickCancel = () => {
    this.setState({ open: false });
    this.props.onCancel();
  };

  handleClickSubmit = () => {
    const editor = this.editor.current;
    (async () => {
      let format = this.props.desiredFormat;
      if (this.props.isSearch && this.usingKetcher) {
        format = StructureFormat.MOLV3000;
      }
      const structure = await editor.getStructure(format);
      this.props.onSubmit(structure);
      return structure;
    })();
    this.setState({ open: false });
  };

  handleMoleculeSelected = (selection: SelectedMolecule) => {
    if (selection.molecule) {
      const editor = this.editor.current;
      if (selection.molecule.mrv) {
        editor.addStructure(selection.molecule.mrv);
      } else {
        const { displayName } = selection.molecule;
        getMoleculeMrv(selection.molecule.id).then(mrv => {
          if (mrv === null) {
            askForConfirmation({
              title: 'Error',
              message: `${term('molecule', true)} '${displayName}' is structureless`,
              noCancelOption: true,
            });
          } else if (mrv.includes('x2=')) {
            editor.addStructure(mrv);
          } else {
            CDD.StructureEditor.convertStructure(mrv, 'mrv:-a', mrv2D => {
              editor.addStructure(mrv2D);
            });
          }
        });
      }
    }
  };

  handleClickHelp = () => {
    ModalUtils.showModal(<>
      Email <A href='mailto:support@collaborativedrug.com'>support@collaborativedrug.com</A> with your use case and feedback.
      We&apos;re adding new features to the editor and want your input.
    </>, {
      noCancelOption: true,
      title: '',
    });
  };

  renderInsertBar = () => {
    return <div className='insertBar'>
      <StructureLookup onChange={this.handleMoleculeSelected} />
      <IconButton className='btn-expand-toggle' onClick={this.handleClickToggleExpand}>
        {!this.state.expanded && <i className="fa fa-expand" aria-hidden="true"></i>}
        {this.state.expanded && <i className="fa fa-compress" aria-hidden="true"></i>}
      </IconButton>
    </div>;
  };

  renderKetcherEditor = () => {
    return <KetcherEditor
      ref={this.editor as React.RefObject<KetcherEditor>}
      structure={this.props.structure}
      moleculesOnly={this.props.moleculesOnly}
      isSearch={this.props.isSearch}
    />;
  };

  renderEditor = () => {
    return this.renderKetcherEditor();
  };

  renderActions = () => {
    return (
      <CardActions>
        {this.usingKetcher
          ? <A className='help-link'
          onClick={this.handleClickHelp}>Have a feature request?</A>
          : null}
        <SubmitOrCancel
          idSubmit='useThisStructureButton'
          idCancel='closeStructureEditorLink'
          green
          onCancel={this.handleClickCancel}
          onSubmit={this.handleClickSubmit}
        >
          Use this structure
        </SubmitOrCancel>
      </CardActions>
    );
  };

  get dialogPaperStyle() {
    const expanded = getRootStore().localPersistenceStore.data.structureExpanded;
    const expandedStyle = {
      style: {
        minWidth: window.innerWidth - 40,
        minHeight: window.innerHeight - 40,
        overflow: 'none',
      },
    };
    if (expanded) {
      return expandedStyle;
    } else {
      return {
        style: {
          minWidth: Math.min(800, expandedStyle.style.minWidth),
          minHeight: Math.min(735, expandedStyle.style.minHeight),
          overflow: 'none',
        },
      };
    }
  }

  render() {
    const classes = {
      'paper': 'modal-dialog',
    };

    const { dialogPaperStyle } = this.state;
    return (
      <>
      <Dialog
        id='StructureEditorDialog'
        open={this.state.open}
        classes={classes}
        className={'StructureEditorDialog'}
        PaperProps={dialogPaperStyle }
      >
        { this.renderInsertBar() }
        { this.renderEditor() }
        { this.renderActions() }
      </Dialog>
      </>
    );
  }
}

export function launchStructureEditorDialog(props: Props) {
  return openModalComponent(StructureEditorDialog, props, true);
}
