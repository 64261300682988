import React from 'react'

import BaseRow from './BaseRow.jsx'

export default function RowPreview(props) {
  const newProps = Object.assign({}, props, {
    elementId: 'sortable-row-preview',
    dragHandle: ({ ...args }) => ({ ...args }),
    readOnly: true,
    handleChange: () => {},
  })

  return (<BaseRow {...newProps} />)
}
