import * as React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import {
  ColumnDef,
  SimpleDataTableProps,
  SimpleTableCell,
  SimpleTableHeaderCell,
  getDataTableFieldValue,
} from '@/components/SimpleDataTable/SimpleDataTable';
import { DDFormUtils } from '@/shared/components/DDForm/DDFormUtils';
import { AnyObject, StringOrNumber } from '@/types';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';

import { ColumnForDisplay } from '@/Protocols/types';
import { ExpandMoreButton } from '@/components/Buttons/ExpandMoreButton';
import { ColumnsEditor } from '@/components/ColumnsEditor/ColumnsEditor';
import { CustomOrDefaultResultColumnId, FieldType } from '../types';
import './NestedExpandableDataTable.sass';
import {
  EncodedFieldString,
  encodeFieldTypeInId,
  unencodeFieldTypeFromId,
} from './encodeUtils';

type HexString = `#${string}`;
type RGBString = `rgb(${number}, ${number}, ${number})`;

type EvenOddObj<TObjVal> = { even: TObjVal; odd: TObjVal };
type RootChildObj<TObjVal> = { rootRow?: TObjVal; childRow: TObjVal };

export type RootedRow<TRow extends AnyObject = AnyObject> = {
  root: TRow;
  children: Array<TRow>;
  rootID: string;
  rootExpandable?: boolean;
};

export type ClickNestableRowProps = (clickedOn: {
  rootIndex: number;
  childIndex: number | null;
  row: RootedRow;
}) => void;

type LocalClickNestableRowProps = (clickedOnIndices: {
  rootIndex: number;
  childIndex: number | null;
}) => void;

export type ExportColumnProps = {
  onChangeVisibleColumns: (
    value: Array<{ id: CustomOrDefaultResultColumnId; field_type: FieldType }>
  ) => void;
  availableColumns: ColumnForDisplay<CustomOrDefaultResultColumnId | number>[];
  selectedColumns: EncodedFieldString[];
};

type NestedExpandableDataTableProps<
  TColID extends StringOrNumber = StringOrNumber
> = Omit<SimpleDataTableProps<TColID>, 'rows' | 'onClickRow'> & {
  rowColors?: RootChildObj<
    | HexString
    | RGBString
    | ((row: AnyObject, ii: number) => HexString | RGBString)
  >;
  // hoverRowColor?: RootChildObj<HexString | RGBString>;
  rootRows: Array<RootedRow>;
  onClickRow?: ClickNestableRowProps;
};

const BlankSpacer = () => (
  <td>
    <IconButton aria-label='non-expandable row' size='small' />
  </td>
);
class RootTableRow<
  TColID extends StringOrNumber = StringOrNumber
> extends React.Component<
  Omit<NestedExpandableDataTableProps<TColID>, 'onClickRow'> & {
    onClickRow: LocalClickNestableRowProps;
    index: number;
    row: AnyObject;
    handleExpandRoot: () => void;
    isExpanded: boolean;
    rootExpandable: boolean;
    tooltipText: string;
  }
> {
  handleClickRow = () => {
    if (this.props.onClickRow) {
      this.props.onClickRow({
        rootIndex: this.props.index,
        // clicked on root row, so no child index
        childIndex: null,
      });
    }
  };

  render() {
    const {
      row,
      columns,
      getRowClassName,
      index,
      rowColors: { rootRow },
    } = this.props;
    const rowColor =
      typeof rootRow === 'string' ? rootRow : rootRow(row, index);

    return (
      <TableRow
        onClick={this.handleClickRow}
        className={getRowClassName?.({ ...row, index })}
        style={{
          backgroundColor: rowColor,
          cursor: this.handleClickRow ? 'pointer' : undefined,
        }}
      >
        {this.props.rootExpandable
          ? (
          <td>
            <Tooltip title={this.props.tooltipText}>
              <ExpandMoreButton
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.handleExpandRoot();
                }}
              />
            </Tooltip>
          </td>
            )
          : (
          <BlankSpacer />
            )}
        {columns.map((column) => {
          return (
            <SimpleTableCell
              key={column.id}
              row={row}
              id={column.id}
              {...this.props}
            />
          );
        })}
      </TableRow>
    );
  }
}
class ChildTableRow extends React.Component<
  Omit<NestedExpandableDataTableProps, 'onClickRow'> & {
    childIndex: number;
    onClickRow: LocalClickNestableRowProps;
    rootIndex: number;
    row: AnyObject;
    isExpanded: boolean;
  }
> {
  handleClickRow = () => {
    if (this.props.onClickRow) {
      this.props.onClickRow({
        rootIndex: this.props.rootIndex,
        childIndex: this.props.childIndex,
      });
    }
  };

  render() {
    const {
      childIndex: index,
      row,
      columns,
      getRowClassName,
      isExpanded,
      // hoverRowColor: { childRow: childHoverColor },
      rowColors: { childRow },
    } = this.props;
    const rowColor =
      typeof childRow === 'string' ? childRow : childRow(row, index);

    return (
      <TableRow
        onClick={this.handleClickRow}
        className={`child-row ${
          isExpanded ? 'expanded-table-row' : 'collapsed-table-row'
        }`}
        style={{
          backgroundColor: rowColor,
          visibility: 'collapse',
          cursor: this.handleClickRow ? 'pointer' : undefined,
        }}
      >
        <BlankSpacer />
        {columns.map((column, ii) => {
          const cellKey = `${column.id}-${ii}`;
          return (
            <SimpleTableCell
              key={cellKey}
              {...this.props}
              row={row}
              id={column.id}
            />
          );
        })}
      </TableRow>
    );
  }
}
@observer
export class NestedExpandableDataTable<
  TColID extends StringOrNumber = StringOrNumber
> extends React.Component<
  NestedExpandableDataTableProps<TColID> & {
    // some of these props could probably be moved to state but IMO (V) having a stricter separation
    // between function & UI really makes something like Storybook a lot easier to use - also
    // makes this component more easily reusable when not strictly tied to specific data
    tooltipTextMap: { alreadyExpanded: string; alreadyCollapsed: string };
    rootExclusiveColumnIds?: Array<TColID>;
    editorProps: ExportColumnProps;
    headerClassName?: string;
  }
> {
  static defaultProps: Partial<NestedExpandableDataTableProps> = {
    presorted: false,
  };

  rowLimit = 100;
  expandedRoots = [];

  constructor(props) {
    super(props);
    makeObservable(
      this,
      {
        rootedRows: computed,
        columns: computed,
        rowLimit: observable,
        expandedRoots: observable,
      },
      { autoBind: true },
    );
  }

  get columns(): ColumnDef<TColID>[] {
    const { columns, rootRows } = this.props;
    const columnsWithData = new Set<TColID>();
    columns.forEach((column) => {
      if (!column.hideIfEmpty) {
        columnsWithData.add(column.id);
      }
    });

    rootRows.forEach((row) => {
      columns.forEach((column) => {
        const fieldValue = DDFormUtils.getValue('' + column.id, row);
        if (fieldValue === 0 || !!fieldValue) {
          columnsWithData.add(column.id);
        }
      });
    });

    return this.props.columns.filter((column) =>
      columnsWithData.has(column.id),
    );
  }

  get rootedRows() {
    if (!this.props?.rootRows) {
      return [];
    }

    if (this.props.presorted) {
      return this.props.rootRows;
    }

    // perform internal sort
    const result = this.props.rootRows.slice();
    const {
      props,
      props: { sortBy },
    } = this;
    if (sortBy) {
      result.sort((rowA, rowB) => {
        const a = getDataTableFieldValue(props, sortBy.id, rowA.root);
        const b = getDataTableFieldValue(props, sortBy.id, rowB.root);
        const strictlyNumerical =
          typeof a === 'number' && typeof b === 'number';

        const result = strictlyNumerical
          ? a - b
          : ('' + a).localeCompare('' + b);

        const dirFactor = sortBy.direction === 'desc' ? -1 : 1;

        return dirFactor * result;
      });
    }

    return result;
  }

  componentDidUpdate(
    prevProps: Readonly<NestedExpandableDataTableProps>,
  ): void {
    const { columns } = this;

    if (prevProps.columns !== columns) {
      if (!this.props.sortBy) {
        // set default sortBy column if none set from the first sortable column.
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].isSortable !== false) {
            this.props.setSortBy?.({ id: columns[i].id, direction: 'asc' });
            return;
          }
        }
      }
    }
  }

  handleFetchMoreRows = action(() => {
    this.rowLimit += 100;
  });

  handleExpandRoot = (isExpanded: boolean, rootID: string) => {
    if (isExpanded) {
      const rootIndex = this.expandedRoots.indexOf(rootID);
      this.expandedRoots = [
        ...this.expandedRoots.slice(0, rootIndex),
        ...this.expandedRoots.slice(rootIndex + 1),
      ];
      return;
    }
    this.expandedRoots = [...this.expandedRoots, rootID];
  };

  state = {
    expandedRoots: [],
  };

  renderEditorColumn() {
    const { onChangeVisibleColumns, availableColumns, selectedColumns } =
      this.props.editorProps;
    const wrappedChangeVisible = (val) => {
      const unencodedColumns = val.map((col) => unencodeFieldTypeFromId(col));
      onChangeVisibleColumns(unencodedColumns);
    };
    const encodedAvailableColumns = availableColumns.map((val) => ({
      ...val,
      children: val.children.map((child) => ({
        ...child,
        id: encodeFieldTypeInId({
          field_type: val.id as FieldType,
          id: child.id,
        }),
      })),
    }));

    return (
      <ColumnsEditor
        availableColumns={encodedAvailableColumns}
        value={selectedColumns}
        onChangeValue={wrappedChangeVisible}
        headerClassName={'search_results__columns__project'}
        itemClassName={'search_results__columns__field-name'}
      />
    );
  }

  render() {
    const { ariaLabel, infiniteScrollHeight, renderHeaderRow } = this.props;
    const { columns, rootedRows } = this;
    const displayRows =
      rootedRows.length > this.rowLimit
        ? rootedRows.slice(0, this.rowLimit)
        : rootedRows;
    const {
      alternateRowColors = true,
      showHover = true,
      rowColors = {
        rootRow: (_, ii) =>
          ii % 2 === 0 ? 'rgb(240, 244, 247)' : 'rgb(255, 255, 255)',
        childRow: (_, ii) =>
          ii % 2 === 0 ? 'rgb(215, 219, 222)' : 'rgb(230, 230, 230)',
      },
      className,
      headerClassName,
      tooltipTextMap,
      onClickRow,
      ...restProps
    } = this.props;
    const tableBodyClass = `${showHover ? 'show-hover-gray' : ''}`;
    const childColumns = columns.map((val) => {
      return this.props.rootExclusiveColumnIds.includes(val.id)
        ? { id: '-----', label: '-----' }
        : val;
    });
    return (
      <InfiniteScroll
        element='div'
        height={infiniteScrollHeight ? `${infiniteScrollHeight}px` : ''}
        pageStart={0}
        loadMore={this.handleFetchMoreRows}
        hasMore={this.props.rootRows.length > this.rowLimit}
      >
        <Table
          aria-label={ariaLabel}
          className={'NestedExpandableDataTable ' + className}
        >
          <TableHead className={headerClassName}>
            {renderHeaderRow && (
              <TableRow>
                <TableCell
                  className='additional-header-row'
                  style={{ height: this.props.collapsedHeight }}
                  colSpan={columns.length}
                >
                  {renderHeaderRow()}
                </TableCell>
              </TableRow>
            )}

            <TableRow className='header-row'>
              <BlankSpacer />
              {columns.map((column, ii) => {
                return (
                  <SimpleTableHeaderCell
                    {...this.props}
                    key={column.id === null ? `blank-${ii}` : column.id}
                    id={column.id}
                  />
                );
              })}
              {this.renderEditorColumn()}
            </TableRow>
          </TableHead>

          <TableBody className={tableBodyClass}>
            {displayRows.map(
              ({ root, rootID, children, rootExpandable }, rootIndex) => {
                const isExpanded = this.expandedRoots.includes(rootID);

                const tooltipText = isExpanded
                  ? tooltipTextMap.alreadyExpanded
                  : tooltipTextMap.alreadyCollapsed;
                return (
                  <>
                    <RootTableRow
                      key={rootIndex}
                      isExpanded={isExpanded}
                      tooltipText={tooltipText}
                      rootExpandable={rootExpandable}
                      columns={columns}
                      index={rootIndex}
                      row={root}
                      rowColors={rowColors}
                      onClickRow={(args) =>
                        onClickRow({
                          ...args,
                          row: this.rootedRows[args.rootIndex],
                        })
                      }
                      handleExpandRoot={() =>
                        this.handleExpandRoot(isExpanded, rootID)
                      }
                      {...restProps}
                    />
                    {rootExpandable
                      ? (
                          children.map((childRow, childIdx) => {
                            return (
                          <ChildTableRow
                            {...restProps}
                            key={`${rootIndex}-${childIdx}`}
                            rootIndex={rootIndex}
                            isExpanded={isExpanded}
                            columns={childColumns}
                            childIndex={childIdx}
                            onClickRow={(args) =>
                              onClickRow({
                                ...args,
                                row: this.rootedRows[args.rootIndex],
                              })
                            }
                            rowColors={rowColors}
                            row={childRow}
                          />
                            );
                          })
                        )
                      : (
                      <></>
                        )}
                  </>
                );
              },
            )}
          </TableBody>
        </Table>
      </InfiniteScroll>
    );
  }
}
