import React from 'react'

import FieldDefinitionsTable from '@/shared/components/FieldDefinitionsTable/component.jsx'
import DataTable from './components/DataTable'
import EditDataTable from './components/EditDataTable'

export default class ElnFieldDefinitions extends React.Component {
  render() {
    const { editing, edit, errorMessages } = this.props

    const VisibleDataTable = editing ? EditDataTable : DataTable

    return (
      <FieldDefinitionsTable
        editing={editing}
        edit={edit}
        errorMessages={errorMessages}
        name="ELN Fields"
      >
        <VisibleDataTable />
      </FieldDefinitionsTable>
    )
  }
}
