import { LocationValue } from '@/Samples/types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { LocationUtils } from './locationUtils';
import { LocationPicker } from './LocationPicker';
import { Box } from '@mui/system';

type Props = React.ComponentProps<typeof LocationPicker> & {
  open: boolean;
  onSubmit: (value: LocationValue) => void;
  onCancel: () => void;
};

type State = {
  value?: LocationValue;
};

export class LocationPickerDialog extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
    return {
      value: nextProps.open ? (prevState.value || nextProps.value) : null,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { value } = this.state;
    if (value) {
      onSubmit(value);
    }
  };

  render() {
    const { open, value: originalValue, locations, fetchBoxContents, onCancel } = this.props;
    const { value } = this.state;

    let changeLegend = <></>;
    if (value && originalValue && (value.id !== originalValue.id || value.position !== originalValue.position)) {
      const from = LocationUtils.getLabelForLocation(originalValue, locations);
      const to = LocationUtils.getLabelForLocation(value, locations);
      changeLegend = <table><tbody>
        <tr><td className='text-align-right'>Moving from:</td><td><b>{from}</b></td></tr>
        <tr><td className='text-align-right'>to:</td><td><b>{to}</b></td></tr>
      </tbody></table>;
    }
    return (
      <Dialog
        className='LocationPickerDialog'
        open={open}
        fullWidth
        maxWidth="md">
        <DialogTitle className='muiDialog-title'>Pick Location</DialogTitle>
        <DialogContent>
          <LocationPicker
            value={value}
            locations={locations}
            fetchBoxContents={fetchBoxContents}
            onChange={value => {
              this.setState({ value });
            }}
          />
          <span className='change-legend'>
            {changeLegend}
          </span>

        </DialogContent>
        <Box className='full-width'>
          <DialogActions className='bottom-modal-row'>
            <Button variant='text' onClick={onCancel}>Cancel</Button>
            <Button variant='contained' onClick={this.handleSubmit}
              disabled={!value?.id || !value?.position}
              color='primary'>
              OK
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}
