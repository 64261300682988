import React, { ReactNode, useEffect, useState } from 'react';
import { DoseResponsePlot } from '@cdd/vision/dist/app/entrypoints/doseResponsePlot';
import { RenderIfVisible } from '@/components/RenderIfVisible/RenderIfVisible';
import { A } from '@/shared/components/sanitizedTags.js';

type DoseResponsePlotProps = Parameters<typeof DoseResponsePlot>[0];
type DoseResponsePlotData = Pick<DoseResponsePlotProps, 'data'>;
type DatalessDoseResponsePlotProps = Omit<DoseResponsePlotProps, 'data'>;
type DoseResponsePlotWrapperProps = DatalessDoseResponsePlotProps & {
    dataUrl?: string;
};
type DelayedDoseResponsePlotProps = DoseResponsePlotWrapperProps & {
    children?: ReactNode;
    clickUrl?: string;
};

const DoseResponsePlotWrapper = (props: DoseResponsePlotWrapperProps) => {
  const [state, setState] = useState<DoseResponsePlotData>({ data: null });
  const { dataUrl, ...rest } = props;

  useEffect(() => {
    fetch(dataUrl)
      .then((response) => response.json())
      .then((data) => setState({ data: Array.isArray(data) ? data : [data] }));
  }, []);

  if (!state.data) {
    return null;
  }

  return <DoseResponsePlot {...rest} {...state} />;
};

const DelayedDoseResponsePlotProps = (props: DelayedDoseResponsePlotProps) => {
  const { children, clickUrl, ...rest } = props;

  return <RenderIfVisible>
        <A href={clickUrl} target="newWindow" style={{ display: 'contents' }}>
            <DoseResponsePlotWrapper {...rest} />
        </A>
        {children}
    </RenderIfVisible>;
};

export default DelayedDoseResponsePlotProps;
