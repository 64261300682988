import React from 'react'
import {
  FormControl,
  Grid,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'

import { default as SaltPicker } from '../Salt/Picker.jsx'
import { default as SolventPicker } from '../SolventOfCrystallization/Picker.jsx'

import MuiTheme from 'shared/components/MuiTheme.jsx'

const NoSaltFreeBaseOrAcid = 'No Salt, free base or acid'
const NoSolvent = 'No Solvent'

class QuantityEditor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const inputProps = {
      step: 1,
      min: 0,
    }
    return (
      <TextField
        disabled={this.props.disabled}
        fullWidth
        id='specified_batch_salt_ratio'
        inputProps={inputProps}
        name={`${this.props.objectName}[salt_ratio]`}
        autoComplete='off'
        {...this.props}
      />
    )
  }
}

QuantityEditor.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

class SaltAndSolventEditor extends React.Component {
  constructor(props) {
    super(props)
    const { defaultSaltData } = this.props || { salt_count: 0, solvent_of_crystallization_count: 0 }
    const { salt_count, solvent_of_crystallization_count } = defaultSaltData
    const { salt_name, solvent_of_crystallization_id, solvent_of_crystallization_name } = defaultSaltData
    var solventValue
    if (solvent_of_crystallization_id) {
      solventValue = {
        label: solvent_of_crystallization_name,
        value: solvent_of_crystallization_id,
      }
    } else {
      solventValue = null
    }
    const saltValue = {
      label: salt_name || NoSaltFreeBaseOrAcid,
      value: salt_name || NoSaltFreeBaseOrAcid,
    }
    this.state = {
      saltCount: salt_count || 0,
      saltValue,
      solventOfCrystallizationCount: solvent_of_crystallization_count,
      solventValue,
    }
  }

  handleChange = key => (value) => {
    this.setState({
      [key]: value,
    })
  }

  handleChangeEvent = key => (event) => {
    const { target } = event
    const { value } = target
    this.setState({
      [key]: value,
    })
  }

  noneOrOne = (currentQuantity, selectionRepresentsNone) => {
    var quantity = currentQuantity
    if (quantity == 0 && !selectionRepresentsNone) {
      quantity = 1
    } else if (quantity != 0 && selectionRepresentsNone) {
      quantity = 0
    }
    return quantity
  }

  setSalt = (option) => {
    const value = option ? option.value : NoSaltFreeBaseOrAcid
    var saltCount = this.noneOrOne(this.state.saltCount, this.isNoSalt(value))
    this.setState({
      saltCount,
      saltValue: option,
    })
  }

  setSolvent = (option) => {
    const { value } = option
    var solventOfCrystallizationCount = this.noneOrOne(this.state.solventOfCrystallizationCount, this.isNoSolvent(value))
    this.setState({
      solventOfCrystallizationCount,
      solventValue: option,
    })
  }

  isNoSalt = (value) => {
    return value == null || value == undefined || value == NoSaltFreeBaseOrAcid
  }

  isNoSolvent = (value) => {
    return value == null || value == undefined || value == '' || value == NoSolvent
  }

  handleChangeSaltCount = (event) => {
    this.handleChangeEvent('saltCount')(event)
  }

  handleChangeSolventOfCrystallizationCount = (event) => {
    this.handleChangeEvent('solventOfCrystallizationCount')(event)
  }

  render() {
    return (
      <>
        <FormControl component="fieldset" fullWidth>
          <Grid container className='SaltAndSolventEditor' alignItems='flex-end' spacing={1}>
            <Grid item xs={2}>
              <QuantityEditor
                disabled={this.props.disabled}
                id='specified_batch_salt_ratio'
                name={`${this.props.objectName}[salt_ratio]`}
                onChange={this.handleChangeSaltCount}
                value={'' + this.state.saltCount}
              />
            </Grid>
            <Grid item xs={10}>
              <SaltPicker
                disabled={this.props.disabled}
                name={`${this.props.objectName}[salt_name]`}
                setValue={this.setSalt}
                defaultValue={this.state.saltValue}
                NoSaltFreeBaseOrAcid={NoSaltFreeBaseOrAcid}
              />
            </Grid>

            <Grid item xs={2}>
              <QuantityEditor
                disabled={this.props.disabled}
                id='specified_batch_solvent_of_crystallization_ratio'
                name={`${this.props.objectName}[solvent_of_crystallization_ratio]`}
                onChange={this.handleChangeSolventOfCrystallizationCount}
                value={'' + this.state.solventOfCrystallizationCount}
              />
            </Grid>
            <Grid item xs={10}>
              <SolventPicker
                disabled={this.props.disabled}
                name={`${this.props.objectName}[solvent_of_crystallization_id]`}
                options={this.props.solventOptions}
                setValue={this.setSolvent}
                defaultValue={this.state.solventValue}
              />
            </Grid>
          </Grid>
        </FormControl>
      </>
    )
  }
}

SaltAndSolventEditor.propTypes = {
  solventOptions: PropTypes.array.isRequired,
  defaultSaltData: PropTypes.object.isRequired,
  objectName: PropTypes.string.isRequired,
}

export default SaltAndSolventEditor
