import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import editable from './modules/editable.js'
import MoleculeFieldDefinitions from './component.jsx'
import { getRemovedMoleculeFieldDefinitionsWithData } from './selectors.js'

function mapStateToProps(state) {
  const removedMoleculeFieldDefinitionsWithData = getRemovedMoleculeFieldDefinitionsWithData(state)

  return {
    editing: state.get('editing'),
    moleculeFieldDefinitions: state.getIn(['current', 'moleculeFieldDefinitions']),
    errorMessages: state.get('errorMessages'),
    removedMoleculeFieldDefinitionsWithData,
    usingRegistrationSystem: state.get('usingRegistrationSystem'),
    moleculeFieldDefinitionsLocked: state.get('moleculeFieldDefinitionsLocked'),
    allowsMultipleMoleculesPerStructure: state.get('allowsMultipleMoleculesPerStructure'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    edit: editable.actions.edit,
  }, dispatch)
}

const MoleculeFieldDefinitionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoleculeFieldDefinitions)

export default MoleculeFieldDefinitionsContainer
