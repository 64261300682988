import React from 'react';

import ExportLink from '@/shared/components/ExportLink';

// TODO dedupe with eln entries stuff where possible
export type EntriesExportLinksProps = {
  exportsDisabled: boolean;
  terminology: string;
  onClickExport: () => void;
};

export class EntriesExportLinks extends React.Component<EntriesExportLinksProps> {
  render() {
    const { exportsDisabled, terminology, onClickExport } = this.props;
    return (
      <ExportLink
        className='search-bar__export_link'
        disabled={exportsDisabled}
        onClick={onClickExport}
      >
        Export {terminology}
      </ExportLink>
    );
  }
}
