import React from 'react'

import Icon from '@/shared/components/Icon.jsx'
import { Actions } from '@/shared/components/FieldDefinitionsTable'

export default function BaseRow(props) {
  const { name, id, requiredText } = props
  const elementId = `batch-field-definition-${id}`

  return (
    <div className="row" id={elementId}>
      <div className="inner name">
        <span name="name">
          {name}
        </span>
      </div>
      <div className="inner data-type"></div>
      <div className="inner unique"></div>
      <div className="inner overwritable"></div>
      <div className="inner requirement">
        <span name="required requirement">
          {requiredText}
        </span>
      </div>
      <div className="inner actions">
        <Actions disabled={true} />
        <span>
          <Icon icon="disabled_drag" />
        </span>
      </div>
    </div>
  )
}
