import module from '@/shared/modules/pickListValues'
import persistable from './persistable.js'

const pickListValues = module({
  namespace: 'protocolFieldDefinitions',
  objectId: 'protocol_field_definition_id',
  persistable,
})

const { initialState, actions, actionTypes, reducer } = pickListValues

export { initialState, actions, actionTypes, reducer }
export default pickListValues
