import { fromJS, Map } from 'immutable'

import {
  ADD, UPDATE, REMOVE, SPLICE, UPDATE_REQUIRED_GROUP_NUMBER,
} from '@/FieldDefinitions/BatchFieldDefinitions/actions/batchFieldDefinitions.js'

export function batchFieldDefinitionsInitialState(batchFieldDefinitions: Object[]) {
  const bfds = batchFieldDefinitions.map((obj, index) => {
    const temp_group_number = index + batchFieldDefinitions.length
    const bfd = Object.assign({}, obj.batch_field_definition, {
      temp_group_number,
    })

    delete bfd.pick_list_values

    return bfd
  })

  return fromJS({
    byId: bfds.reduce((memo, bfd) => {
      memo[bfd.id] = bfd
      return memo
    }, {}),
    all: bfds
      .sort((a, b) => a.display_order - b.display_order)
      .map(bfd => bfd.id),
  })
}

const resetGroupNumbers = reducer => (state, action) => {
  let reducedState = reducer(state, action)

  if (action.subtype !== UPDATE_REQUIRED_GROUP_NUMBER) {
    return reducedState
  } else {
    let index = 1

    const groupNumberMappings = reducedState.get('byId').reduce((memo, bfd) => {
      const requiredGroupNumber = bfd.get('required_group_number')

      if (requiredGroupNumber && !memo[requiredGroupNumber]) {
        memo[requiredGroupNumber] = index
        index++
      }

      return memo
    }, {})

    return reducedState.update('byId', byId => byId.map(bfd => {
      return bfd.set(
        'required_group_number',
        groupNumberMappings[bfd.get('required_group_number')]
      )
    }))
  }
}

function _batchFieldDefinitions(state: Object = Map(), action: Object) {
  switch (action.type) {
    case ADD: {
      const batchFieldDefinition = Map({
        id: 'FAKE-ID-' + Date.now(),
        data_type_name: 'Text',
        display_order: state.get('all').size,
        unique_value: false,
        required_group_number: null,
        batch_fields_count: 0,
        temp_group_number: state.get('all').size * 2,
      })

      return state
        .setIn(['byId', batchFieldDefinition.get('id')], batchFieldDefinition)
        .update('all', all => all.push(batchFieldDefinition.get('id')))
    }

    case UPDATE:
      switch (action.subtype) {
        case UPDATE_REQUIRED_GROUP_NUMBER: {
          if (!action.requiredGroupNumber) {
            // is optional
            return state.setIn(['byId', action.id.toString(), 'required_group_number'], null)
          } else {
            const requiredGroupNumber = parseInt(action.requiredGroupNumber)
            const requiredGroup = state.get('byId').filter(bfd => {
              return bfd.get('required_group_number') === requiredGroupNumber
            })
            const otherBfd = state.get('byId').find(bfd => {
              return bfd.get('temp_group_number') === requiredGroupNumber
            })

            let interimState = state

            if ((!requiredGroup || requiredGroup.size === 0) && otherBfd.get('id') !== action.id) {
              // is starting a new group with another definition
              interimState = state.setIn(['byId', otherBfd.get('id').toString(), 'required_group_number'], requiredGroupNumber)
            }

            // is setting itself to required, either alone or with another
            return interimState.setIn(['byId', action.id.toString(), 'required_group_number'], requiredGroupNumber)
          }
        }

        default: {
          const updatedValues = Object.assign({}, action.updatedValues)
          const data_type_name = updatedValues.data_type_name

          if (data_type_name) {
            if (state.getIn(['byId', action.id.toString(), 'batch_fields_count']) > 0) {
              delete updatedValues.data_type_name
            } else if (data_type_name !== 'Text') {
              updatedValues.unique_value = false
            }
          }

          return state.mergeDeep({
            byId: { [action.id]: updatedValues },
          })
        }
      }

    case REMOVE:
      return state.mergeDeep({ byId: { [action.id]: { _destroy: 1 } } })

    case SPLICE:
      return state.update('all', all => {
        return all
          .delete(all.findIndex(id => id === action.id))
          .insert(action.index, action.id)
      })

    default:
      return state
  }
}

const batchFieldDefinitions = resetGroupNumbers(_batchFieldDefinitions)

export default batchFieldDefinitions
