import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import editable from './modules/editable.js'
import ProtocolFieldDefinitions from './component.jsx'
import { getRemovedProtocolFieldDefinitionsWithData } from './selectors.js'

function mapStateToProps(state) {
  const removedProtocolFieldDefinitionsWithData = getRemovedProtocolFieldDefinitionsWithData(state)

  return {
    editing: state.get('editing'),
    protocolFieldDefinitions: state.getIn(['current', 'protocolFieldDefinitions']),
    errorMessages: state.get('errorMessages'),
    removedProtocolFieldDefinitionsWithData,
    usingRegistrationSystem: state.get('usingRegistrationSystem'),
    protocolFieldDefinitionsLocked: state.get('protocolFieldDefinitionsLocked'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    edit: editable.actions.edit,
  }, dispatch)
}

const ProtocolFieldDefinitionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtocolFieldDefinitions)

export default ProtocolFieldDefinitionsContainer
