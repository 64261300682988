import React from 'react'
import Icon from '@/shared/components/Icon.jsx'

type Props = {
  checked: boolean
};

export default class Checkbox extends React.Component {
  props: Props

  render() {
    if (this.props.checked) {
      return (
        <Icon icon="tick" forceSize="16" />
      )
    } else {
      return <div />
    }
  }
}
