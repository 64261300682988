/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import { FieldDataType } from '@/FieldDefinitions/types';
import { EditFormDefinitionStore } from '@/FormDefinitions/stores/editFormDefinitionStore';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

const {
  row,
  column,
  textInput,
  numberInput,
  dateInput,
  select,
  checkbox,
  button,
  typography,
} = layoutBuilder;

type Props = {
  store: EditFormDefinitionStore;
};

@observer
export class EditFieldDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      layout: computed,
      bottomRowElements: computed,
    });
  }

  get value() {
    return this.props.store.currentEditItem;
  }

  handleSubmit = () => {
    this.props.store.handleSubmitEditField();
  };

  handleCancel = () => {
    this.props.store.handleCancelEditField();
  };

  handleClose = (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'escapeKeyDown') {
      this.props.store.handleCancelEditField();
    }
  };

  handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.value.label = e.target.value;
  };

  handleChangeSpan = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.value.span = parseInt(e.target.value, 10);
  };

  get fieldDefinition() {
    const { store, store: { currentEditItem } } = this.props;

    return store.getFieldDefinition(currentEditItem);
  }

  get bottomRowElements() {
    const result = [button({
      key: 'DELETE',
      label: 'Remove Field',
      variant: 'text',
      className: 'delete-button',
    }),
    ];

    const { currentEditItem, currentFieldDefinitionsRequiredGroups } = this.props.store;

    if (currentEditItem) {
      const canDelete = (!currentEditItem.isRequired) &&
        (typeof currentEditItem.fieldID !== 'string') && // a special field like protocol name or run date, always required
        (currentFieldDefinitionsRequiredGroups[currentEditItem.fieldID]?.canDelete ?? true);

      result[0].onClickButton = this.handleDelete;
      result[0].disabled = !canDelete;
      result[0].tooltip = canDelete ? '' : 'This field is required and cannot be removed.';
    }

    return result;
  }

  get layout() {
    const {
      fieldDefinition,
      props: {
        store: {
          currentEditItem,
          currentFieldDefinitionsRequiredGroups,
        },
      },
    } = this;

    const isProtocolField = currentEditItem.fieldID !== null && currentEditItem.fieldID !== undefined;
    const isOntologyField = currentEditItem.ontologyAssn !== null && currentEditItem.ontologyAssn !== undefined;
    const isText = !isProtocolField && !isOntologyField;

    const canEditText = isText || isOntologyField;

    const { data_type_name } = fieldDefinition ?? {};

    // if this field is required via its definition, then it will have a required label (which may indicate that it is one of
    // a group of required fields). In this case, we shouldn't allow unchecking Required.
    const isFieldDefinitionRequired = !!currentFieldDefinitionsRequiredGroups[currentEditItem.fieldID]?.requiredLabel;
    const requiredLabel = currentFieldDefinitionsRequiredGroups[currentEditItem.fieldID]?.requiredLabel;
    const isRequired = currentEditItem.isRequired || requiredLabel !== null;

    const pick_list_values = ((data_type_name === FieldDataType.PickList)
      ? (fieldDefinition.pick_list_values ?? [])
      : [])
      .filter(item => item !== null && item !== undefined && !item.hidden);

    const result = column({ controlAttributes: { style: { marginBottom: '2em' } } }, [
      textInput({ key: 'label', label: 'Field Label', disabled: !canEditText, autoFocus: canEditText }),
    ]);

    if (!isText) {
      result.children.push(
        row({}, [
          checkbox({
            key: 'isRequired',
            label: 'Required',
            visible: isProtocolField,
            disabled: (requiredLabel !== null && !currentEditItem.isRequired) || isFieldDefinitionRequired,
            translateGetValue: (value) => {
              return value || isRequired;
            },
          }),
          typography({
            label: requiredLabel,
            visible: !!requiredLabel,
          }),
        ]),
      );

      const defaultValueKeyAndLabel = { key: 'defaultValue', label: 'Default Value' };
      const lockValue = { key: 'isLocked', label: 'Lock Value' };
      switch (data_type_name) {
        case FieldDataType.Number:
        case FieldDataType.Float:
          result.children.push(numberInput(defaultValueKeyAndLabel));
          result.children.push(checkbox(lockValue));
          break;

        case FieldDataType.Text:
        case FieldDataType.LongText:
          result.children.push(textInput(defaultValueKeyAndLabel));
          result.children.push(checkbox(lockValue));
          break;

        case FieldDataType.Date:
          result.children.push(dateInput(defaultValueKeyAndLabel));
          result.children.push(checkbox(lockValue));
          break;

        case FieldDataType.PickList:
          result.children.push(select({
            ...defaultValueKeyAndLabel,
            selectOptions: pick_list_values.filter(item =>
              (!currentEditItem.allowedValues?.length || currentEditItem.allowedValues.includes(item.value))),
            optionFieldLabel: 'value',
            valueType: 'number',
          }));
          result.children.push(checkbox(lockValue));

          if (pick_list_values.length > 0) {
            result.children.push(column({ legend: 'Allowed Pick List Values' },
              pick_list_values.map(item => checkbox({
                key: 'allowedValues',
                label: item.value,
                translateGetValue: (value: any) => {
                  return (currentEditItem.allowedValues ?? []).includes(item.value) ||
                  !currentEditItem.allowedValues?.length;
                },
                translateSetValue: (value: any) => {
                  const allowedValues = currentEditItem.allowedValues?.length
                    ? currentEditItem.allowedValues
                    : pick_list_values.map(item => item.value);

                  if (value) {
                    if (!allowedValues.includes(item.value)) {
                      allowedValues.push(item.value);
                    }
                  } else {
                    const index = allowedValues.indexOf(item.value);
                    if (index >= 0) {
                      allowedValues.splice(index, 1);
                    }
                  }
                  return allowedValues;
                },
              }))));
          }

          break;
      }
    }

    return result;
  }

  handleDelete = () => {
    this.props.store.deleteField(this.value.layoutID);
    this.handleCancel();
  };

  render() {
    const {
      store: {
        currentEditItem,
      },
    } = this.props;

    return (
      <>
        <Dialog
          open={currentEditItem !== null}
          onClose={this.handleClose}
        >
          {currentEditItem && (
            <>
              <DialogTitle className='muiDialog-title'>
                Edit Field
              </DialogTitle>

              <DialogContent>
                <CDDModalForm
                  data={currentEditItem}
                  layout={this.layout}
                  onOK={this.handleSubmit}
                  onCancel={this.handleCancel}
                  terminology={{ OK: 'Save' }}
                  bottomLeftElements={this.bottomRowElements}
                />
              </DialogContent>
            </>
          )}
        </Dialog>
      </>);
  }
}
