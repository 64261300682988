import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import editable from './modules/editable.js'
import BatchFieldDefinitions from './component.jsx'
import { getRemovedBatchFieldDefinitionsWithData } from './selectors.js'

function mapStateToProps(state) {
  const removedBatchFieldDefinitionsWithData = getRemovedBatchFieldDefinitionsWithData(state)

  return {
    editing: state.get('editing'),
    batchFieldDefinitions: state.getIn(['current', 'batchFieldDefinitions']),
    errorMessages: state.get('errorMessages'),
    removedBatchFieldDefinitionsWithData,
    usingRegistrationSystem: state.get('usingRegistrationSystem'),
    batchFieldDefinitionsLocked: state.get('batchFieldDefinitionsLocked'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    edit: editable.actions.edit,
  }, dispatch)
}

const BatchFieldDefinitionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchFieldDefinitions)

export default BatchFieldDefinitionsContainer
