import React from 'react'

import { A } from '@/shared/components/sanitizedTags.js'
import Icon from '@/shared/components/Icon.jsx'

import { SortableRows } from '@/shared/components/FieldDefinitionsTable'
import PreGeneratedRow from './SortableRows/PreGeneratedRow.jsx'
import Row from './SortableRows/Row.jsx'
import RowPreview from './SortableRows/RowPreview.jsx'
import SubmitOrCancel from '@/shared/components/SubmitOrCancel.jsx'
import { Terminology } from 'javascripts/cross_app_utilities.js'

export default class EditDataTable extends React.Component {
  constructor(props) {
    super(props)

    this.save = this._save.bind(this)
  }

  _save() {
    const message = `Removing ${Terminology.dictionary.protocol} fields will delete existing data`

    if (this.props.removedProtocolFieldDefinitionsWithData.size > 0 && !confirm(message)) {
      return
    }

    this.props.save()
  }

  render() {
    const {
      protocolFieldDefinitions,
      saving,
      add,
      update,
      remove,
      splice,
      cancelEdit,

    } = this.props
    const protocolStr = Terminology.dictionary.protocol
    const byId = protocolFieldDefinitions.get('byId')
    const all = protocolFieldDefinitions.get('all')

    let autoGeneratedRows
    autoGeneratedRows = [
      <PreGeneratedRow
        id="protocol-name"
        key="protocol-name"
        name={`Name`}
        requiredText={`is required`}
      />,
    ]

    const rows = all.toArray().map((id, index) => {
      const protocolFieldDefinition = byId.get(id.toString())

      return <Row
        id={id}
        index={index}
        key={id}
        protocolFieldDefinition={protocolFieldDefinition}
        update={update}
        remove={remove}
        onSortEnd={splice}
      />
    })

    return (
      <form>
        <div id="protocolFieldDefinitions-edit" className="fakeDataTable">
          <div className="row greyBackground">
            <div className="inner name">Name</div>
            <div className="inner data-type">Data Type</div>
            <div className="inner requirement">This Field</div>
          </div>
          {autoGeneratedRows}
          <SortableRows
            RowPreviewClass={RowPreview}
            rowPreviewProps={id => ({ protocolFieldDefinition: byId.get(id.toString()) })}
          >
            {rows}
          </SortableRows>
          <div className="row fake_data_table__add_row">
            <div className="inner">
              <A id="add-protocol-field-definition" onClick={add} href="#">
                <Icon icon="add" forceSize="16" />
                <span>Add a {protocolStr} field</span>
              </A>
            </div>
          </div>
          <SubmitOrCancel
            disabled={saving}
            green={true}
            onSubmit={this.save}
            onCancel={cancelEdit}
          >
            Update {protocolStr} fields
          </SubmitOrCancel>
        </div>
      </form>
    )
  }
}
