import { List, Map } from 'immutable'

export function getFieldDefinitionById(fieldDefinitionKey, state, id) {
  return state.getIn(['current', fieldDefinitionKey, 'byId', id.toString()])
}

export function getRequiredGroupNames(fieldDefinitionKey, state, id, requiredGroupNumber) {
  if (requiredGroupNumber) {
    return state.getIn(['current', fieldDefinitionKey, 'all'])
      .map(id => getFieldDefinitionById(fieldDefinitionKey, state, id))
      .filter(fd => {
        return fd.get('_destroy') !== 1 &&
          fd.get('required_group_number') === requiredGroupNumber &&
          fd.get('id') !== id
      })
      .map(fd => fd.get('name'))
  } else {
    return List()
  }
}

export function getGroupsByNumber(fieldDefinitionKey, state) {
  return state.getIn(['current', fieldDefinitionKey, 'all'])
    .map(id => getFieldDefinitionById(fieldDefinitionKey, state, id))
    .filter(fd => { return fd.get('_destroy') !== 1 })
    .reduce((memo, fd) => {
      const groupNumber = (fd.get('required_group_number') || fd.get('temp_group_number')).toString()

      if (!memo.get(groupNumber)) {
        memo = memo.set(groupNumber, List())
      }

      return memo.update(groupNumber, fds => fds.push(fd))
    }, Map())
}

export function getRemovedFieldDefinitionsWithData(fieldDefinitionKey, fieldCountKey, state) {
  return state.getIn(['current', fieldDefinitionKey, 'byId'])
    .filter(fd => {
      return fd.get('_destroy') === 1 && fd.get(fieldCountKey) > 0
    })
}

export function getPickListValues(state, id) {
  const ids = state.getIn(['current', 'pickListValues', 'current', 'byDefinitionId', id.toString()]) || List()

  return ids.map(id => {
    return state.getIn(['current', 'pickListValues', 'current', 'byId', id.toString()])
  }).filter(value => { return value.get('_destroy') !== 1 })
}

export function isEditingPickList(state, definitionId) {
  const editingDefinitionId = state.getIn(['current', 'pickListValues', 'current', 'editingDefinitionId'])
  return !!(editingDefinitionId && editingDefinitionId === definitionId)
}

export function getInvalidIds(state) {
  // Scott: I've found that if you hit escape while when editing a pick list, an exception is thrown and the screen goes
  // blank. This isn't the ideal fix, but we'll probably redo it eventually and this is good enough for now. An
  // improvement, anyway - and should be safe enough. ;)
  try {
    return state.getIn(['current', 'pickListValues', 'current', 'byValue'])
      .reduce((memo, ids) => {
        if (ids.size > 1) {
          return memo.concat(ids)
        } else {
          return memo
        }
      }, List())
  } catch (e) {
    return []
  }
}

export function getNotification(state) {
  return state.getIn(['current', 'pickListValues', 'current', 'notification'])
}

export function getInput(state) {
  return state.getIn(['current', 'pickListValues', 'current', 'input'])
}
