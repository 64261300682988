import { FieldDefinition } from '@/FieldDefinitions/types';
import { InventoryEvent, Sample } from '@/Samples/types';
import { ColumnDef, SimpleDataTable } from '@/components';
import MultipleTablesWithStickyHeaders from '@/components/MultipleTablesWithStickyHeaders/MultipleTablesWithStickyHeaders';
import { DDFormUtils } from '@/shared/components/DDForm/DDFormUtils';
import { Img } from '@/shared/components/sanitizedTags';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { StringOrNumber } from '@/types';
import addIcon from 'ASSETS/images/cdd30/icons/add.png';
import editIcon from 'ASSETS/images/cdd30/icons/pencil.png';
import { observer } from 'mobx-react';
import React from 'react';
import { currentAmountForSample } from '../stores/sampleDataStore';
import { DepleteButton, ToolbarIconButton } from './DepleteButton';
import { formatDateAndName, modifiedAtFormatter } from './formatDateAndName';

type Props = {
  vaultId: number;
  moleculeId: number;
  columns: Array<ColumnDef>;
  singleUseColumns: Array<ColumnDef>;
  samples: Array<Sample>;
  tableData: Array<{
    group: Sample;
    rows: Array<InventoryEvent | Sample>;
  }>;

  editSampleTooltip: string;
  addEventVisible: boolean;
  addEventDisabled: boolean;
  sampleFieldDefinitions: FieldDefinition[];
  eventFieldDefinitions: FieldDefinition[];
  editSample(sample: Sample): void;
  addSampleEvent: (sample: Sample) => void;
  onClickRow: (event: InventoryEvent) => void;
  onToggleSampleDepleted: (sample: Sample) => void;
};

@observer
export default class InventorySampleTables extends React.Component<Props> {
  renderCell = (columnId: StringOrNumber, eventOrSample: InventoryEvent | Sample) => {
    const sample = (eventOrSample as Sample).inventory_events ? eventOrSample as Sample : null;
    const event = sample?.inventory_events?.[0] ?? (eventOrSample as InventoryEvent);
    const cellValue = DDFormUtils.getValue('' + columnId, eventOrSample);

    const handleClickDeplete = (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.props.onToggleSampleDepleted(eventOrSample as Sample);
    };

    switch (columnId) {
      case 'deplete':
        return <DepleteButton
          onClick={handleClickDeplete}
          depleted={(eventOrSample as Sample).depleted}
        />;

      case 'current_amount': {
        const CurrentAmount = sample?.current_amount ?? 0;
        return `${CurrentAmount} (${sample.units})`;
      }

      case 'location': {
        if (sample?.is_single_use && !sample?.depleted) {
          return sample?.location?.value ?? '';
        }
        return event.inventory_event_fields_name_keyed?.Location?.value ?? '';
      }

      case 'debit_credit': {
        const Credit = event.inventory_event_fields_name_keyed?.Credit;
        const Debit = event.inventory_event_fields_name_keyed?.Debit;
        if (Credit > 0) {
          return <span className='credit'>+ {Credit}</span>;
        }
        return <span className='debit'>- {Debit}</span>;
      }

      case 'created_at': {
        if (sample?.is_single_use) {
          return formatDateAndName(sample.created_at, sample.created_by_user_full_name);
        }
        return formatDateAndName(event.created_at, event.created_by_user_full_name);
      }

      case 'modified_at': {
        if (sample?.is_single_use) {
          return modifiedAtFormatter(
            sample.modified_by_user_full_name,
            sample.modified_at,
            sample.created_at,
          );
        }
        return event.modified_by_user_full_name
          ? formatDateAndName(event.modified_at, event.modified_by_user_full_name)
          : '';
      }
    }

    if (cellValue && typeof cellValue === 'object') {
      // special case for file uploads
      const { file_name, value } = cellValue;
      return file_name ?? value;
    }

    return cellValue;
  };

  renderAdditionalHeaderElements = (sample: Sample) => {
    if (sample.is_single_use) {
      return <i>Single use samples</i>;
    }
    const addEventVisible = this.props.addEventVisible;
    const addEventDisabled = this.props.addEventDisabled || sample.depleted;
    const addEventTooltip = this.props.editSampleTooltip ??
      (sample.depleted ? 'New events cannot be added to depleted samples' : undefined) ??
      'Create a new event';

    const handleClickCreateEvent = () => {
      if (!addEventDisabled) {
        this.props.addSampleEvent(sample);
      }
    };

    const editSampleVisible = !sample.hide_sample_edit_links;
    const editSampleDisabled = !sample.can_modify || sample.depleted;
    const editSampleTooltip = this.props.editSampleTooltip ??
      (sample.depleted ? 'Depleted sample cannot be edited' : undefined) ??
      'Edit sample';

    const handleClickEditSample = () => {
      if (editSampleVisible && !editSampleDisabled) {
        this.props.editSample(sample);
      }
    };

    const depleteSampleVisible = !sample.hide_sample_edit_links;

    const handleClickDepleteSample = () => {
      this.props.onToggleSampleDepleted(sample);
    };

    const currentAmount = currentAmountForSample(sample) + (sample.units ? ` (${sample.units})` : '');
    const currentLocation = sample?.location?.value;

    return <div className='additionalHeaderElements'>
      <span className='left'>
        <span className='label-text'>
          Current Amount:
        </span>
        <span className='value-text right-margin'>
          {currentAmount}
        </span>
        {currentLocation && <><span className='label-text'>
          Location:
        </span>
          <span className='value-text right-margin'>
            {currentLocation}
          </span>
        </>}
      </span>

      <span className='right'>
        {depleteSampleVisible &&
          <DepleteButton
            onClick={handleClickDepleteSample}
            depleted={sample.depleted}
          />
        }

        {editSampleVisible &&
          <ToolbarIconButton
            onClick={handleClickEditSample}
            img={<Img
              width={16}
              height={16}
              className='icon-16'
              alt='Edit sample'
              src={editIcon}
            />}
            tooltip={editSampleTooltip}
            ariaLabel='Edit sample'
            disabled={editSampleDisabled}
          />}

        {addEventVisible &&
          <ToolbarIconButton
            onClick={handleClickCreateEvent}
            img={<Img
              width={16}
              height={16}
              className='icon-16'
              alt='Create a new event'
              src={addIcon}
            />}
            tooltip={addEventTooltip}
            ariaLabel={'Create a new event'}
            disabled={addEventDisabled}
          />
        }
      </span>
    </div>;
  };

  renderAdditionalHeaderRow = (sample: Sample) => {
    if (sample.is_single_use) {
      return null;
    }

    // convert props.sampleFieldDefinitions to a map of field ids to names
    const sampleFieldDefinitions = this.props.sampleFieldDefinitions.reduce((map, field) => {
      map[field.id] = field.name;
      return map;
    }, {} as Record<number, string>);

    return <div className='additionalHeaderElements width__full'>

      <span className='parent width__full'>
        <span className='left-container'>
          {
            keysAsNumbers(sample.inventory_sample_fields ?? {}).map((key) => {
              let value = sample.inventory_sample_fields[key] ?? '';
              if (typeof value === 'object' && value !== null) {
                value = value.file_name ?? value;
              }
              return <span key={key} className='whitespace__nowrap'>
                <span className='label-text'>
                  {sampleFieldDefinitions[key]}:
                </span>
                <span className='value-text right-margin'>
                  {value.toString()}
                </span>
              </span>;
            })
          }
        </span>
      </span>
    </div>;
  };

  renderCustomHeaderCell = (column: ColumnDef, sample: Sample) => {
    if (column.id === 'debit_credit') {
      return sample.units ? `${column.label} (${sample.units})` : column.label;
    }
  };

  getOverridePropsForGroup = (sample: Sample) => {
    const result = {} as Partial<React.ComponentProps<typeof SimpleDataTable>>;

    if (sample.is_single_use) {
      result.columns = this.props.singleUseColumns;
    } else {
      result.rows = sample.inventory_events;
    }

    if (sample.hide_sample_edit_links || !sample.can_modify) {
      result.className = 'no-pointer-events';
    }

    if (sample.depleted) {
      result.className = (result.className ?? '') + ' depleted';
    }

    if (sample.is_single_use) {
      result.getRowClassName = (row: Sample) => {
        return row.depleted ? 'single-use-depleted' : '';
      };
    }

    return result;
  };

  render() {
    const { tableData } = this.props;

    return <>
      <MultipleTablesWithStickyHeaders
        {...this.props}
        collapsedHeight={60}
        isSortable={false}
        tables={tableData}
        renderCell={this.renderCell}
        noDataMessage={'No events found'}
        renderAdditionalHeaderElements={(sample: Sample) => this.renderAdditionalHeaderElements(sample)}
        renderAdditionalHeaderRow={(sample: Sample) => this.renderAdditionalHeaderRow(sample)}
        renderCustomHeaderCell={this.renderCustomHeaderCell}
        getOverridePropsForGroup={this.getOverridePropsForGroup}
      />

      {/* Hacky way of forcing refresh when depleted changes */}
      <span className='hidden'>{
        tableData.map((data) => data.group.depleted)
      }</span>
    </>;
  }
}
