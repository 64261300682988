import React from 'react'

import Icon from '@/shared/components/Icon.jsx'
import { NameField, DataTypeSelect, UniqueCheckbox, Actions }
  from '@/shared/components/FieldDefinitionsTable'

import PickListEditor from './PickListEditor'
import RequiredGroupSelect from './RequiredGroupSelect'

export default function BaseRow(props) {
  const {
    dragHandle,
    elementId,
    moleculeFieldDefinition,
    handleChange,
    readOnly,
    remove,
  } = props
  const {
    id,
    name,
    data_type_name,
    unique_value,
    overwritable,
    required_group_number,
    temp_group_number,
    molecule_fields_count,
    disabled,
  } = moleculeFieldDefinition.toJS()
  const dataTypeDisabled = molecule_fields_count > 0

  const commonProps = {
    onChange: handleChange,
    readOnly: readOnly,
    disabled: disabled,
  }

  return (
    <div className="row" id={elementId}>
      <div className="inner name">
        <NameField
          value={name}
          {...commonProps}
        />
      </div>
      <div className="inner data-type">
        <DataTypeSelect
          options={['Text', 'Number', 'Date', 'PickList', 'File']}
          value={data_type_name}
          {...commonProps}
          disabled={disabled || dataTypeDisabled}
        />
        <PickListEditor
          id={id}
          dataTypeName={data_type_name}
          {...commonProps}
        />
      </div>
      <div className="inner centered unique">
        <UniqueCheckbox
          {...commonProps}
          checked={unique_value}
          disabled={disabled}
          dataTypeName={data_type_name}
          name="unique_value"
        />
      </div>
      <div className="inner centered overwritable">
        <UniqueCheckbox
          {...commonProps}
          checked={overwritable}
          disabled={disabled}
          name="overwritable"
        />
      </div>
      <div className="inner requirement">
        <RequiredGroupSelect
          id={id}
          requiredGroupNumber={required_group_number}
          tempGroupNumber={temp_group_number}
          {...commonProps}
        />
      </div>
      <div className="inner actions">
        <Actions
          onClick={() => remove(id)}
          disabledTitle="Cannot remove inventory fields. Contact support."
          {...commonProps}
        />
        {dragHandle(
          <span>
            <Icon icon="drag_handle" />
          </span>
        )}
      </div>
    </div>
  )
}
