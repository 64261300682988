import { Map, List } from 'immutable'

import actionsGenerator from './actions.js'
import storeGenerator from './store.js'
import editable from '../editable.js'

import { normalizedValue } from '@/shared/utils/models.js'

const messages = {
  duplicates: () => 'There are duplicate items in the pick list that require correction',
  present: () => 'This item is already present in the pick list',
  removed: value => `Removed ${value}`,
  addedMany: (count, failureCount) => {
    const itemCountStr = `${count} item${count == 1 ? '' : 's'}`
    const failureCountStr = `${failureCount} item${failureCount == 1 ? '' : 's'}`
    return `Added ${itemCountStr} [failed to add ${failureCountStr}]`
  },
}

const module = ({ namespace = '', objectId = '', persistable } = {}) => {
  const store = storeGenerator({ objectId })
  const _actions = actionsGenerator(namespace)

  const editablePickListValues = editable(`${namespace}/pickListValues`)
  // overwrite editable.edit so we can add a definition id
  editablePickListValues.actions.edit = (definitionId: number) => {
    return {
      type: editablePickListValues.actionTypes.EDIT,
      definitionId,
    }
  }

  function addPickListValue(state, value, normValue) {
    const item = store.pickListValue(state, value)
    const id = item.get('id')

    return state.setIn(['byId', id], item)
      .updateIn(['byValue', normValue], (ids = List()) => ids.push(id))
      .updateIn(['byDefinitionId', item.get(objectId).toString()], (ids = List()) => ids.push(id))
      .set('input', '')
      .set('notification', null)
  }

  function _reducer(state: Object = Map(), action: Object) {
    switch (action.type) {
      case editablePickListValues.actionTypes.EDIT: {
        const byDefinitionId = state.getIn(['byDefinitionId', action.definitionId.toString()]) || List()
        const byValue = byDefinitionId
          .filter(id => !state.getIn(['byId', id.toString(), '_destroy']))
          .reduce((memo, id) => {
            const value = normalizedValue(state.getIn(['byId', id.toString(), 'value']))

            return memo.update(value, ids => {
              return ids ? ids.push(id) : List([id])
            })
          }, Map())

        return state.set('editingDefinitionId', action.definitionId)
          .set('byValue', byValue)
      }
      case editablePickListValues.actionTypes.CANCEL:
      case editablePickListValues.actionTypes.SUBMIT:
        return state.merge({
          byValue: Map(),
          editingDefinitionId: null,
          input: '',
          notification: null,
        })
      case _actions.actionTypes.UPDATE_INPUT: {
        return state.merge({
          input: action.input,
          notification: null,
        })
      }
      case _actions.actionTypes.ADD: {
        const normValue = normalizedValue(action.value)
        const value = action.value.trim()

        if (value === '') {
          return state
        } else if (state.getIn(['byValue', normValue])) {
          return state.set('notification', messages.present())
        } else {
          return addPickListValue(state, value, normValue)
        }
      }
      case _actions.actionTypes.ADD_MANY: {
        const values = action.values.map(value => value.trim())
        let reducedState = state
        let count = 0

        values.forEach(value => {
          const normValue = normalizedValue(value)

          if (normValue !== '' && !reducedState.getIn(['byValue', normValue])) {
            reducedState = addPickListValue(reducedState, value, normValue)
            count++
          }
        })

        return reducedState.set('notification', messages.addedMany(count, values.length - count))
      }
      case _actions.actionTypes.UPDATE: {
        const id = action.id

        if (action.updatedValues.value !== undefined) {
          const oldValue = normalizedValue(state.getIn(['byId', id.toString(), 'value']))
          const value = normalizedValue(action.updatedValues.value)
          const reducedState = state.update('byValue', byValue => {
            const ids = byValue.get(oldValue).filter(_id => _id !== id)
            return ids.size ? byValue.set(oldValue, ids) : byValue.delete(oldValue)
          })
            .updateIn(['byValue', value], ids => {
              return ids ? ids.push(id) : List([id])
            })
          const duplicates = reducedState.get('byValue')
            .reduce((memo, ids) => {
              return ids.size > 1 ? memo.concat(ids) : memo
            }, List())

          const message = duplicates.size ? messages.duplicates() : null

          return reducedState.mergeDeep({
            byId: { [id.toString()]: action.updatedValues },
            notification: message,
          })
        } else {
          return state.mergeDeepIn(['byId', id.toString()], action.updatedValues)
        }
      }
      case _actions.actionTypes.REMOVE: {
        const value = state.getIn(['byId', action.id.toString(), 'value'])
        const normValue = normalizedValue(value)

        return state.mergeDeep({
          byId: { [action.id]: { '_destroy': 1 } },
          notification: messages.removed(value),
        })
          .update('byValue', byValue => {
            const ids = byValue.get(normValue).filter(id => { return id !== action.id })
            return ids.size ? byValue.set(normValue, ids) : byValue.delete(normValue)
          })
      }
      case persistable.actionTypes.SUCCEED: {
        const filteredElnFieldDefinitions = action.response.filter(efd => efd.pick_list_values)
        return store.initialState(filteredElnFieldDefinitions)
      }
      default:
        return state
    }
  }

  const initialState = (definitions) => {
    return editablePickListValues.initialState({
      current: store.initialState(definitions),
    })
  }
  const actionTypes = Object.assign({}, _actions.actionTypes, editablePickListValues.actionTypes)
  const actions = Object.assign({}, _actions.actions, editablePickListValues.actions)
  const reducer = editablePickListValues.reducer(_reducer)

  return { initialState, actionTypes, actions, reducer }
}

export default module
