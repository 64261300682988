import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { FilterDateValue } from './FilterDateValue';
import { FilterNumberValue } from './FilterNumberValue';
import { FilterPickListValue } from './FilterPickListValue';
import { FilterTextValue } from './FilterTextValue';
import { FilterValueSelectProps } from './FilterValueSelect.types';

// TODO dedupe with eln entries stuff where possible
const componentMap = new Proxy(
  {
    PickList: FilterPickListValue,
    Date: FilterDateValue,
    Text: FilterTextValue,
    Number: FilterNumberValue,
  },
  {
    get: (map, type) => map[type] || FilterTextValue,
  },
);

export class FilterValueSelect extends React.Component<
  FilterValueSelectProps<FieldDataType>
> {
  render() {
    const {
      inventoryFieldDefinition: { data_type_name },
    } = this.props;
    const Component = componentMap[data_type_name];
    return (
      <span className='filter-value-select'>
        <Component {...this.props} />
      </span>
    );
  }
}
