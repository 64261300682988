import { TSearchQuery } from '@/Samples/types';
import { AddButton } from '@/shared/components';
import React from 'react';
import { Handlers } from '../../../types';
import { FilterItem, FilterItemProps } from './FilterItem';

// TODO dedupe with eln entries stuff where possible
export type FilterFieldsProps = {
  queryFilters: (TSearchQuery['query_filters'][number] | null)[];
  handlers: Handlers<FilterItemProps> & {
    addQueryFilter: () => void;
  };
} & Pick<FilterItemProps, 'inventoryFieldDefinitions' | 'vaultNameMap'>;

export class FilterFields extends React.Component<FilterFieldsProps> {
  render() {
    const {
      handlers: { addQueryFilter, ...filterItemHandlers },
      queryFilters,
      inventoryFieldDefinitions,
      vaultNameMap,
    } = this.props;
    return (
      <div className='search-bar__filters'>
        {queryFilters.map((filter, i) => {
          return (
            <FilterItem
              handlers={filterItemHandlers}
              selectedFieldValues={queryFilters
                .filter((val) => val !== null)
                .map((val) => val.field_select_value)}
              key={i}
              index={i}
              filter={filter}
              inventoryFieldDefinitions={inventoryFieldDefinitions}
              vaultNameMap={vaultNameMap}
            />
          );
        })}
        <div className='search-bar__filters__actions'>
          <AddButton onClick={addQueryFilter} text={'Add Filter'} />
        </div>
      </div>
    );
  }
}
