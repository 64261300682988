import React from 'react'
import Checkbox from '@/shared/components/Checkbox.jsx'
import DataType from 'models/DataType.js'

export default function Row(props) {
  const {
    id,
    name,
    data_type_name,
    unique_value,
    overwritable,
    required_group_number,
    requiredGroupNames,
  } = props
  let { requiredText } = props
  let htmlId = `batch-field-definition-${id}`

  if (!requiredText) {
    if (required_group_number) {
      if (requiredGroupNames.size > 0) {
        requiredText = `or ${requiredGroupNames.sort().join(' or ')} is required`
      } else {
        requiredText = 'is required'
      }
    } else {
      requiredText = 'is optional'
    }
  }

  return (
    <tr id={htmlId} className="row">
      <td name="name">{ name }</td>
      <td name="data_type_name">{ DataType.displayNameForDataTypeName(data_type_name) }</td>
      <td name="unique_value" className={'centered'}>
        <Checkbox checked={ unique_value } />
      </td>
      <td name="overwritable" className={'centered'}>
        <Checkbox checked={ overwritable } />
      </td>
      <td name="requirement">
        {requiredText}
      </td>
      <td></td>
    </tr>
  )
}
