import React from 'react'
import {
  FormControl,
  Select,
  MenuItem,
  Input,
} from '@mui/material'

export default class FilterPickListValue extends React.Component {
  handleChange = (event) => {
    let filterValues = event.target.value
    this.props.handleValueChange(filterValues)
  }

  render() {
    const { elnFieldDefinition, filter } = this.props
    const filterValues = filter.field_values.map((value) => (value + ''))

    return (
      <FormControl>
        <Select
          multiple
          value={filterValues}
          onChange={this.handleChange}
          input={<Input />}
          className='filter__value__select'
        >
          {elnFieldDefinition.pick_list_values.map(pickListValue => (
            <MenuItem key={pickListValue.id} value={pickListValue.id + ''} className='filter__value__select-item'>
              {pickListValue.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}
