import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';
import { SimilarStructure } from './SimilarityResultsTypes';

export enum Collection {
  ChEMBL = 'ChEMBL',
  SureChEMBL = 'SureChEMBL',
  Enamine = 'Enamine',
  CDD_Public = 'CDD Public',
}

type PatentInformation = {
  count: number;
  first_patent: string;
  first_date: string;
}

type CDD_PublicInformation = {
  molecule_id: number;
}

export type Identifier = {
  name: string;
  collection: Collection;
  patent?: PatentInformation;
  cdd_public?: CDD_PublicInformation;
}

export const allCollections = [Collection.ChEMBL, Collection.SureChEMBL, Collection.CDD_Public, Collection.Enamine];

export function getCollectionByName(collection: string): Collection {
  if (collection === 'ChEMBL') {
    return Collection.ChEMBL;
  } else if (collection === 'SureChEMBL') {
    return Collection.SureChEMBL;
  } else if (collection === 'Enamine') {
    return Collection.Enamine;
  } else if (collection === 'CDD Public') {
    return Collection.CDD_Public;
  }
  return null;
}

type LinkInformation = {
  url: string;
  label: string;
  tooltip?: string;
}

export function getLinkInformation(identifier: Identifier): LinkInformation | null {
  switch (identifier.collection) {
    case Collection.ChEMBL:
      return {
        url: `https://www.ebi.ac.uk/chembl/compound_report_card/${identifier.name}/`,
        label: `Open ChEMBL for ${identifier.name}`,
      };
    case Collection.SureChEMBL: {
      const schembl_id = identifier.name.replace('SCHEMBL', '');
      return {
        url: `https://www.surechembl.org/chemical/${schembl_id}/`,
        label: `Open SureChEMBL for ${identifier.name}`,
      };
    }
    case Collection.Enamine:
      return {
        url: `https://enaminestore.com/catalog/${identifier.name}/`,
        label: `Open the Enamine store for ${identifier.name}`,
      };
    case Collection.CDD_Public: {
      const url = window.location.href;
      return {
        url: `${url.substring(0, url.lastIndexOf('/'))}/${identifier.cdd_public.molecule_id}/`,
        label: `Open ${identifier.name}`,
      };
    }
    default:
      return null;
  }
}

export function getPatentInforrmation(identifiers: Array<Identifier>): PatentInformation | null {
  for (const identifier of identifiers) {
    if (identifier.patent) {
      return identifier.patent;
    }
  }
  return null;
}

export const CollectionInformationView = (props: {
  identifiers: Identifier[], filters: Collection[]
}) => {
  const filtered = props.identifiers.filter((identifier) => props.filters.includes(identifier.collection));
  if (filtered.length === 0) {
    return null;
  }
  const views = [];
  filtered.forEach((identifier, idx) => {
    if (identifier.collection === Collection.SureChEMBL) {
      const patent = getPatentInforrmation([identifier]);
      if (patent) {
        views.push(<span key={idx}>{patent.first_patent} ({patent.first_date})</span>);
      }
    }
  });
  return <Box>{views}</Box>;
};

type CollectionSelectionProps = {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
};

export const CollectionSelection = (props: CollectionSelectionProps) => {
  const { onChange } = props;

  const [enabled, setEnabled] = useState<boolean>(props.enabled);
  const chkbox = <Checkbox
    sx={{ pt: '0.1em', pb: '0.1em' }}
    checked={enabled}
    onChange={(e) => {
      setEnabled(e.target.checked);
      onChange(e.target.checked);
    }} />;
  return (
    <Stack direction='column' alignItems='left' sx={{ pb: '12px' }}>
      {/* <Box>Collection specific information</Box> */}
      <Divider orientation="horizontal" sx={{ pt: 0, pb: 0 }} />
      <FormGroup>
        <FormControlLabel control={chkbox} label='First patent (if available)' />
      </FormGroup>
    </Stack>
  );
};

export function getCollections(similarStructures: Array<SimilarStructure>): Set<Collection> {
  const collections: Set<Collection> = new Set();
  similarStructures.forEach((structure) => {
    structure.identifiers.forEach((identifier) => collections.add(identifier.collection));
  });
  return collections;
}

export function filterByCollections(
  similarStructures: Array<SimilarStructure>,
  filters: Array<Collection>,
): Array<SimilarStructure> {
  return similarStructures.filter((structure) => {
    return structure.identifiers.some((identifier) => filters.includes(identifier.collection));
  });
}

export function adjustFilters(collections: Set<Collection>, oldCollections: Collection[], filters: Collection[]): Collection[] {
  collections = new Set([...collections].filter((collection) => allCollections.includes(collection)));
  const newCollections = [];
  collections.forEach((collection) => {
    if (!oldCollections.includes(collection)) {
      newCollections.push(collection);
    }
  });
  const newFilters = new Set([...filters, ...newCollections]
    .filter((c) => allCollections.includes(c)));
  return [...newFilters].sort();
}
