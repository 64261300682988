import React from 'react'

import { A } from '@/shared/components/sanitizedTags.js'
import Icon from '@/shared/components/Icon.jsx'

import { SortableRows } from '@/shared/components/FieldDefinitionsTable'
import PreGeneratedRow from './SortableRows/PreGeneratedRow.jsx'
import Row from './SortableRows/Row.jsx'
import RowPreview from './SortableRows/RowPreview.jsx'
import SubmitOrCancel from '@/shared/components/SubmitOrCancel.jsx'
import { Terminology } from 'javascripts/cross_app_utilities.js'

export default class EditDataTable extends React.Component {
  constructor(props) {
    super(props)

    this.save = this._save.bind(this)
  }

  _save() {
    const message = `Removing ${Terminology.dictionary.run} fields will delete existing data`

    if (this.props.removedRunFieldDefinitionsWithData.size > 0 && !confirm(message)) {
      return
    }

    this.props.save()
  }

  render() {
    const {
      runFieldDefinitions,
      saving,
      add,
      update,
      remove,
      splice,
      cancelEdit,

    } = this.props
    const runStr = Terminology.dictionary.run
    const byId = runFieldDefinitions.get('byId')
    const all = runFieldDefinitions.get('all')

    let autoGeneratedRows
    autoGeneratedRows = [
      <PreGeneratedRow
        id="run-date"
        key="run-date"
        name={`${runStr.titleize()} Date`}
        requiredText={`is required`}
        is_display_identifier={true}
      />,
    ]

    const rows = all.toArray().map((id, index) => {
      const runFieldDefinition = byId.get(id.toString())

      return <Row
        id={id}
        index={index}
        key={id}
        runFieldDefinition={runFieldDefinition}
        update={update}
        remove={remove}
        onSortEnd={splice}
      />
    })

    return (
      <form>
        <div id="runFieldDefinitions-edit" className="fakeDataTable">
          <div className="row greyBackground">
            <div className="inner name">Name</div>
            <div className="inner data-type">Data Type</div>
            <div className="inner dropdown-display centered">Dropdown Display</div>
            <div className="inner requirement">This Field</div>
          </div>
          {autoGeneratedRows}
          <SortableRows
            RowPreviewClass={RowPreview}
            rowPreviewProps={id => ({ runFieldDefinition: byId.get(id.toString()) })}
          >
            {rows}
          </SortableRows>
          <div className="row fake_data_table__add_row">
            <div className="inner">
              <A id="add-run-field-definition" onClick={add} href="#">
                <Icon icon="add" forceSize="16" />
                <span>Add a {runStr} field</span>
              </A>
            </div>
          </div>
          <SubmitOrCancel
            disabled={saving}
            green={true}
            onSubmit={this.save}
            onCancel={cancelEdit}
          >
            Update {runStr} fields
          </SubmitOrCancel>
        </div>
      </form>
    )
  }
}
