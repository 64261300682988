import { fromJS } from 'immutable'

export default function module(namespace) {
  const EDIT = `${namespace}/editable/EDIT`
  const SUBMIT = `${namespace}/editable/SUBMIT`
  const CANCEL = `${namespace}/editable/CANCEL`

  function initialState({
    previous = [],
    current = [],
    editing = false,
  } = {}) {
    return fromJS({ previous, current, editing })
  }

  return {
    initialState,
    actionTypes: {
      EDIT,
      SUBMIT,
      CANCEL,
    },
    actions: {
      edit: () => ({ type: EDIT }),
      submit: () => ({ type: SUBMIT }),
      cancelEdit: () => ({ type: CANCEL }),
    },
    reducer: reducer => (state = initialState(), action) => {
      const previous = state.get('previous')
      const oldCurrent = state.get('current')
      const current = reducer(oldCurrent, action)

      switch (action.type) {
        case EDIT:
          return state.merge({
            previous: previous.push(oldCurrent),
            current: current,
            editing: true,
          })
        case SUBMIT:
          return state.merge({
            previous: previous.pop(),
            current: current,
            editing: false,
          })
        case CANCEL:
          return state.merge({
            previous: previous.pop(),
            current: previous.last(),
            editing: false,
          })
        default:
          return state.merge({ current })
      }
    },
  }
}
