import React from 'react'
import { Provider } from 'react-redux'

import MuiTheme from '@/shared/components/MuiTheme.jsx'

export default class AppContainer extends React.Component {
  render() {
    const { children, store } = this.props

    return (
      <MuiTheme>
        <Provider store={store}>
          {children}
        </Provider>
      </MuiTheme>
    )
  }
}
