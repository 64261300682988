import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import RequiredGroupSelect from './component.jsx'
import { getGroupsByNumber } from '@/FieldDefinitions/RunFieldDefinitions/selectors.js'
import { updateRequiredGroupNumber } from '@/FieldDefinitions/RunFieldDefinitions/actions/runFieldDefinitions.js'

function mapStateToProps(state) {
  const groupsByNumber = getGroupsByNumber(state)

  return {
    groupsByNumber,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateRequiredGroupNumber,
  }, dispatch)
}

const RequiredGroupSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequiredGroupSelect)

export default RequiredGroupSelectContainer
