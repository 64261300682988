import { FieldDataType, FieldDefinition } from '@/FieldDefinitions/types';

export class FieldDefinitionUtils {
  static toFormData(collection: Record<string, FieldDefinition[]>): FormData {
    const result = new FormData();
    collection = deepClone(collection);

    // get the object keys, sorted in the order used in existing code. Not strictly necessary but helpful
    // for comparison.
    const getObjectKeys = (obj: Record<string, unknown> | FieldDefinition) => {
      const objectKeys = Object.keys(obj);
      const sortedKeys = [
        'unique_value',
        'required_group_number',
        'name',
        'display_order',
        'overwritable',
        'temp_group_number',
        'id',
        'inventory_sample_fields_count',
        'data_type_name',
        'is_sample_id',
        'disabled'];

      return [
        ...sortedKeys.filter(k => objectKeys.includes(k)),
        ...objectKeys.filter(k => !sortedKeys.includes(k)),
      ];
    };

    const preprocessRowFields = (rows: FieldDefinition[], index: number) => {
      const row = rows[index];
      if (row.data_type_name !== FieldDataType.PickList) {
        delete row.pick_list_values;
      }
      Object.assign(row, {
        data_type_name: FieldDataType.Text,
        ...row,
        display_order: index,
      });
      (row.pick_list_values ?? []).forEach((value) => {
        if (('' + value.id).startsWith('FAKE-ID')) {
          delete value.id;
        }
      });
    };

    const appendFormData = (prefix: string, row: FieldDefinition, key: string) => {
      const value = row[key] ?? '';
      if (typeof value === 'object') {
        getObjectKeys(value).forEach(subkey => {
          appendFormData(`${prefix}[${key}]`, value, subkey);
        });
      } else {
        result.append(`${prefix}[${key}]`, value);
      }
    };

    Object.keys(collection).forEach(collectionKey => {
      const rows = collection[collectionKey];
      rows.forEach((row, index) => {
        preprocessRowFields(rows, index);
        getObjectKeys(row).forEach(key => {
          appendFormData(`vault[${collectionKey}_attributes][${index}]`, row, key);
        });
      });
    });

    return result;
  }
}
