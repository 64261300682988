import React from 'react'

export default function RequiredCheckbox(props) {
  const { onChange, checked, readOnly, uneditable } = props

  return (
    <input
      type="checkbox"
      name="required"
      onChange={onChange}
      checked={checked}
      readOnly={readOnly}
      disabled={uneditable}
    />
  )
}
