import React from 'react'
import { createRoot } from 'react-dom/client'

import AppContainer from '@/shared/components/AppContainer.jsx'
import camelCaseKeys from '@/shared/utils/camelCaseKeys.js'
import ProtocolFieldDefinitionsContainer from './container.js'
import configureStore from './reducers'

type Props = {
  protocolFieldDefinitions: {
    protocol_field_definition: {
      id: number,
      name: string,
      display_order: number,
      data_type_name: string,
      unique_value: boolean,
      required_group_number?: number,
      overwritable: boolean,
      protocol_fields_count: number,
      disabled: boolean,
      overwrite_disabled: boolean,
      pick_list_values: any[]
    }
  }[],
  vaultProtocolFieldDefinitionsUrl: string,
  usingRegistrationSystem: boolean,
  protocolFieldDefinitionsLocked: boolean
};

export default function ProtocolFieldDefinitions(props: Props) {
  const rootElement = document.getElementById('protocolFieldDefinitions')
  props = camelCaseKeys(props)
  const store = configureStore(camelCaseKeys(props))

  const root = createRoot(rootElement)
  const renderApp = () => {
    root.render(
      <AppContainer store={store}>
        <ProtocolFieldDefinitionsContainer />
      </AppContainer>
      ,
      rootElement
    )
  }

  renderApp()
}
