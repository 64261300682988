import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DownloadIcon from '@mui/icons-material/Download';
import { CenterContent } from '../layout';

export const BioisosterInfoNoFragments = () => {
  const message = (
    <>
      <h2>No fragments found</h2>
      <p>
        The fragmentation method, which is based on pre-defined retrosynthetic rules (BRICS, Degen et al. 2008),
        cannot identify fragments.
      </p>
    </>);
  return CenterContent(message);
};

export const BioisosterInfoDialog = (
  props: {
    open: boolean;
    onClose: () => void;
  },
) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle className='muiDialog-title'>Bioisosteric suggestions</DialogTitle>
      <DialogContent>
        <Typography variant="h5">Method</Typography>
        <p>
          Using pre-defined retrosynthetic rules (BRICS, Degen et al. 2008), the reference structure is broken down into fragments.
          A numerical representation of these fragments is then derived using the CDD Deep Learning model. By comparing the numerical
          representations with a large libraries of suitable replacement fragments found in ChEMBL, the model suggests bioisosteric
          replacements.
        </p>
        <p>
          Each fragment is highlighted in the original reference structure and the suggested replacements shown in the table next to it.
          Click on a suggested structure to select it. Build up a list of favorites to be included in the downloaded file.
        </p>
        <Typography variant="h5">Settings</Typography>
        <p>
          Open the settings dialog by clicking this icon <SettingsIcon sx={{ fontSize: '1rem' }} />:
          <ul>
            <li>Maximum number of hits per fragment</li>
            <li>Control display of additional information (e.g. first patent for structure)</li>
            <li>
              Select the properties to be shown for each suggestion.
              The following color coding is used: properties will be <Box component="span" sx={{ color: '#DC143C' }}>red</Box> if they are higher
              than the target, <Box component="span" sx={{ color: '#133bdc' }}>blue</Box> if lower or black if the same.
            </li>
          </ul>
        </p>
        <Typography variant="h5">Download</Typography>
        <p>
          Click on the download icon <DownloadIcon sx={{ fontSize: '1rem' }} /> to export the suggestions and their properties in a CSV file.
          The file will contain the original reference structure, the selected suggestions and their properties. You can use this file to
          further analyze the suggestions in CDD Visualization or import into an ideas Vault.
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog >
  );
};
