import { connect } from 'react-redux'

import DataTable from './component.jsx'

function mapStateToProps(state) {
  return {
    elnFieldDefinitions: state.getIn(['current', 'elnFieldDefinitions']),
  }
}

const DataTableContainer = connect(
  mapStateToProps
)(DataTable)

export default DataTableContainer
