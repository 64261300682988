import { combineReducers } from 'redux-immutable'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import batchFieldDefinitions, { batchFieldDefinitionsInitialState }
  from './batchFieldDefinitions.js'
import pickListValues from '@/FieldDefinitions/BatchFieldDefinitions/modules/pickListValues.js'
import editable from '@/FieldDefinitions/BatchFieldDefinitions/modules/editable.js'
import persistable from '@/FieldDefinitions/BatchFieldDefinitions/modules/persistable.js'
import reactions from '../middleware/reactions.js'
import reinitAfterSaveOrCancel from './reinitAfterSaveOrCancel.js'
import composeWithDevTools from '@/shared/utils/composeWithDevTools.js'

const reducer = reinitAfterSaveOrCancel(
  persistable.reducer(
    editable.reducer(
      combineReducers({
        batchFieldDefinitions,
        pickListValues: pickListValues.reducer,
      })
    )
  )
)

export function initialState(batchFieldDefinitions) {
  const parsedBatchFieldDefinitions = batchFieldDefinitions.map(obj => obj.batch_field_definition)

  return persistable.initialState().toMap()
    .merge(
      editable.initialState({
        current: {
          batchFieldDefinitions: batchFieldDefinitionsInitialState(batchFieldDefinitions),
          pickListValues: pickListValues.initialState(parsedBatchFieldDefinitions),
        },
      })
    )
}

export default function configureStore({
  batchFieldDefinitions,
  vaultBatchFieldDefinitionsUrl,
  usingRegistrationSystem,
  batchFieldDefinitionsLocked,
} = {}) {
  const _initialState = initialState(batchFieldDefinitions)
    .merge({
      vaultBatchFieldDefinitionsUrl,
      usingRegistrationSystem,
      batchFieldDefinitionsLocked,
    })

  return createStore(
    reducer,
    _initialState,
    composeWithDevTools(
      applyMiddleware(
        reactions,
        thunk
      )
    )
  )
}
