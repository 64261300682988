import { Map, fromJS } from 'immutable'

import { getUniqueId } from '@/shared/utils/models.js'

function strCompare(a, b) {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

const store = ({ objectId = '' }) => {
  function initialState(definitions: Object[]) {
    const byDefinitionId = {}
    const byId = {}
    const byValue = {}
    const editingDefinitionId = null
    const notification = null
    const input = ''

    definitions.forEach(definition => {
      byDefinitionId[definition.id] = definition.pick_list_values
        .sort((a, b) => strCompare(a.value.toLowerCase(), b.value.toLowerCase()))
        .map(plv => plv.id)
      definition.pick_list_values.forEach(plv => { byId[plv.id] = plv })
    })

    return fromJS({
      byDefinitionId,
      byId,
      byValue,
      editingDefinitionId,
      notification,
      input,
    })
  }

  function pickListValue(state, value) {
    const id = getUniqueId(state)
    const trimmedValue = value.trim()
    const editingDefinitionId = state.get('editingDefinitionId')

    return Map({
      id,
      value: trimmedValue,
      [objectId]: editingDefinitionId,
      deletable: true,
    })
  }

  return { initialState, pickListValue }
}

export default store
