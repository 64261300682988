import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DownloadIcon from '@mui/icons-material/Download';

export const SimilarityInfoDialog = (
  props: {
    open: boolean;
    onClose: () => void;
  },
) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle className='muiDialog-title'>Deep-Learning Similarity</DialogTitle>
      <DialogContent>
        <Typography variant="h5">Method</Typography>
        <p>
          The similarity search is using the CDD deep-learning model
          which was trained on a large dataset of chemical structures.
          Each structure is represented by a numerical vector that
          captures the structural characteristics of the molecule.
        </p>
        <p>
          The search retrieves the most similar structures for each of
          the data collections in the database and combines them into a
          single list. There may be an overlap in the hit lists for the
          different collections, so the number of returned hits may be
          less than the requested number.
        </p>
        <p>
          The similarity between the query structure and the structures
          in the database is calculated as the distance between the vectors.
          This distance is then converted into a similarity score that is 1
          for identical structures and gets smaller as  structures become
          more different. We aimed to map the distance to a similarity in
          a way that matches the experience from standard fingerprint-based
          similarity searches.
        </p>
        <Typography variant="h5">Similarity settings</Typography>
        <p>
          Open the settings dialog by clicking this icon <SettingsIcon sx={{ fontSize: '1rem' }} />:
          <ul>
            <li>Set the number of similar structures for each data collection</li>
            <li>Control display of additional information (e.g. first patent for structure)</li>
            <li>
              Select the properties to be shown for each hit.
              The following color coding is used: properties will
              be <Box component="span" sx={{ color: '#DC143C' }}>red</Box> if they are higher than the
              reference structure, <Box component="span" sx={{ color: '#133bdc' }}>blue</Box> if lower
              or black if the same.
            </li>
          </ul>
        </p>
        <Typography variant="h5">Download</Typography>
        <p>
          Click on the download icon <DownloadIcon sx={{ fontSize: '1rem' }} /> to export
          the hits and their properties in a CSV file. The file will
          contain the hits and their properties. You can use this file to further
          analyze the suggestions in CDD Visualization or import into a Vault.
        </p>
        <Typography variant="h5">Collections</Typography>
        <p>
          Note that the SureChEMBL collection contains only structures exemplified in patents. It does not
          include Markush structures or structures described in R-group tables.</p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog >
  );
};
