import React from 'react'
import { render } from 'react-dom'

import AppContainer from '@/shared/components/AppContainer.jsx'
import camelCaseKeys from '@/shared/utils/camelCaseKeys.js'
import RunFieldDefinitionsContainer from './container.js'
import configureStore from './reducers'

type Props = {
  runFieldDefinitions: {
    run_field_definition: {
      id: number,
      name: string,
      display_order: number,
      data_type_name: string,
      unique_value: boolean,
      required_group_number?: number,
      overwritable: boolean,
      is_display_identifier: boolean,
      run_fields_count: number,
      disabled: boolean,
      overwrite_disabled: boolean,
      dropdown_display_disabled: boolean,
      pick_list_values: any[]
    }
  }[],
  vaultRunFieldDefinitionsUrl: string,
  usingRegistrationSystem: boolean,
  runFieldDefinitionsLocked: boolean
};

export default function RunFieldDefinitions(props: Props) {
  const rootElement = document.getElementById('runFieldDefinitions')
  props = camelCaseKeys(props)
  const store = configureStore(camelCaseKeys(props))

  const renderApp = () => {
    render(
      <AppContainer store={store}>
        <RunFieldDefinitionsContainer />
      </AppContainer>
      ,
      rootElement
    )
  }

  renderApp()
}
