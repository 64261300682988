// All the Pickers are copies that should be refactored/replaced when we update material.
import React from 'react';

import {
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { StyleRules, Styles, withStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import axios from 'axios';
import AsyncSelect from 'react-select/lib/Async';

import RailsHiddenFields from '@/shared/components/RailsHiddenFields.jsx';
import { Terminology } from 'javascripts/cross_app_utilities';

const themeSpacingUnit = 8; // theme.spacing.unit

// Style stuff to make the display work with material-ui
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles: Styles<Theme, StyleRules> = _theme => ({
  parentComponent: {
    width: 410,
  },
  input: {
    display: 'flex !important',
    padding: '0 !important',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding: 0,
  },
  noOptionsMessage: {
    padding: `${themeSpacingUnit}px ${themeSpacingUnit * 2}px`,
  },
  singleValue: {
    fontSize: '0.703rem',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: '0.703rem',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: themeSpacingUnit,
    left: 0,
    right: 0,
  },
});

// Functions used to display material-ui inside react-select

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
NoOptionsMessage.displayName = 'MoleculeBatchIdentifierPicker.NoOptionsMessage';

// Use a dummy <div> component to give our TextField no built-in behavior
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const inputComponent = React.forwardRef((props, _ref) => {
  return (<div {...props} />);
});
inputComponent.displayName = 'MoleculeBatchIdentifierPicker.inputComponent';

function Control(props) {
  return (
    <TextField
      disabled={props.isDisabled}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}
Control.displayName = 'MoleculeBatchIdentifierPicker.Control';

function Option(props) {
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}
Option.displayName = 'MoleculeBatchIdentifierPicker.Option';

function Placeholder(props) {
  const textColor = props.isDisabled ? 'inherit' : 'textSecondary';
  return (
    <Typography
      color={textColor}
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
Placeholder.displayName = 'MoleculeBatchIdentifierPicker.Placeholder';

function SingleValue(props) {
  const { data } = props;
  const textColor = props.isDisabled ? 'inherit' : 'textSecondary';
  const content = data?.label;
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      color={textColor}
      {...props.innerProps}
    >
      {content}
    </Typography>
  );
}
SingleValue.displayName = 'MoleculeBatchIdentifierPicker.SingleValue';

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}
ValueContainer.displayName = 'MoleculeBatchIdentifierPicker.ValueContainer';

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}
Menu.displayName = 'MoleculeBatchIdentifierPicker.Menu';

// The list of components that are customized for display (material-ui inside react-select)
const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

type BatchFieldData = {
    id: number | null;
    batch_id: number | null;
    batch_field_definition_id: number | null;
    float_value: null;
    text_value: string | null;
    date_value: null;
    uploaded_file_id: null;
    pick_list_value_id: null;
    batch_link_id: number | null;
}

export type SelectedBatchOption = {
    value: number | string;
    label: string;
} | null;

type Props = {
  classes: { [className: string]: string };
  context: string;
  defaultValue: BatchFieldData;
  disabled?: boolean;
  name: string;
  onChange: (option: SelectedBatchOption) => void | null;
  placeholder: string;
  theme: Theme;
}

type State = {
  selectedOption: SelectedBatchOption;
}

class MoleculeBatchIdentifierPicker extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: {
        label: this.props.defaultValue?.text_value ?? '',
        value: this.props.defaultValue?.batch_link_id ?? '',
      },
    };
  }

  // Fetch for any number (normal) or there are at least 2 characters (non-reg)
  shouldFetch = (input) => {
    return /\d/.test(input) || input.length >= 2;
  };

  // Called from UI
  getPromise = (input) => {
    if (!this.shouldFetch(input)) {
      return Promise.resolve([]);
    }
    return axios.get(
      `${this.props.context}/specified_batches/search.json`,
      { responseType: 'json', params: { molecule_batch_identifier: input } },
    ).then(response => {
      return response.data.map((batch) => ({
        label: batch.molecule_batch_identifier,
        value: batch.id,
      }));
    });
  };

  setOption = (option) => {
    const { onChange: onChangeCallback } = this.props;
    onChangeCallback?.(option);
    this.setState({ selectedOption: option });
  };

  noOptionsMessage = ({ inputValue }) => {
    if (!this.shouldFetch(inputValue)) {
      return 'Paste the ' + Terminology.dictionary.molecule + ' ' + Terminology.dictionary.batch + ' identifier or search using the ID';
    } else {
      return 'None found.';
    }
  };

  render() {
    const { classes, theme } = this.props;
    const { selectedOption } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    return (
      <div className={classes.parentComponent}>
        <AsyncSelect
          fullWidth
          isClearable
          className='molecule-batch-identifier'
          classes={classes}
          components={components}
          defaultValue={selectedOption}
          isDisabled={this.props.disabled}
          loadOptions={this.getPromise}
          name={this.props.name}
          noOptionsMessage={this.noOptionsMessage}
          onChange={this.setOption}
          styles={selectStyles}
        />
        <RailsHiddenFields name={this.props.name} value={selectedOption?.value} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MoleculeBatchIdentifierPicker);
