import React from 'react'

import EditLink from '@/shared/components/EditLink.jsx'
import Errors from '@/shared/components/Errors.jsx'

export SortableRows from './EditDataTable/SortableRows/component.jsx'

export default class FieldDefinitions extends React.Component {
  render() {
    const { editing, edit, errorMessages, name } = this.props

    return (
      <div className="subcontainer">
        <EditLink hidden={editing} onClick={edit}>
          {`Add/Edit ${name}`}
        </EditLink>
        <h2>{name}</h2>
        <Errors disabled={!editing} errorMessages={errorMessages} />
        {this.props.children}
      </div>
    )
  }
}
