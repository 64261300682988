import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import editable from './modules/editable.js'
import RunFieldDefinitions from './component.jsx'
import { getRemovedRunFieldDefinitionsWithData } from './selectors.js'

function mapStateToProps(state) {
  const removedRunFieldDefinitionsWithData = getRemovedRunFieldDefinitionsWithData(state)

  return {
    editing: state.get('editing'),
    runFieldDefinitions: state.getIn(['current', 'runFieldDefinitions']),
    errorMessages: state.get('errorMessages'),
    removedRunFieldDefinitionsWithData,
    usingRegistrationSystem: state.get('usingRegistrationSystem'),
    runFieldDefinitionsLocked: state.get('runFieldDefinitionsLocked'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    edit: editable.actions.edit,
  }, dispatch)
}

const RunFieldDefinitionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RunFieldDefinitions)

export default RunFieldDefinitionsContainer
