import { connect } from 'react-redux'

import {
  getRunFieldDefinition,
  getRequiredGroupNames,
} from '@/FieldDefinitions/RunFieldDefinitions/selectors.js'
import Component from './component.jsx'

function mapStateToProps(state, ownProps) {
  const {
    id,
    name,
    data_type_name,
    is_display_identifier,
    unique_value,
    overwritable,
    required_group_number,
  } = getRunFieldDefinition(state, ownProps.id).toJS()

  const requiredGroupNames = getRequiredGroupNames(state, id, required_group_number)

  return {
    id,
    name,
    data_type_name,
    is_display_identifier,
    unique_value,
    overwritable,
    required_group_number,
    requiredGroupNames,
  }
}

const Row = connect(
  mapStateToProps
)(Component)

export default Row
