import {
  getFieldDefinitionById,
  getRemovedFieldDefinitionsWithData,
} from '@/FieldDefinitions/shared/selectors.js'

export {
  getPickListValues,
  isEditingPickList,
  getInvalidIds,
  getNotification,
  getInput,
} from '@/FieldDefinitions/shared/selectors.js'

const FIELD_DEFINITION_KEY = 'elnFieldDefinitions'
const FIELD_COUNT_KEY = 'eln_fields_count'

export function getElnFieldDefinition(state, id) {
  return getFieldDefinitionById(FIELD_DEFINITION_KEY, state, id)
}

export function getRemovedElnFieldDefinitionsWithData(state) {
  return getRemovedFieldDefinitionsWithData(FIELD_DEFINITION_KEY, FIELD_COUNT_KEY, state)
}
