import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import DataTable from './components/DataTable/component.jsx'
import EditDataTable from './components/EditDataTable'
import EditLink from '@/shared/components/EditLink.jsx'
import Errors from '@/shared/components/Errors.jsx'
import UniquenessNote from './components/UniquenessNote.jsx'

export default class MoleculeFieldDefinitions extends React.Component {
  render() {
    const {
      editing,
      errorMessages,
      moleculeFieldDefinitions,
      edit,
      removedMoleculeFieldDefinitionsWithData,
      usingRegistrationSystem,
      moleculeFieldDefinitionsLocked,
      allowsMultipleMoleculesPerStructure,
    } = this.props
    const moleculeStr = Terminology.dictionary.molecule.titleize()

    if (editing) {
      return (
        <div className="subcontainer">
          <h2>{moleculeStr} Fields</h2>
          <Errors errorMessages={errorMessages} />
          <EditDataTable
            moleculeFieldDefinitions={moleculeFieldDefinitions}
            removedMoleculeFieldDefinitionsWithData={removedMoleculeFieldDefinitionsWithData}
          />
          <UniquenessNote />
        </div>
      )
    } else {
      return (
        <div className="subcontainer">
          <EditLink onClick={edit} disabled={moleculeFieldDefinitionsLocked}>
            Add/Edit {moleculeStr} Fields
          </EditLink>
          <h2>{moleculeStr} Fields</h2>
          <DataTable
            moleculeFieldDefinitions={moleculeFieldDefinitions}
            usingRegistrationSystem={usingRegistrationSystem}
            allowsMultipleMoleculesPerStructure={allowsMultipleMoleculesPerStructure}
          />
          <UniquenessNote />
        </div>
      )
    }
  }
}
