import React from 'react'
import Row from './Row.jsx'

export default class DataTable extends React.Component {
  render() {
    const { elnFieldDefinitions } = this.props
    const byId = elnFieldDefinitions.get('byId')
    const all = elnFieldDefinitions.get('all')

    return (
      <table id="elnFieldDefinitions-show" className="dataTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Data Type</th>
            <th className="centered">Must be Unique</th>
            <th className="centered">Is Required</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {all.toArray().map((id) => {
            return <Row key={id} elnFieldDefinition={byId.get(id.toString())} />
          })}
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    )
  }
}
