import { List } from 'immutable'

import { getBatchFieldDefinition } from '../selectors.js'

export default function railsify(state) {
  const batchFieldDefinitions = state.getIn(['current', 'batchFieldDefinitions'])

  const batch_field_definitions_attributes = batchFieldDefinitions.get('all')
    .reduce((memo, id, index) => {
      const definition = getBatchFieldDefinition(state, id).toJS()

      definition.display_order = index

      if (!definition.required_group_number) {
        definition.required_group_number = ''
      }

      definition.pick_list_values = state.getIn(['current', 'pickListValues', 'current', 'byDefinitionId', id.toString()], List())
        .reduce((memo, _id) => {
          const pickListValue = state.getIn(['current', 'pickListValues', 'current', 'byId', _id.toString()]).toJS()

          if (pickListValue.id.toString().startsWith('FAKE-ID')) {
            delete pickListValue.id
          }

          memo.push(pickListValue)
          return memo
        }, [])

      memo[index] = definition
      return memo
    }, {})

  return { vault: { batch_field_definitions_attributes } }
}
