import React from 'react'

import { SortableRows } from '@/shared/components/FieldDefinitionsTable'
import Row from './SortableRows/Row.jsx'
import RowPreview from './SortableRows/RowPreview.jsx'
import Icon from '@/shared/components/Icon.jsx'
import SubmitOrCancel from '@/shared/components/SubmitOrCancel.jsx'
import { A } from '@/shared/components/sanitizedTags.js'

export default class EditDataTable extends React.Component {
  render() {
    const {
      elnFieldDefinitions,
      saving,
      addElnFieldDefinition,
      updateElnFieldDefinition,
      removeElnFieldDefinition,
      spliceElnFieldDefinition,
      cancelEdit,
      saveElnFieldDefinitions,
    } = this.props

    const byId = elnFieldDefinitions.get('byId')
    const all = elnFieldDefinitions.get('all')

    const rows = all.toArray().map((id, index) => {
      const elnFieldDefinition = byId.get(id.toString())

      return <Row
        id={id}
        index={index}
        key={id}
        elnFieldDefinition={elnFieldDefinition}
        updateElnFieldDefinition={updateElnFieldDefinition}
        removeElnFieldDefinition={removeElnFieldDefinition}
        onSortEnd={spliceElnFieldDefinition}
      />
    })

    return (
      <form>
        <div id="elnFieldDefinitions-edit" className="fakeDataTable">
          <div className="row greyBackground">
            <div className="inner">Name</div>
            <div className="inner">Data Type</div>
            <div className="inner centered">Must be Unique</div>
            <div className="inner centered">Is Required</div>
            <div className="inner"></div>
          </div>
          <SortableRows
            RowPreviewClass={RowPreview}
            rowPreviewProps={(id) => ({ elnFieldDefinition: byId.get(id.toString()) })}
          >
            {rows}
          </SortableRows>
          <div className="row fake_data_table__add_row">
            <div className="inner">
              <A href="#" onClick={addElnFieldDefinition}>
                <Icon icon="add" forceSize="16" />
                <span> Add ELN Field</span>
              </A>
            </div>
          </div>
          <SubmitOrCancel
            disabled={saving}
            green={true}
            onSubmit={saveElnFieldDefinitions}
            onCancel={cancelEdit}
          >
            Update ELN Fields
          </SubmitOrCancel>
        </div>
      </form>
    )
  }
}
