/* eslint-disable multiline-ternary, no-nested-ternary */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@mui/material';
import CancelOrSubmit from '@/shared/components/CancelOrSubmit.jsx';
import React from 'react';
import { TemplateGroup, TemplateAssignment, TemplateValue, SuggestionType, SpecificationType } from '../data/templates';
import './Template.sass';
import Tooltip from '@mui/material/Tooltip';
import { Branch, OntologyTree } from '../data/OntologyTree';
import { expandPrefix } from '../data/utils';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { SearchCache, SearchCacheHit } from '../data/SearchCache';
import { SchemaBranch } from '../data/Schema';
import { Img } from '@/shared/components/sanitizedTags.js';
import branchOpenIcon from 'ASSETS/images/branch_open_icon.svg';
import branchCloseIcon from 'ASSETS/images/branch_close_icon.svg';
import branchDotIcon from 'ASSETS/images/branch_dot_icon.svg';

export enum TemplateComponentEditType {
  Root,
  Group,
  Assignment,
  Value,
}

type Props = {
  isOpen: boolean,
  dialogTitle: string;
  editType: TemplateComponentEditType,
  group: TemplateGroup, // incoming
  assn: TemplateAssignment, // incoming
  value: TemplateValue, // incoming
  customURI: string,
  onDialogCancel: () => void,
  onDialogApply: (object: TemplateGroup | TemplateAssignment | TemplateValue) => void,
};
type State = {
  watermarkUpdate: number,
  editURI?: string,
  editName?: string,
  editDescr?: string,
  editSuggestions?: SuggestionType,
  editMandatory?: boolean,
  editAltLabels?: string,
  editExternalURLs?: string,
  editSpec?: SpecificationType,
  editParentURI?: string,
  searchText: string,
  searchHits: SearchCacheHit[],
  searchUnion: Set<string>,
  searchLastHit: SearchCacheHit,
  popoverID: string,
  popoverURI: string,
  popoverDescr: string,
};

export class TemplateComponentDialog extends React.Component<Props, State> {
  private openBranches: Set<string> = null;
  private lastOpen = false;
  private branchToElement = new Map<string, React.RefObject<Element>>();
  private bogartEnterKey = false;
  private triggerScrollURI:string = null;
  private refName = React.createRef<HTMLInputElement>();
  private search: SearchCache = null;

  constructor(props) {
    super(props);

    this.state = {
      watermarkUpdate: 0,
      searchText: '',
      searchHits: null,
      searchUnion: null,
      searchLastHit: null,
      popoverID: null,
      popoverURI: null,
      popoverDescr: null,
    };
  }

  public render() {
    this.branchToElement.clear();

    const { editType } = this.props;
    if (!this.props.isOpen) {
      this.openBranches = new Set<string>();
      this.lastOpen = false;
      this.search = null;
    } else if (!this.lastOpen) {
      this.lastOpen = true;
      setTimeout(() => {
        if (editType == TemplateComponentEditType.Root || editType == TemplateComponentEditType.Group) {
          const { group } = this.props;
          this.rederiveOpenness(group.groupURI);
          this.triggerScrollURI = group.groupURI;
          this.setState({
            editURI: group.groupURI || '',
            editName: group.name || '',
            editDescr: group.descr || '',
            editSuggestions: null,
            editMandatory: null,
            editAltLabels: null,
            editExternalURLs: null,
            editSpec: null,
            editParentURI: null,
            searchText: '',
            searchHits: null,
            searchUnion: null,
            searchLastHit: null,
          });
        } else if (editType == TemplateComponentEditType.Assignment) {
          const { assn } = this.props;
          this.rederiveOpenness(assn.propURI);
          this.triggerScrollURI = assn.propURI;
          this.setState({
            editURI: assn.propURI || '',
            editName: assn.name || '',
            editDescr: assn.descr || '',
            editSuggestions: assn.suggestions || SuggestionType.Full,
            editMandatory: !!assn.mandatory,
            editAltLabels: null,
            editExternalURLs: null,
            editSpec: null,
            editParentURI: null,
            searchText: '',
            searchHits: null,
            searchUnion: null,
            searchLastHit: null,
          });
        } else if (editType == TemplateComponentEditType.Value) {
          const { value } = this.props;
          this.rederiveOpenness(value.uri);
          this.triggerScrollURI = value.uri;
          this.setState({
            editURI: value.uri || '',
            editName: value.name || '',
            editDescr: value.descr || '',
            editSuggestions: null,
            editMandatory: null,
            editAltLabels: (value.altLabels || []).join('\n'),
            editExternalURLs: (value.externalURLs || []).join('\n'),
            editSpec: value.spec || SpecificationType.WholeBranch,
            editParentURI: value.parentURI || '',
            searchText: '',
            searchHits: null,
            searchUnion: null,
            searchLastHit: null,
          });
        }
      });
      return null;
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleCancel}
        className='EditTeamDialog edit-account-object-dialog'
        maxWidth='xl'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderContent()}
      </Dialog>
    );
  }

  public componentDidUpdate(): void {
    if (this.triggerScrollURI) {
      const scrollRef = this.branchToElement.get(this.triggerScrollURI);
      if (scrollRef && scrollRef.current) {
        this.triggerScrollURI = null;
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  private renderContent(): JSX.Element {
    const { editType } = this.props;

    const isRoot = editType == TemplateComponentEditType.Root;

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.props.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          <div className={`OntologyComponent-vbox ${isRoot ? '' : 'OntologyComponent-withuri'}`}>
            {
              editType == TemplateComponentEditType.Root ? this.renderRoot()
                : editType == TemplateComponentEditType.Group ? this.renderGroup()
                  : editType == TemplateComponentEditType.Assignment ? this.renderAssignment()
                    : editType == TemplateComponentEditType.Value ? this.renderValue()
                      : null
            }
          </div>
        </DialogContent>
        <DialogActions className="project__action">
          <CancelOrSubmit
            green={true}
            onCancel={this.handleCancel}
            onSubmit={this.handleApply}
          >
            APPLY
          </CancelOrSubmit>
        </DialogActions>
      </>
    );
  }

  private renderRoot(): JSX.Element {
    return (
      <>
        { this.makeNameDescr(true) }
      </>
    );
  }

  private renderGroup(): JSX.Element {
    return (
      <>
        { this.makeSelectURI() }
        { this.makeNameDescr(false) }
      </>
    );
  }

  private renderAssignment(): JSX.Element {
    const { editSuggestions, editMandatory } = this.state;
    return (
      <>
        { this.makeSelectURI() }
        { this.makeNameDescr(false) }
        <div>
          <FormControl>
            <RadioGroup
              row
              name="assignment-suggestions"
              value={editSuggestions}
              onChange={(event, value) => this.setState({ editSuggestions: value as SuggestionType })}
            >
              <Tooltip arrow title="Ontology terms, with full machine learning support wherever possible.">
                <FormControlLabel value={SuggestionType.Full} control={<Radio/>} label="Full"/>
              </Tooltip>
              <Tooltip arrow title="Ontology terms, with machine learning support turned off.">
                <FormControlLabel value={SuggestionType.Disabled} control={<Radio/>} label="Disabled"/>
              </Tooltip>
              <Tooltip arrow title="References to fields associated with entity data.">
                <FormControlLabel value={SuggestionType.Field} control={<Radio/>} label="Field"/>
              </Tooltip>
              <Tooltip arrow title="Hyperlinks to external resources.">
                <FormControlLabel value={SuggestionType.URL} control={<Radio/>} label="URL"/>
              </Tooltip>
              <Tooltip arrow title="Identifier references to Vault objects.">
                <FormControlLabel value={SuggestionType.ID} control={<Radio/>} label="ID"/>
              </Tooltip>
              <Tooltip arrow title="Freeform text.">
                <FormControlLabel value={SuggestionType.String} control={<Radio/>} label="String"/>
              </Tooltip>
              <Tooltip arrow title="Numeric values, floating point.">
                <FormControlLabel value={SuggestionType.Number} control={<Radio/>} label="Number"/>
              </Tooltip>
              <Tooltip arrow title="Integer numeric values.">
                <FormControlLabel value={SuggestionType.Integer} control={<Radio/>} label="Integer"/>
              </Tooltip>
              <Tooltip arrow title="Date/time.">
                <FormControlLabel value={SuggestionType.Date} control={<Radio/>} label="Date"/>
              </Tooltip>
            </RadioGroup>
          </FormControl>

          <Tooltip arrow title="If on, the user is strongly advised not to leave this blank.">
            <FormControlLabel control={
              <Checkbox
                checked={editMandatory}
                onChange={(event, checked) => this.setState({ editMandatory: checked })}
                />
            } label="Mandatory"/>
          </Tooltip>
        </div>
      </>
    );
  }

  private renderValue(): JSX.Element {
    const { editSpec, editParentURI } = this.state;
    return (
      <>
        { this.makeSelectURI() }
        { this.makeNameDescr(false) }
        <div className="OntologyTemplate-edit-hbox">
          <FormControl>
            <RadioGroup
              row
              name="value-spec"
              value={editSpec}
              onChange={(event, value) => this.setState({ editSpec: value as SpecificationType })}
            >
              <Tooltip arrow title="Incorporate the specified term.">
                <FormControlLabel value={SpecificationType.Item} control={<Radio/>} label="Item"/>
              </Tooltip>
              <Tooltip arrow title="Incorporate the specified term and all of its child branches.">
                <FormControlLabel value={SpecificationType.WholeBranch} control={<Radio/>} label="Whole Branch"/>
              </Tooltip>
              <Tooltip arrow title="Incorporate the whole branch, but mark this term as non-selectable.">
                <FormControlLabel value={SpecificationType.Container} control={<Radio/>} label="Container"/>
              </Tooltip>
              <Tooltip arrow title="Exclude the specified term from the hierarchy.">
                <FormControlLabel value={SpecificationType.Exclude} control={<Radio/>} label="Exclude"/>
              </Tooltip>
              <Tooltip arrow title="Exclude the entire branch from the hierarchy.">
                <FormControlLabel value={SpecificationType.ExcludeBranch} control={<Radio/>} label="Exclude Branch"/>
              </Tooltip>
            </RadioGroup>
          </FormControl>
          <div className="OntologyComponent-label">
            Parent URI
          </div>
          <input
            className="input-text OntologyTemplate-flexgrow1"
            type="text"
            value={editParentURI}
            onChange={(event) => this.setState({ editParentURI: event.target.value })}
            autoFocus={true}
            />
        </div>
      </>
    );
  }

  private makeSelectURI(): JSX.Element {
    const hasCustom = this.state.editURI != this.props.customURI;
    return (
      <React.Fragment key="selectURI">
        <div className="OntologyComponent-gridlabelbtn">
          <div className="OntologyComponent-label" style={{ gridArea: '1 / title' }}>URI</div>
          <input
            className="input-text"
            style={{ gridArea: '1 / content' }}
            type="text"
            value={this.state.editURI}
            onChange={(event) => this.setState({ editURI: event.target.value })}
            autoFocus={true}
            />
          <div
            className={`OntologyTemplate-menubutton ${hasCustom ? 'OntologyTemplate-clickable' : 'OntologyTemplate-unclickable'}`}
            style={{ gridArea: '1 / button' }}
            onClick={hasCustom ? () => this.handleCustomURI() : null}
            >
            Custom
          </div>

          <div style={{ gridArea: '2 / content', textAlign: 'right' }}>
            <input
              className="input-text OntologyComponent-text"
              type="text"
              style={{ width: '20em' }}
              placeholder="Search"
              value={this.state.searchText}
              onChange={(event) => this.handleSearchChange(event.target.value)}
              onKeyDown={(event) => this.handleSearchKey(event)}
              />
          </div>
          <div
            className="OntologyTemplate-menubutton OntologyTemplate-clickable"
            style={{ gridArea: '2 / button' }}
            onClick={this.handleSearchLook}
            >
            Look
          </div>
        </div>

        <div className="OntologyComponent-hierarchy">
          {this.renderHierarchy()}
        </div>
      </React.Fragment>
    );
  }

  private handleCustomURI(): void {
    this.setState({ editURI: this.props.customURI });
    if (this.props.editType == TemplateComponentEditType.Value) {
      this.refName.current?.focus();
      this.setState({ editSpec: SpecificationType.Item });
    }
  }

  private handleSearchChange(searchText: string): void {
    if (!searchText) {
      this.setState({ searchText, searchHits: null, searchUnion: null, searchLastHit: null });
      return;
    }

    this.search.performSearch(searchText, (hits, hitUnion) => {
      if (!this.props.isOpen) return;
      this.setState({ searchHits: hits, searchUnion: hitUnion });
    });

    this.setState({ searchText });
  }

  private handleSearchKey(event: React.KeyboardEvent): void {
    if (event.key == 'Enter') {
      this.bogartEnterKey = true; // it blags the submit button regardless of event.stopPropagation/preventDefault
      this.handleSearchLook();
    }
  }

  private handleSearchLook = (): void => {
    const { searchHits, searchLastHit } = this.state;
    if (!searchHits?.length) return;

    let idxMatch = -1;
    if (searchLastHit) {
      const lastURI = Vec.last(searchLastHit.sequence);
      for (let n = 0; n < searchHits.length; n++) {
        if (Vec.last(searchHits[n].sequence) == lastURI) {
          idxMatch = n;
          break;
        }
      }
    }
    const curHit = searchHits[(idxMatch + 1) % searchHits.length], curURI = Vec.last(curHit.sequence);

    const scanForMatches = (branch: Branch): void => {
      if (branch.uri == curURI) {
        for (let look = branch.parent; look; look = look.parent) {
          this.openBranches.add(look.uri);
        }
        this.triggerScrollURI = expandPrefix(branch.uri);
        this.setState({ searchLastHit: curHit, watermarkUpdate: this.state.watermarkUpdate + 1 });
        return;
      }
      for (const child of branch.children) scanForMatches(child);
    };

    const tree = this.props.editType == TemplateComponentEditType.Value ? OntologyTree.values : OntologyTree.props;
    const roots = tree.cachedRoots();
    for (const root of roots) scanForMatches(root);
  };

  private makeNameDescr(isRoot: boolean): JSX.Element {
    const tree = this.props.editType == TemplateComponentEditType.Value ? OntologyTree.values : OntologyTree.props;
    const uri = this.state.editURI;
    const branch = uri ? tree.cachedBranch(this.state.editURI) : null;
    let canRevertLabel = false, canRevertDescr = false;
    if (branch) {
      if (branch.label == null || branch.description == null) {
        (async () => {
          tree.getBranch(uri, branch.label == null, branch.description == null);
          setTimeout(() => {
            this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
          });
        })();
      } else {
        canRevertLabel = this.state.editName != (this.sanitateProperty(branch.label));
        canRevertDescr = this.state.editDescr != (branch.description || '');
      }
    }

    const btnRevertName = !isRoot ? (
      <div
        style={{ gridArea: '1 / button' }}
        className={`OntologyTemplate-menubutton ${canRevertLabel ? 'OntologyTemplate-clickable' : 'OntologyTemplate-unclickable'}`}
        onClick={() => this.handleRevertName()}
        >
        Revert
      </div>
    ) : null;
    const btnRevertDescr = !isRoot ? (
      <div
        style={{ gridArea: '2 / button' }}
        className={`OntologyTemplate-menubutton ${canRevertDescr ? 'OntologyTemplate-clickable' : 'OntologyTemplate-unclickable'}`}
        onClick={() => this.handleRevertDescr()}
        >
        Revert
      </div>
    ) : null;

    return (
      <React.Fragment key="nameDescr">
        <div className={isRoot ? 'OntologyComponent-gridlabel' : 'OntologyComponent-gridlabelbtn'}>
          <div className="OntologyComponent-label" style={{ gridArea: '1 / title' }}>Name</div>
          <input
            ref={this.refName}
            className="input-text"
            style={{ gridArea: '1 / content' }}
            type="text"
            value={this.state.editName}
            onChange={(event) => this.setState({ editName: event.target.value })}
            autoFocus={this.props.editType == TemplateComponentEditType.Root}
            />
          {btnRevertName}

          <div className="OntologyComponent-label" style={{ gridArea: '2 / title' }}>Description</div>
          <textarea
            className="OntologyComponent-textarea resizable"
            style={{ gridArea: '2 / content' }}
            cols={40}
            rows={6}
            value={this.state.editDescr}
            onChange={(event) => this.setState({ editDescr: event.target.value })}
          />
          {btnRevertDescr}
        </div>
      </React.Fragment>
    );
  }

  private renderHierarchy(): JSX.Element {
    const tree = this.props.editType == TemplateComponentEditType.Value ? OntologyTree.values : OntologyTree.props;
    const roots = tree.cachedRoots();
    let allRootsLoaded = true;
    for (const root of roots) if (!root.label) allRootsLoaded = false;
    if (!allRootsLoaded) {
      setTimeout(() => {
        (async () => {
          for (const root of roots) await tree.getBranch(root.uri);
          this.search = new SearchCache(null, roots.map((branch) => {
            return { assn: null, groupNest: null, branch: branch as SchemaBranch };
          }));
          this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
        })();
      }, 10);
      return null;
    } else {
      this.search = new SearchCache(null, roots.map((branch) => {
        return { assn: null, groupNest: null, branch: branch as SchemaBranch };
      }));
    }
    roots.sort((r1, r2) => r1.label.localeCompare(r2.label));

    const thoseUnloaded = new Set<string>();

    const frag = (
      <>
        {roots.map((branch) => this.renderBranch(branch, 0, thoseUnloaded))}
      </>
    );

    if (thoseUnloaded.size > 0) {
      setTimeout(() => {
        (async () => {
          for (const uri of Array.from(thoseUnloaded)) await tree.getBranch(uri);
          this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
        })();
      }, 10);
    }

    return frag;
  }

  private renderBranch(branch: Branch, depth: number, thoseUnloaded: Set<string>): JSX.Element {
    if (!this.openBranches) return null;

    if (branch.label == null) thoseUnloaded.add(branch.uri);

    const isOpen = this.openBranches.has(branch.uri);
    const isSelected = expandPrefix(branch.uri) == this.state.editURI;
    const { searchHits, searchUnion } = this.state;
    const searchMatched = searchHits?.some((hit) => Vec.last(hit.sequence) == branch.uri);
    const ancestorMatched = searchUnion?.has(branch.uri);

    let svgPath = isOpen ? branchCloseIcon : branchOpenIcon;
    if (branch.children.length == 0) svgPath = branchDotIcon;
    const toggleClass = branch.children.length > 0 ? ['TermPicker-toggle'] : [];
    if (ancestorMatched && !searchMatched && !isOpen) toggleClass.push('TermPicker-ancestor-matched');
    const toggle = (
      <div
        className={toggleClass.join(' ')}
        onClick={() => this.handleToggleBranch(branch)}
        >
        <Img className="TermPicker-icon" src={svgPath}/>
      </div>
    );

    const lineClass = ['TermPicker-termline'];
    if (searchMatched) lineClass.push('OntologyComponent-selected');

    const popoverID = 'uri-' + branch.uri;
    const tooltip = popoverID == this.state.popoverID ? (
      <Typography className="ProtocolAnnotator-tooltip">
        <b>{this.state.popoverURI}</b>
        <br/>
        {this.state.popoverDescr}
      </Typography>
    ) : '';

    const useRef = React.createRef<HTMLDivElement>();
    this.branchToElement.set(expandPrefix(branch.uri), useRef);

    return (
      <React.Fragment key={branch.uri}>
        <div
          ref={useRef}
          className={lineClass.join(' ')}
          style={{ paddingLeft: `${depth}em` }}
          >
          {toggle}
          <Tooltip
            title={tooltip}
            arrow
            placement="right"
            >
            <div
              className={isSelected ? 'TermPicker-selected' : 'TermPicker-label'}
              onClick={() => this.handlePickURI(branch)}
              onMouseEnter={() => this.handlePopoverOpen(popoverID, branch)}
              >
              {branch.label}
            </div>
          </Tooltip>
        </div>
        <div>
          {
            isOpen && branch.children.length > 0 ? (
              branch.children.map((child) => this.renderBranch(child, depth + 1, thoseUnloaded))
            ) : null
          }
        </div>
      </React.Fragment>
    );
  }

  private handleToggleBranch(branch: Branch): void {
    if (this.openBranches.has(branch.uri)) {
      this.openBranches.delete(branch.uri);
    } else {
      this.openBranches.add(branch.uri);
    }
    this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
  }

  private handlePickURI(branch: Branch): void {
    this.refName.current?.focus();
    this.setState({
      editURI: expandPrefix(branch.uri),
      editName: this.state.editName || this.sanitateProperty(branch.label),
      editDescr: this.state.editDescr || branch.description,
    });
  }

  private handleRevertName(): void {
    const uri = this.state.editURI;
    if (!uri) return;
    const tree = this.props.editType == TemplateComponentEditType.Value ? OntologyTree.values : OntologyTree.props;
    (async () => {
      const branch = await tree.getBranch(uri, true, false);
      const label = branch ? this.sanitateProperty(branch.label) : '';
      if (branch) this.setState({ editName: label });
    })();
  }

  private handleRevertDescr(): void {
    const uri = this.state.editURI;
    if (!uri) return;
    const tree = this.props.editType == TemplateComponentEditType.Value ? OntologyTree.values : OntologyTree.props;
    (async () => {
      const branch = await tree.getBranch(uri, false, true);
      if (branch) this.setState({ editDescr: branch.description || '' });
    })();
  }

  private handlePopoverOpen(popoverID: string, branch: Branch): void {
    if (branch.label != null && branch.description != null) {
      this.setState({ popoverID, popoverURI: expandPrefix(branch.uri), popoverDescr: branch ? branch.description : null });
      return;
    }

    (async () => {
      const tree = this.props.editType == TemplateComponentEditType.Value ? OntologyTree.values : OntologyTree.props;
      branch = await tree.getBranch(branch.uri, true, true);
      this.setState({ popoverID, popoverURI: expandPrefix(branch.uri), popoverDescr: branch ? branch.description : null });
    })();
  }

  // dialog has been opened with new branch info, so decide which of the branches should be open
  private rederiveOpenness(selectedURI: string): void {
    this.openBranches = new Set<string>();
    const roots = this.props.editType == TemplateComponentEditType.Value ? OntologyTree.values.cachedRoots() : OntologyTree.props.cachedRoots();

    const scanForSelected = (branch: Branch): void => {
      if (expandPrefix(branch.uri) == selectedURI) {
        for (let look = branch; look; look = look.parent) {
          this.openBranches.add(look.uri);
        }
      }
      for (const child of branch.children) scanForSelected(child);
    };

    for (const root of roots) {
      if (selectedURI) scanForSelected(root);
    }
  }

  private handleCancel = (): void => {
    this.props.onDialogCancel();
  };

  private handleApply = (): void => {
    if (this.bogartEnterKey) {
      this.bogartEnterKey = false;
      return;
    }

    const { editType } = this.props;
    const isRoot = editType == TemplateComponentEditType.Root;
    const { editURI, editName, editDescr, editSuggestions, editMandatory, editAltLabels, editExternalURLs, editSpec, editParentURI } = this.state;

    if (!isRoot && (!editURI || !editURI.startsWith('http://'))) {
      ModalUtils.showModal('The URI must be provided, and start with the http:// prefix.', {});
      return;
    }
    if (!editName) {
      ModalUtils.showModal('Name must be provided.', {});
      return;
    }

    const toArray = (str: string): string[] => {
      const list = str.split(/\r?\n/).filter((line) => !!line);
      return list.length > 0 ? list : null;
    };

    if (editType == TemplateComponentEditType.Root || editType == TemplateComponentEditType.Group) {
      const group: TemplateGroup = deepClone(this.props.group);
      group.groupURI = editURI;
      group.name = editName;
      group.descr = editDescr;
      // (others?)
      this.props.onDialogApply(group);
    } else if (editType == TemplateComponentEditType.Assignment) {
      const assn: TemplateAssignment = deepClone(this.props.assn);
      assn.propURI = editURI;
      assn.name = editName;
      assn.descr = editDescr;
      assn.suggestions = editSuggestions;
      assn.mandatory = editMandatory;
      this.props.onDialogApply(assn);
    } else if (editType == TemplateComponentEditType.Value) {
      const value: TemplateValue = deepClone(this.props.value);
      value.uri = editURI;
      value.name = editName;
      value.descr = editDescr;
      value.altLabels = toArray(editAltLabels);
      value.externalURLs = toArray(editExternalURLs);
      value.spec = editSpec;
      value.parentURI = editParentURI;
      this.props.onDialogApply(value);
    }
  };

  private sanitateProperty(label: string): string {
    if (label == null) return '';
    if (this.props.editType != TemplateComponentEditType.Value) {
      if (label.startsWith('has ')) return label.substring(4);
      else if (label.startsWith('is ')) return label.substring(3);
      else if (label.startsWith('uses ')) return label.substring(5);
    }
    return label;
  }
}
