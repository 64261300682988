import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import EditDataTable from './component.jsx'
import editable from '@/FieldDefinitions/ElnFieldDefinitions/modules/editable.js'
import {
  addElnFieldDefinition,
  updateElnFieldDefinition,
  removeElnFieldDefinition,
  spliceElnFieldDefinition,
  saveElnFieldDefinitions,
} from '@/FieldDefinitions/ElnFieldDefinitions/actions.js'

function mapStateToProps(state) {
  return {
    elnFieldDefinitions: state.getIn(['current', 'elnFieldDefinitions']),
    vaultElnFieldDefinitionsUrl: state.get('vaultElnFieldDefinitionsUrl'),
    saving: state.get('saving'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cancelEdit: editable.actions.cancelEdit,
    addElnFieldDefinition,
    updateElnFieldDefinition,
    removeElnFieldDefinition,
    spliceElnFieldDefinition,
    saveElnFieldDefinitions,
  }, dispatch)
}

const EditDataTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDataTable)

export default EditDataTableContainer
