import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import DataTable from './components/DataTable/component.jsx'
import EditDataTable from './components/EditDataTable'
import EditLink from '@/shared/components/EditLink.jsx'
import Errors from '@/shared/components/Errors.jsx'

export default class ProtocolFieldDefinitions extends React.Component {
  render() {
    const {
      editing,
      errorMessages,
      protocolFieldDefinitions,
      edit,
      removedProtocolFieldDefinitionsWithData,
      usingRegistrationSystem,
      protocolFieldDefinitionsLocked,
    } = this.props
    const protocolStr = Terminology.dictionary.protocol.titleize()

    if (editing) {
      return (
        <div className="subcontainer">
          <h2>{protocolStr} Fields</h2>
          <Errors errorMessages={errorMessages} />
          <EditDataTable
            protocolFieldDefinitions={protocolFieldDefinitions}
            removedProtocolFieldDefinitionsWithData={removedProtocolFieldDefinitionsWithData}
          />
        </div>
      )
    } else {
      return (
        <div className="subcontainer">
          <EditLink onClick={edit} disabled={protocolFieldDefinitionsLocked}>
            Add/Edit {protocolStr} Fields
          </EditLink>
          <h2>{protocolStr} Fields</h2>
          <DataTable
            protocolFieldDefinitions={protocolFieldDefinitions}
            usingRegistrationSystem={usingRegistrationSystem}
          />
        </div>
      )
    }
  }
}
