import React from 'react'
import FilterPickListValue from './FilterPickListValue.jsx'
import FilterTextValue from './FilterTextValue.jsx'
import FilterDateValue from './FilterDateValue.jsx'
import FilterNumberValue from './FilterNumberValue.jsx'

const componentMap = new Proxy({
  PickList: FilterPickListValue,
  Date: FilterDateValue,
  Number: FilterNumberValue,
}, {
  get: (map, type) => map[type] || FilterTextValue,
})

export default class FilterValueSelect extends React.Component {
  render() {
    const { elnFieldDefinition } = this.props
    const Component = componentMap[elnFieldDefinition.data_type_name]

    return <span className='filter-value-select'><Component {...this.props} /></span>
  }
}
