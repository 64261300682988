import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import MuiTheme from 'shared/components/MuiTheme.jsx'
import SaltAndSolventEditor from './SaltAndSolventEditor.jsx'

const ShouldUseStoichiometry = 'should_use_stoichiometry'
const ShouldStrip = 'should_strip'

class StripOrSalt extends React.Component {
  constructor(props) {
    super(props)
    const { should_use_stoichiometry } = this.props
    const specified_batch = should_use_stoichiometry ? ShouldUseStoichiometry : ShouldStrip
    this.state = {
      specified_batch: specified_batch,
    }
  }

  stripSaltsDisabled = () => {
    return this.state.specified_batch == ShouldUseStoichiometry
  }

  useSaltsDisabled = () => {
    return !this.stripSaltsDisabled()
  }

  handleRadioChange = (event) => {
    this.setState({ specified_batch: event.target.value })
  }

  render() {
    const radioGroupProps = {
      height: '100%',
      name: 'specified_batch',
      onChange: this.handleRadioChange,
      value: this.state.specified_batch,
    }

    return (
      <MuiTheme className='SaltAndSolventEditor'>
        <FormControl component="fieldset">
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs={2}>
              <RadioGroup {...radioGroupProps}>
                <FormControlLabel
                  id='specified_batch_should_strip_salts'
                  value="should_strip"
                  className="SaltAndSolventEditor_text"
                  control={<Radio color={'primary'}/>}
                  label={<Typography className='SaltAndSolventEditor_text'>Strip:</Typography>}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={10}>
              <Typography
                className='SaltAndSolventEditor_text'
              >
                Strip salts and solvents from structure during registration
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <RadioGroup {...radioGroupProps}>
                <FormControlLabel
                  control={<Radio color={'primary'}/>}
                  className='SaltAndSolventEditor_text'
                  id='specified_batch_should_use_stoichiometry'
                  label={<Typography className='SaltAndSolventEditor_text'>Stoichiometry:</Typography>}
                  value="should_use_stoichiometry"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={10}>
              <SaltAndSolventEditor
                disabled={this.useSaltsDisabled()}
                {...this.props}
              />
            </Grid>
          </Grid>
        </FormControl>
      </MuiTheme>
    )
  }
}

StripOrSalt.propTypes = {
  solventOptions: PropTypes.array.isRequired,
  defaultSaltData: PropTypes.object.isRequired,
  objectName: PropTypes.string.isRequired,
}

export { StripOrSalt }
