import React from 'react'

import Icon from '@/shared/components/Icon.jsx'
import { DropdownDisplayCheckbox, Actions } from '@/shared/components/FieldDefinitionsTable'

export default function BaseRow(props) {
  const { name, id, requiredText } = props
  const elementId = `run-field-definition-${id}`

  return (
    <div className="row" id={elementId}>
      <div className="inner name">
        <span name="name">
          {name}
        </span>
      </div>
      <div className="inner data-type"></div>
      <div className="inner centered dropdown-display">
        <DropdownDisplayCheckbox
          checked={true}
          disabled={true}
          disabledText="This field must be displayed"
          name="dropdown-display"
        />
      </div>
      <div className="inner requirement">
        <span name="required requirement">
          {requiredText}
        </span>
      </div>
      <div className="inner actions">
        <Actions disabled={true} />
        <span>
          <Icon icon="disabled_drag" />
        </span>
      </div>
    </div>
  )
}
