import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import constants from 'ASSETS/javascripts/constants.js';
const { DATE_DISPLAY_FORMAT } = constants;

type Props = {
  className: string,
  id: string,
  label: string,
  disableOpenOnEnter: boolean,
  onChange: (evt: any) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
  onError: (error: any /*: React.ReactNode */, value: any /*: MaterialUiPickersDate | ParsableDate */) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
  value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  leftArrowIcon: any /* JSX.Element */, // eslint-disable-line @typescript-eslint/no-explicit-any
  rightArrowIcon: any /* JSX.Element */, // eslint-disable-line @typescript-eslint/no-explicit-any
  keyboardIcon: any /* JSX.Element */, // eslint-disable-line @typescript-eslint/no-explicit-any
  inputProps: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}

const DatePickerWithIconsComponent = (props: Props) => {
  const [value, setValue] = React.useState(props.value);

  const handleBlur = useCallback(() => {
    const newDate = value;
    if (newDate && newDate.isValid()) {
      const oldValue = props.value && moment(props.value).format(DATE_DISPLAY_FORMAT);
      if (newDate.format(DATE_DISPLAY_FORMAT) !== oldValue) {
        props.onChange(newDate);
      }
    }
  }, [value, props]);

  const handleAccept = useCallback((newDate: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (newDate && newDate.isValid()) {
      const oldValue = props.value && moment(props.value).format(DATE_DISPLAY_FORMAT);
      if (newDate.format(DATE_DISPLAY_FORMAT) !== oldValue) {
        props.onChange(newDate);
      }
    } else if (!newDate && props.value) {
      props.onChange(newDate);
    }
  }, []);

  const handleChange = useCallback((value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setValue(value);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        {...props}
        renderInput={(params) => <TextField {...params} onBlur={handleBlur} />}
        inputFormat={DATE_DISPLAY_FORMAT}
        label={props.label}
        value={value}
        onChange={handleChange}
        autoFocus
        componentsProps={{
          actionBar: {
            actions: ['clear', 'cancel', 'accept'],
          },
        }}
        onAccept={handleAccept}
      />
    </LocalizationProvider>
  );
};

DatePickerWithIconsComponent.defaultProps = {
  className: 'datepicker',
  keyboardIcon: <i className='fa fa-calendar' />,
  inputProps: { classes: { input: 'material-input' } },
};

export default class DatePickerWithIcons extends React.Component<Props> {
  render() {
    return <DatePickerWithIconsComponent {...this.props} />;
  }
}
