import React from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'

type Props = {
  errorMessages: Object
};

export default class Errors extends React.Component {
  props: Props

  constructor(props) {
    super(props)

    this.visible = this._visible.bind(this)
  }

  componentDidUpdate() {
    if (this.visible()) {
      scrollIntoView(this.div, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }

  _visible() {
    const { disabled, errorMessages } = this.props

    return !disabled && errorMessages && (errorMessages.size || errorMessages.length) > 0
  }

  render() {
    let { errorMessages } = this.props
    errorMessages = (errorMessages.toArray ? errorMessages.toArray() : errorMessages) || []
    const classes = ['container--fade']

    if (this.visible()) {
      classes.push('visible')
    }

    return (
      <div className={classes.join(' ')} ref={div => { this.div = div }}>
        <div className="errorMessage">
          <ul>
            {errorMessages.map(function (errorMessage) {
              return <li key={errorMessage}>{errorMessage}</li>
            })}
          </ul>
        </div>
      </div>
    )
  }
}
