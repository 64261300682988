import { molecule_properties } from '../Properties/calculator';
import { getCollectionByName } from '../Similarity/Collections';
import {
  BioisosterFragment,
  DLBioisosterResponse,
  DLError,
  ReferenceStructure,
} from './BioisosterResultsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processBioisostereResults(json: any): DLBioisosterResponse | DLError {
  if (json.result?.hits) {
    json.result.hits.forEach((hit) => {
      hit.identifiers.forEach((identifier) => {
        identifier.collection = getCollectionByName(identifier.collection);
      });
    });
  }
  return json;
}

export const bioisosterToBlob = (props: {
  reference: ReferenceStructure,
  bioisosterFragments: Array<BioisosterFragment>,
  displayProperties: string[],
}) => {
  const header = [
    'Fragment',
    'Suggestion',
    'smiles',
    'synthetic accessibility',
    'selected',
  ];
  const exportProperties = molecule_properties.filter((p) =>
    props.displayProperties.includes(p.key),
  );

  const csvData = [[...header, ...exportProperties.map((p) => p.short)]];

  const row = ['Reference', '', props.reference.structure, '', ''];
  exportProperties.forEach((property) => {
    const value = props.reference.properties[property.key];
    row.push(!value ? '' : value.toFixed(property.precision));
  });
  csvData.push(row);

  props.bioisosterFragments.forEach((bioisosterFragment) => {
    bioisosterFragment.bioisosters.forEach((bioisoster) => {
      const row = [
        bioisosterFragment.fragment,
        bioisoster.fragment,
        bioisoster.structure,
        bioisoster.synthetic_feasibility.synthetic_feasibility_score.toFixed(3),
        bioisoster.selected ? 'selected' : '',
      ];
      exportProperties.forEach((property) => {
        const value = bioisoster.properties[property.key];
        row.push(!value ? '' : value.toFixed(property.precision));
      });
      csvData.push(row);
    });
  });

  return new Blob([csvData.map((row) => row.join(',')).join('\n')], {
    type: 'text/csv;charset=utf-8;',
  });
};

export const downloadBioisosters = (props: {
  reference: ReferenceStructure,
  bioisosterFragments: Array<BioisosterFragment>,
  displayProperties: string[],
}) => {
  const blob = bioisosterToBlob(props);
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', 'bioisosteres.csv');
  link.click();
};
