import { List } from 'immutable'
import { initialState } from './index.js'
import persistable from '@/FieldDefinitions/MoleculeFieldDefinitions/modules/persistable.js'
import editable from '@/FieldDefinitions/MoleculeFieldDefinitions/modules/editable.js'

const reinitAfterSaveOrCancel = reducer => (state, action) => {
  const reducedState = reducer(state, action)

  switch (action.type) {
    case persistable.actionTypes.SUCCEED:
      return reducedState.merge(initialState(action.response))
    case editable.actionTypes.CANCEL:
      return reducedState.set('errorMessages', List())
    default:
      return reducedState
  }
}

export default reinitAfterSaveOrCancel
