import React from 'react'
import Checkbox from '@/shared/components/Checkbox.jsx'
import DataType from 'models/DataType.js'

export default class Row extends React.Component {
  render() {
    const { elnFieldDefinition } = this.props
    const { id, name, data_type_name, unique, required } = elnFieldDefinition.toJS()

    return (
      <tr id={'eln_field_definition-' + id} className="row">
        <td name="name">{ name }</td>
        <td name="data_type_name">{ DataType.displayNameForDataTypeName(data_type_name) }</td>
        <td name="unique" className={'centered'}>
          <Checkbox checked={ unique } />
        </td>
        <td name="required" className={'centered'}>
          <Checkbox checked={ required } />
        </td>
        <td></td>
      </tr>
    )
  }
}
